import { TEXTS_EUSK } from './i18n_eusk';
import { TEXTS_CAT } from './i18n_cat';
import { TEXTS_DE } from './i18n_de';
import { TEXTS_EN } from './i18n_en';
import { TEXTS_ES } from './i18n_es';
import { TEXTS_FR } from './i18n_fr';
import { TEXTS_IT } from './i18n_it';


export class LangHandler {

  public texts: any;


  constructor(public lang: string = "en") {
    switch (this.lang) {
      case "es":
        this.texts = TEXTS_ES;
        break;
      case "cat":
        this.texts = TEXTS_CAT;
        break;
      case "eusk":
        this.texts = TEXTS_EUSK;
        break;
      case "it":
        this.texts = TEXTS_IT;
        break;
      case "fr":
        this.texts = TEXTS_FR;
        break;
      case "de":
        this.texts = TEXTS_DE;
        break;
      default:
        this.texts = TEXTS_EN;
    }
  }

}

export const Text: any = new LangHandler(localStorage.getItem("lang") || undefined).texts;
