import { Settings } from '../../constants/settings';
import { CompletedRow } from './CompletedRow';
import { CurrentRow } from './CurrentRow';
import { EmptyRow } from './EmptyRow';

type Props = {
  guesses: string[];
  currentGuess: string;
  isRevealing?: boolean;
};

export const Grid = ({ guesses, currentGuess, isRevealing }: Props) => {
  const empties =
    guesses.length < Settings.MAX_CHALLENGES - 1
      ? Array.from(Array(Settings.MAX_CHALLENGES - 1 - guesses.length))
      : [];

  return (
    <div className="pb-6">
      {guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
        />
      ))}
      {guesses.length < Settings.MAX_CHALLENGES && <CurrentRow guess={currentGuess} />}
      {empties.map((_, i) => (
        <EmptyRow key={i} />
      ))}
    </div>
  );
};
