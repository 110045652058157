type Props = {
  index: number
  size: number
  label: string
}

export const Progress = ({ index, size, label }: Props) => {
  return (
    <div className="flex justify-left m-1">
      <div className="items-center justify-center w-2"><b>{index + 1}</b></div>
      <div className="rounded-md w-full ml-2">
        <div
          style={{ width: `${5 + size}%` }}
          className="bg-blue-500 text-xs font-medium text-white text-center p-0.5 rounded-md"
        >
          <b>{label}</b>
        </div>
      </div>
    </div>
  )
}
