export const VALID_GUESSES_CAT = [
  "abuse",
  "acaia",
  "acopa",
  "acote",
  "acuts",
  "adjar",
  "adoba",
  "adust",
  "aideu",
  "aidin",
  "ajace",
  "aleja",
  "amans",
  "amoni",
  "ampre",
  "anava",
  "anorc",
  "anoti",
  "ansar",
  "arcau",
  "argus",
  "arpin",
  "aspia",
  "atles",
  "atrit",
  "avall",
  "aviau",
  "avidi",
  "badar",
  "bafor",
  "baila",
  "balca",
  "balim",
  "baria",
  "basil",
  "batem",
  "baten",
  "baven",
  "befem",
  "belga",
  "benet",
  "besar",
  "besem",
  "birbi",
  "bitem",
  "bitxa",
  "bohri",
  "boiar",
  "boina",
  "boixi",
  "bombi",
  "boniu",
  "borem",
  "botat",
  "boter",
  "botim",
  "boxar",
  "brial",
  "brise",
  "bruac",
  "bufat",
  "bugia",
  "burgi",
  "burla",
  "busca",
  "cabeu",
  "caduf",
  "calfi",
  "calmi",
  "calta",
  "capen",
  "capir",
  "carxe",
  "cebar",
  "cecal",
  "cedim",
  "cedir",
  "cegau",
  "celau",
  "cepat",
  "cerca",
  "chiva",
  "cifre",
  "cimal",
  "cimin",
  "cirat",
  "cloeu",
  "clone",
  "cobro",
  "cofeu",
  "coiot",
  "colar",
  "colia",
  "colon",
  "colri",
  "colur",
  "conri",
  "conti",
  "copte",
  "coral",
  "corri",
  "cosiu",
  "coten",
  "coter",
  "creba",
  "crome",
  "cugot",
  "cuine",
  "cuque",
  "curse",
  "dabau",
  "decep",
  "decil",
  "deien",
  "desix",
  "desou",
  "diada",
  "dicta",
  "dicti",
  "didot",
  "dient",
  "dinem",
  "diria",
  "diste",
  "doble",
  "dolia",
  "domeu",
  "domta",
  "dopar",
  "dopen",
  "doric",
  "draga",
  "dugui",
  "durat",
  "embat",
  "emili",
  "erere",
  "esmen",
  "evite",
  "exili",
  "eximi",
  "exodi",
  "facin",
  "fadeu",
  "faent",
  "falsa",
  "falta",
  "falti",
  "fasts",
  "faune",
  "favor",
  "feina",
  "feixe",
  "femau",
  "femer",
  "feres",
  "ferla",
  "ferme",
  "ferra",
  "fible",
  "fibra",
  "ficte",
  "filem",
  "filma",
  "finat",
  "finim",
  "finte",
  "finti",
  "fireu",
  "firme",
  "firmi",
  "fitor",
  "fixar",
  "fixeu",
  "flami",
  "flors",
  "flota",
  "floti",
  "focus",
  "folre",
  "foner",
  "forts",
  "fossa",
  "fotre",
  "frase",
  "fruir",
  "fugis",
  "fuita",
  "fulle",
  "fumen",
  "fumut",
  "furem",
  "furen",
  "futur",
  "gaben",
  "gabeu",
  "gafet",
  "galze",
  "galzi",
  "ganya",
  "garli",
  "gasal",
  "gaspi",
  "gatge",
  "gauba",
  "gebre",
  "geldo",
  "geleu",
  "gemat",
  "gemir",
  "gener",
  "gepic",
  "gerra",
  "gicar",
  "ginya",
  "giren",
  "glace",
  "glaci",
  "glauc",
  "godal",
  "godia",
  "gofri",
  "golfa",
  "gorga",
  "gorja",
  "gouda",
  "greix",
  "grier",
  "grima",
  "griso",
  "gropa",
  "gruau",
  "grufe",
  "gruta",
  "guano",
  "guapo",
  "guier",
  "guixe",
  "gules",
  "gussi",
  "hadal",
  "hades",
  "hagut",
  "hahni",
  "haiku",
  "hajat",
  "halau",
  "halin",
  "halur",
  "hansa",
  "hassi",
  "haure",
  "haver",
  "haveu",
  "havre",
  "henna",
  "herba",
  "hereu",
  "hertz",
  "heuen",
  "heuia",
  "heuin",
  "heura",
  "heure",
  "hevea",
  "hexil",
  "hiena",
  "hifal",
  "hilda",
  "himen",
  "hindi",
  "hissi",
  "homei",
  "honra",
  "honre",
  "honri",
  "horda",
  "horts",
  "hulla",
  "humil",
  "humor",
  "humus",
  "ideau",
  "ignot",
  "illot",
  "imago",
  "imido",
  "imite",
  "impur",
  "infix",
  "infli",
  "inici",
  "iridi",
  "irisa",
  "irona",
  "isart",
  "iscle",
  "isole",
  "isqui",
  "itera",
  "ivars",
  "ixent",
  "jacob",
  "jacta",
  "jacte",
  "jacti",
  "jafre",
  "jagui",
  "jagut",
  "jaiem",
  "jamai",
  "jamet",
  "janol",
  "janot",
  "janus",
  "jaume",
  "jaupa",
  "jaupe",
  "jaure",
  "jegui",
  "jeure",
  "joana",
  "jodar",
  "jofre",
  "jonca",
  "jorba",
  "jordi",
  "josep",
  "joule",
  "joval",
  "jover",
  "judai",
  "judes",
  "jugal",
  "jugat",
  "jugui",
  "julep",
  "jumel",
  "junta",
  "jurar",
  "jurat",
  "jurau",
  "jurca",
  "juren",
  "jureu",
  "jurin",
  "justa",
  "juste",
  "justi",
  "jutge",
  "jutgi",
  "kabul",
  "kappa",
  "karri",
  "kauri",
  "kenaf",
  "kenya",
  "khmer",
  "kraft",
  "labor",
  "lacot",
  "lacta",
  "lanau",
  "lapse",
  "largo",
  "lasca",
  "lauan",
  "laxar",
  "legal",
  "leila",
  "libat",
  "libau",
  "liben",
  "liceu",
  "lirat",
  "llaca",
  "llama",
  "llavi",
  "llera",
  "lloau",
  "llosa",
  "llufa",
  "llufe",
  "lluny",
  "loess",
  "longa",
  "luxar",
  "luxeu",
  "macip",
  "mages",
  "malla",
  "mapar",
  "maria",
  "marri",
  "matar",
  "matau",
  "mateu",
  "maure",
  "melis",
  "meneu",
  "menic",
  "merda",
  "merla",
  "metge",
  "midau",
  "mimau",
  "mimen",
  "minem",
  "minve",
  "minvi",
  "mioli",
  "mirem",
  "modal",
  "mofau",
  "mogol",
  "molar",
  "moles",
  "molia",
  "moqui",
  "morin",
  "mossa",
  "mudem",
  "mudeu",
  "munic",
  "munir",
  "mural",
  "murem",
  "music",
  "nacte",
  "nadeu",
  "nadin",
  "nafil",
  "nahua",
  "nanna",
  "nanni",
  "narra",
  "narre",
  "natja",
  "nauru",
  "navei",
  "nebot",
  "nedar",
  "nedem",
  "neden",
  "nedeu",
  "nedin",
  "negar",
  "negre",
  "neixi",
  "nepal",
  "nepta",
  "nervi",
  "neven",
  "neveu",
  "niant",
  "niava",
  "nicea",
  "nicol",
  "niera",
  "nigul",
  "nitro",
  "niuet",
  "noble",
  "nocat",
  "noces",
  "noesi",
  "nogui",
  "nomut",
  "nonat",
  "nonet",
  "norai",
  "notin",
  "noval",
  "noves",
  "nuava",
  "nucia",
  "nuesa",
  "nugau",
  "nugue",
  "nurag",
  "nyaca",
  "nyapa",
  "nyoca",
  "obiol",
  "oblia",
  "obren",
  "obreu",
  "obris",
  "octau",
  "octet",
  "ocult",
  "odiat",
  "odieu",
  "odore",
  "odori",
  "ofidi",
  "ofita",
  "ogiva",
  "olier",
  "olieu",
  "oliva",
  "olive",
  "oluja",
  "olvan",
  "ombre",
  "omega",
  "omeia",
  "ometi",
  "ondat",
  "onden",
  "ondin",
  "oneig",
  "operi",
  "opini",
  "oposa",
  "opose",
  "oposi",
  "optar",
  "oquer",
  "orada",
  "orbar",
  "orbau",
  "ordix",
  "oriac",
  "orinc",
  "oriol",
  "orosi",
  "ortiu",
  "osaka",
  "osees",
  "osmat",
  "osqui",
  "ostra",
  "ouera",
  "ovali",
  "ovidi",
  "ovina",
  "oxidi",
  "oxoni",
  "panda",
  "panna",
  "panxa",
  "paona",
  "parar",
  "parem",
  "parle",
  "parli",
  "passa",
  "pebre",
  "peier",
  "peira",
  "peixe",
  "penin",
  "pensi",
  "peone",
  "perix",
  "petge",
  "pilot",
  "pinxo",
  "pipar",
  "pipeu",
  "pipiu",
  "pirau",
  "piris",
  "pisar",
  "pitet",
  "piuat",
  "pixen",
  "plica",
  "ploga",
  "poder",
  "polla",
  "polpa",
  "ponia",
  "popar",
  "popem",
  "popeu",
  "popin",
  "porti",
  "posat",
  "posin",
  "pouau",
  "poueu",
  "prats",
  "propi",
  "pruna",
  "puell",
  "pugem",
  "punxa",
  "punxe",
  "qatar",
  "quall",
  "quant",
  "quark",
  "quars",
  "quasi",
  "queda",
  "quede",
  "quedi",
  "queix",
  "quema",
  "quera",
  "quere",
  "queta",
  "quica",
  "quico",
  "quiet",
  "quilo",
  "quina",
  "quint",
  "quirc",
  "quist",
  "quita",
  "quite",
  "quiti",
  "quota",
  "radom",
  "ralle",
  "rapem",
  "rapeu",
  "rapin",
  "rasem",
  "rasen",
  "rateu",
  "ratxa",
  "refeu",
  "reina",
  "relui",
  "rente",
  "repti",
  "reste",
  "retxe",
  "reves",
  "ribau",
  "riber",
  "ribes",
  "risse",
  "rombe",
  "ronsa",
  "rosec",
  "rumba",
  "rumor",
  "sabeu",
  "sabre",
  "sagal",
  "salat",
  "saldo",
  "salem",
  "salen",
  "sanat",
  "sanau",
  "santa",
  "sanui",
  "sarri",
  "seixa",
  "semem",
  "senda",
  "senil",
  "senya",
  "senye",
  "septe",
  "serem",
  "serre",
  "servi",
  "setet",
  "sexem",
  "sidra",
  "sifre",
  "sigma",
  "signi",
  "simun",
  "sinus",
  "sobra",
  "sodar",
  "solau",
  "soleu",
  "solti",
  "sompo",
  "sonat",
  "sonen",
  "soneu",
  "sopau",
  "sorts",
  "sotal",
  "sudan",
  "sumat",
  "surem",
  "surra",
  "sutge",
  "tabac",
  "tafur",
  "talau",
  "talpa",
  "tapet",
  "tapeu",
  "tapia",
  "tarde",
  "tassa",
  "tasta",
  "tatxe",
  "tatxi",
  "taxeu",
  "telen",
  "tibat",
  "tibet",
  "tifus",
  "tinga",
  "tolem",
  "tolim",
  "toliu",
  "tomar",
  "tomba",
  "tonal",
  "tonia",
  "topen",
  "toral",
  "torbi",
  "torre",
  "toset",
  "tosse",
  "trair",
  "trame",
  "trapa",
  "trast",
  "trave",
  "tremp",
  "triar",
  "trota",
  "tupen",
  "turet",
  "tussa",
  "tusse",
  "tusta",
  "twist",
  "ubald",
  "ubals",
  "ucant",
  "ucava",
  "udola",
  "udole",
  "ufana",
  "uissa",
  "uisse",
  "uissi",
  "uixer",
  "ujant",
  "ujava",
  "ullar",
  "ullat",
  "ullau",
  "ullet",
  "ulleu",
  "ullin",
  "ultra",
  "ulula",
  "ululi",
  "ulval",
  "umbre",
  "uneix",
  "unfla",
  "unfli",
  "ungim",
  "ungiu",
  "ungix",
  "ungla",
  "ungle",
  "ungli",
  "unint",
  "untat",
  "untau",
  "unten",
  "untet",
  "untin",
  "untor",
  "uquem",
  "uqueu",
  "uquin",
  "urani",
  "urban",
  "ureic",
  "ureid",
  "urent",
  "urgim",
  "urgir",
  "urgit",
  "urgiu",
  "urgix",
  "uriac",
  "urpar",
  "urpat",
  "urpau",
  "urpem",
  "urpeu",
  "urpim",
  "urpir",
  "urpit",
  "urpiu",
  "urrea",
  "usant",
  "usard",
  "usava",
  "usina",
  "ustec",
  "usual",
  "utiel",
  "uzbek",
  "vacar",
  "vagau",
  "vages",
  "vaire",
  "valga",
  "valls",
  "valor",
  "valse",
  "valva",
  "vamba",
  "vanem",
  "vaneu",
  "vanti",
  "vapor",
  "vaque",
  "varar",
  "vares",
  "vauma",
  "vedar",
  "vedat",
  "vedem",
  "vedin",
  "veges",
  "veiem",
  "velam",
  "velar",
  "velen",
  "veler",
  "venal",
  "venge",
  "venia",
  "venti",
  "verba",
  "versi",
  "vesat",
  "vessa",
  "veten",
  "vetin",
  "vetla",
  "vetli",
  "vexar",
  "vexau",
  "vexin",
  "vicie",
  "vilar",
  "vinca",
  "vinil",
  "viola",
  "viona",
  "virat",
  "vireu",
  "viseu",
  "visiu",
  "visor",
  "vists",
  "vital",
  "viuda",
  "vivia",
  "vivim",
  "vodka",
  "vogat",
  "vogue",
  "volea",
  "volti",
  "xabec",
  "xacar",
  "xacat",
  "xacra",
  "xafar",
  "xafau",
  "xafes",
  "xaiar",
  "xaiat",
  "xaiau",
  "xaier",
  "xalem",
  "xalen",
  "xaleu",
  "xamar",
  "xamat",
  "xamau",
  "xanca",
  "xapat",
  "xapot",
  "xarol",
  "xarot",
  "xarpi",
  "xarre",
  "xarri",
  "xaruc",
  "xauxa",
  "xaval",
  "xefla",
  "xelva",
  "xemic",
  "xerec",
  "xerra",
  "xerre",
  "xerri",
  "xicot",
  "xifri",
  "xinar",
  "xinat",
  "xinau",
  "xinem",
  "xinen",
  "xineu",
  "xipre",
  "xiric",
  "xiroi",
  "xisca",
  "xitem",
  "xiten",
  "xodos",
  "xolla",
  "xolle",
  "xolli",
  "xopat",
  "xopau",
  "xopem",
  "xopeu",
  "xoric",
  "xorre",
  "xorri",
  "xotis",
  "xotxo",
  "xover",
  "xubec",
  "xucla",
  "xucle",
  "xucli",
  "xueta",
  "xufar",
  "xufer",
  "xufla",
  "xuixe",
  "xumau",
  "xumen",
  "xumeu",
  "xumin",
  "xunga",
  "xupla",
  "xuple",
  "xupli",
  "xurla",
  "xurma",
  "xurmi",
  "xurra",
  "xurro",
  "xusma",
  "xutar",
  "xuten",
  "xutin",
  "zebra",
  "zelar",
  "zelat",
  "zelau",
  "zelen",
  "zeleu",
  "zelin",
  "zenit",
  "zigot",
  "zloty",
  "zoile",
  "zonal",
  "zonat",
];
