

export const TEXTS_ES = {
  GAME_TITLE: 'Jordle 🇪🇸',
  WIN_MESSAGES: ['Bien Hecho!', 'Impresionante!', 'Buen trabajo!', 'Genial!', 'Asombroso!', 'Espectacular!', 'Increíble!', 'Alucinante!', 'Extraordinario!'],
  GAME_COPIED_MESSAGE: 'Juego copiado al portapapeles',
  ABOUT_GAME_MESSAGE: 'Acerca de este juego',
  NOT_ENOUGH_LETTERS_MESSAGE: 'No hay suficientes letras',
  WORD_NOT_FOUND_MESSAGE: 'Palabra no encontrada',
  HARD_MODE_DESC: "Las letras adivinadas deben aparecer en la siguiente palabra",
  HARD_MODE_ALERT_MESSAGE: "Modo dificil habilitado!",
  CORRECT_WORD_MESSAGE: (solution: string) => `La palabra era ${solution}`,
  WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Debes usar ${guess} en esta posición ${position}`,
  NOT_CONTAINED_MESSAGE: (letter: string) => `Debe contener ${letter}`,
  URL_DEFINITION: (word: string) => `https://dle.rae.es/${word}`,
  ENTER_TEXT: 'Enviar',
  DELETE_TEXT: 'Borrar',
  STATISTICS_TITLE: 'Estadísticas',
  GUESS_DISTRIBUTION_TEXT: 'Distribución de conjeturas',
  NEW_WORD_TEXT: 'Nueva palabra en ',
  SHARE_TEXT: 'Compartir',
  TOTAL_TRIES_TEXT: 'Total intentos',
  SUCCESS_RATE_TEXT: 'Aciertos',
  CURRENT_STREAK_TEXT: 'Racha actual',
  BEST_STREAK_TEXT: 'Mejor racha',
  INFO_TITLE: "Cómo jugar",
  INFO_INTRO: "Adivina la palabra en 6 intentos. Después de cada suposición, el color de las fichas cambiará para mostrar qué tan cerca estuvo su suposición de la palabra.",
  INFO_CORRECT: "La letra 'W' está en la palabra y en el lugar correcto.",
  INFO_PRESENT: "La letra 'L' está en la palabra pero en el lugar equivocado.",
  INFO_ABSENT: "La letra 'U' no está en la palabra en ningún lugar.",
  PLAY_MORE: "Jugar de nuevo!"
};


