export const VALID_GUESSES_FR = [
  "aaron",
  "abats",
  "abces",
  "abdel",
  "abime",
  "aboli",
  "abord",
  "about",
  "acces",
  "accru",
  "accus",
  "acere",
  "achat",
  "acide",
  "acier",
  "actes",
  "actif",
  "adams",
  "adieu",
  "admet",
  "admis",
  "adore",
  "adore",
  "affut",
  "agees",
  "agent",
  "agile",
  "agite",
  "agite",
  "agnes",
  "agnus",
  "ahmed",
  "aider",
  "aides",
  "aides",
  "aient",
  "aigle",
  "aigre",
  "aigri",
  "aigue",
  "ailes",
  "aille",
  "aimee",
  "aimer",
  "ainee",
  "aines",
  "ainsi",
  "aires",
  "aisee",
  "aises",
  "aisne",
  "ajout",
  "akbar",
  "alain",
  "albin",
  "album",
  "aleas",
  "alene",
  "alger",
  "algue",
  "alias",
  "alibi",
  "alice",
  "alite",
  "allah",
  "allan",
  "allee",
  "allen",
  "aller",
  "alles",
  "allez",
  "allie",
  "aloes",
  "alors",
  "alpes",
  "alpha",
  "alpin",
  "amant",
  "ambon",
  "amene",
  "amene",
  "amere",
  "amibe",
  "amont",
  "amour",
  "amour",
  "amphi",
  "ample",
  "amuse",
  "anche",
  "ancre",
  "andre",
  "anges",
  "angle",
  "anime",
  "anime",
  "annee",
  "annie",
  "anode",
  "antan",
  "antin",
  "aorte",
  "appel",
  "appui",
  "apres",
  "apres",
  "aptes",
  "arabe",
  "arbre",
  "arbre",
  "arche",
  "arene",
  "arete",
  "argot",
  "arias",
  "aride",
  "armee",
  "armer",
  "armes",
  "armes",
  "arome",
  "arret",
  "asile",
  "aspic",
  "assen",
  "assez",
  "assez",
  "assis",
  "astre",
  "athee",
  "atlas",
  "atome",
  "atout",
  "aucun",
  "audit",
  "aussi",
  "autel",
  "autos",
  "autre",
  "autre",
  "autre",
  "avais",
  "avait",
  "avant",
  "avant",
  "avant",
  "avant",
  "avant",
  "avant",
  "avant",
  "avare",
  "avere",
  "aveux",
  "avide",
  "avide",
  "aviez",
  "avion",
  "avion",
  "avise",
  "avive",
  "avoir",
  "avons",
  "avoue",
  "avoue",
  "avril",
  "ayant",
  "azeri",
  "azote",
  "azote",
  "bacle",
  "bacon",
  "baden",
  "badge",
  "baffe",
  "bagne",
  "bague",
  "bahut",
  "bains",
  "bains",
  "baker",
  "bakou",
  "balai",
  "balle",
  "balte",
  "banal",
  "banco",
  "bancs",
  "bande",
  "banjo",
  "barbe",
  "barbu",
  "barde",
  "barge",
  "baril",
  "baron",
  "barre",
  "barre",
  "barry",
  "basee",
  "baser",
  "bases",
  "basse",
  "batir",
  "baton",
  "battu",
  "baume",
  "baver",
  "baver",
  "bazar",
  "beaux",
  "bebes",
  "bebes",
  "becot",
  "beige",
  "bekaa",
  "beler",
  "belge",
  "belle",
  "belle",
  "benin",
  "benin",
  "benir",
  "bercy",
  "beret",
  "berge",
  "berne",
  "berne",
  "berry",
  "betes",
  "beton",
  "beton",
  "bette",
  "bevue",
  "biais",
  "bible",
  "bible",
  "bidon",
  "biens",
  "biere",
  "bigle",
  "bijou",
  "bilan",
  "biler",
  "billy",
  "bique",
  "bison",
  "bisou",
  "bitos",
  "black",
  "blair",
  "blake",
  "blame",
  "blanc",
  "blanc",
  "blase",
  "bleme",
  "bleue",
  "bleus",
  "blini",
  "bloch",
  "blocs",
  "blois",
  "blond",
  "blues",
  "bocal",
  "boeuf",
  "boire",
  "boite",
  "boite",
  "bombe",
  "bonde",
  "bonds",
  "bondy",
  "bongo",
  "bonne",
  "bonte",
  "bonus",
  "borde",
  "bords",
  "boris",
  "borna",
  "borne",
  "borne",
  "bosse",
  "bossu",
  "botha",
  "botte",
  "boude",
  "bouee",
  "bouge",
  "bouge",
  "boule",
  "bourg",
  "bouse",
  "boute",
  "bouts",
  "bovin",
  "boyau",
  "boyer",
  "brame",
  "brave",
  "bravo",
  "break",
  "brefs",
  "brest",
  "breve",
  "bribe",
  "brice",
  "bride",
  "brise",
  "brise",
  "bronx",
  "brown",
  "bruce",
  "bruit",
  "brule",
  "brume",
  "brune",
  "bruno",
  "brute",
  "buire",
  "bulbe",
  "bulle",
  "burin",
  "buter",
  "butin",
  "butor",
  "butte",
  "cable",
  "cable",
  "cabot",
  "cabri",
  "cacao",
  "cache",
  "cache",
  "cache",
  "cadet",
  "cadre",
  "caduc",
  "cafes",
  "caire",
  "cairn",
  "caler",
  "calin",
  "calme",
  "campe",
  "camps",
  "camus",
  "canal",
  "canne",
  "canne",
  "canon",
  "canot",
  "capes",
  "carat",
  "cargo",
  "carlo",
  "carma",
  "carpe",
  "carre",
  "carte",
  "carte",
  "caser",
  "cases",
  "casse",
  "casse",
  "casus",
  "catin",
  "cause",
  "cause",
  "caves",
  "ceder",
  "cedex",
  "celer",
  "celle",
  "celle",
  "celle",
  "celui",
  "celui",
  "celui",
  "cense",
  "cents",
  "cergy",
  "cerne",
  "cesar",
  "cesse",
  "cesse",
  "cette",
  "chair",
  "chale",
  "champ",
  "champ",
  "champ",
  "champ",
  "chant",
  "chaos",
  "chars",
  "chats",
  "chaud",
  "chaux",
  "chefs",
  "chere",
  "cheri",
  "chers",
  "chien",
  "chier",
  "chili",
  "chine",
  "chocs",
  "choir",
  "choix",
  "chope",
  "chose",
  "chute",
  "chute",
  "chute",
  "cibla",
  "cible",
  "cieux",
  "citee",
  "citer",
  "cites",
  "civil",
  "clair",
  "clame",
  "clamp",
  "clans",
  "clark",
  "clefs",
  "clerc",
  "clips",
  "clope",
  "clore",
  "close",
  "cloud",
  "clown",
  "clubs",
  "cocon",
  "codes",
  "coeur",
  "cohen",
  "cohue",
  "coins",
  "colin",
  "colis",
  "colle",
  "combe",
  "comme",
  "comte",
  "comte",
  "concu",
  "conge",
  "congo",
  "conne",
  "connu",
  "conte",
  "conte",
  "copie",
  "coppi",
  "coque",
  "coran",
  "corde",
  "coree",
  "corne",
  "cornu",
  "corps",
  "corse",
  "cosse",
  "costa",
  "coste",
  "cotee",
  "cotes",
  "cotes",
  "coton",
  "coude",
  "coule",
  "coupe",
  "coupe",
  "coupe",
  "coups",
  "cours",
  "court",
  "couru",
  "coute",
  "coute",
  "couts",
  "crabe",
  "crack",
  "craie",
  "crane",
  "credo",
  "creee",
  "creer",
  "crees",
  "creil",
  "creme",
  "creme",
  "crepe",
  "crepi",
  "crepu",
  "crete",
  "creux",
  "creve",
  "creve",
  "crier",
  "crime",
  "crise",
  "crois",
  "croit",
  "croix",
  "cruel",
  "cuber",
  "cubes",
  "cuire",
  "cuite",
  "culot",
  "culte",
  "cumul",
  "curer",
  "curie",
  "cycle",
  "cygne",
  "cyril",
  "dague",
  "dakar",
  "dalle",
  "damas",
  "dames",
  "dandy",
  "danse",
  "dante",
  "datas",
  "datee",
  "dater",
  "dates",
  "datif",
  "david",
  "davis",
  "deale",
  "debat",
  "debit",
  "debre",
  "debut",
  "debut",
  "deces",
  "deche",
  "dechu",
  "decor",
  "decri",
  "decue",
  "decus",
  "dedie",
  "defis",
  "degat",
  "degel",
  "degre",
  "delai",
  "delie",
  "delit",
  "delta",
  "demie",
  "demis",
  "demon",
  "denis",
  "dense",
  "dents",
  "dents",
  "depit",
  "depit",
  "depot",
  "desir",
  "dette",
  "deuil",
  "devez",
  "devis",
  "devot",
  "devra",
  "diane",
  "diego",
  "diete",
  "dieux",
  "digne",
  "digue",
  "dijon",
  "dilue",
  "diner",
  "dires",
  "disse",
  "dites",
  "divan",
  "divin",
  "dogme",
  "doigt",
  "doive",
  "donna",
  "donne",
  "donne",
  "dorer",
  "doser",
  "doses",
  "dotee",
  "doter",
  "dotes",
  "doubs",
  "douce",
  "doues",
  "douma",
  "doute",
  "douze",
  "doyen",
  "drame",
  "dreux",
  "droit",
  "drole",
  "drome",
  "dumas",
  "dunes",
  "duper",
  "dupuy",
  "duras",
  "duree",
  "durer",
  "dures",
  "duvet",
  "ebahi",
  "ebene",
  "ecale",
  "ecart",
  "echec",
  "echos",
  "eclat",
  "ecole",
  "ecran",
  "ecrie",
  "ecrit",
  "ecume",
  "edgar",
  "edite",
  "edith",
  "effet",
  "egale",
  "egard",
  "egaux",
  "egide",
  "elans",
  "eleve",
  "eleve",
  "elias",
  "elime",
  "elire",
  "elite",
  "elles",
  "eloge",
  "email",
  "emane",
  "emile",
  "emise",
  "empan",
  "encre",
  "enfer",
  "enfin",
  "enfle",
  "engin",
  "enjeu",
  "ennui",
  "entre",
  "entre",
  "envie",
  "envoi",
  "envol",
  "epais",
  "epate",
  "epave",
  "epice",
  "epier",
  "epine",
  "epoux",
  "epris",
  "erich",
  "errer",
  "essai",
  "essor",
  "etage",
  "etain",
  "etais",
  "etait",
  "etame",
  "etang",
  "etant",
  "etape",
  "etats",
  "etats",
  "etaye",
  "etron",
  "etude",
  "evade",
  "evase",
  "evert",
  "evier",
  "evite",
  "evite",
  "exact",
  "exces",
  "exclu",
  "exige",
  "exige",
  "exigu",
  "exile",
  "exode",
  "extra",
  "fable",
  "faces",
  "fache",
  "facon",
  "facto",
  "fades",
  "fagne",
  "fagot",
  "faite",
  "faite",
  "faits",
  "faits",
  "fallu",
  "falot",
  "faner",
  "fange",
  "farce",
  "fasse",
  "faste",
  "fatal",
  "fatum",
  "faune",
  "faure",
  "faute",
  "faute",
  "fauve",
  "feint",
  "feler",
  "felix",
  "felon",
  "femme",
  "fente",
  "ferai",
  "ferme",
  "ferme",
  "ferre",
  "ferry",
  "feter",
  "fetes",
  "fibre",
  "fiche",
  "fichu",
  "fidel",
  "fiere",
  "fiers",
  "figue",
  "filer",
  "files",
  "filet",
  "fille",
  "filme",
  "films",
  "filon",
  "filou",
  "final",
  "finie",
  "finir",
  "finis",
  "finit",
  "firme",
  "fixee",
  "fixer",
  "fixes",
  "fixes",
  "flair",
  "flanc",
  "flash",
  "fleau",
  "fleur",
  "flore",
  "flots",
  "floue",
  "flous",
  "foire",
  "folie",
  "folie",
  "folle",
  "fonce",
  "fonda",
  "fonde",
  "fonde",
  "fonds",
  "fonte",
  "force",
  "force",
  "forer",
  "foret",
  "forge",
  "forge",
  "forme",
  "forme",
  "forte",
  "forts",
  "forum",
  "fosse",
  "fosse",
  "fouad",
  "fouet",
  "fouir",
  "foule",
  "foule",
  "fours",
  "foyer",
  "frais",
  "franc",
  "franc",
  "franc",
  "franc",
  "frank",
  "franz",
  "frein",
  "frere",
  "freud",
  "frigo",
  "frime",
  "frire",
  "frise",
  "froid",
  "frole",
  "front",
  "fruit",
  "fuchs",
  "fuite",
  "fumee",
  "fumer",
  "fumet",
  "furet",
  "furie",
  "fusee",
  "fusil",
  "futur",
  "gabon",
  "gaffe",
  "gager",
  "gages",
  "gagne",
  "gagne",
  "gaine",
  "gains",
  "gamin",
  "gamme",
  "ganse",
  "gants",
  "garce",
  "garde",
  "garde",
  "garde",
  "garde",
  "garde",
  "garde",
  "garde",
  "garer",
  "gares",
  "gater",
  "gaver",
  "gazon",
  "geant",
  "geler",
  "gemir",
  "gener",
  "genes",
  "genes",
  "genie",
  "genou",
  "genre",
  "geole",
  "gerbe",
  "geree",
  "gerer",
  "geres",
  "germe",
  "gesir",
  "geste",
  "gibet",
  "gifle",
  "gigot",
  "giral",
  "giron",
  "gitan",
  "givre",
  "glace",
  "glace",
  "globe",
  "glose",
  "glose",
  "gober",
  "godet",
  "golfe",
  "gombo",
  "gomez",
  "gomme",
  "gomme",
  "gomme",
  "gorge",
  "gosse",
  "goulu",
  "gouts",
  "grace",
  "grade",
  "grain",
  "grand",
  "grand",
  "grand",
  "grant",
  "grave",
  "grave",
  "grece",
  "greco",
  "grecs",
  "green",
  "grele",
  "grene",
  "grenu",
  "greve",
  "grise",
  "grive",
  "grosz",
  "group",
  "guepe",
  "guere",
  "gueux",
  "guide",
  "guise",
  "guise",
  "gupta",
  "gypse",
  "habit",
  "habre",
  "hache",
  "hades",
  "hagen",
  "haine",
  "haiti",
  "haler",
  "haler",
  "halle",
  "halte",
  "hampe",
  "hanoi",
  "hante",
  "hante",
  "happe",
  "harpe",
  "harry",
  "hater",
  "haute",
  "hauts",
  "havas",
  "havel",
  "havre",
  "haydn",
  "hebdo",
  "helas",
  "heler",
  "henri",
  "henry",
  "herbe",
  "hernu",
  "heron",
  "heros",
  "herve",
  "heure",
  "hibou",
  "hiver",
  "hoche",
  "homme",
  "honda",
  "honte",
  "horst",
  "hotel",
  "hotel",
  "hotes",
  "houle",
  "house",
  "huile",
  "humer",
  "hutte",
  "hyene",
  "hymne",
  "hyper",
  "ibert",
  "iceux",
  "icone",
  "ideal",
  "idees",
  "idiot",
  "image",
  "image",
  "imide",
  "imper",
  "impie",
  "impot",
  "impot",
  "impur",
  "index",
  "indic",
  "indre",
  "inoui",
  "insee",
  "inter",
  "intra",
  "iouri",
  "irait",
  "iront",
  "isere",
  "islam",
  "isole",
  "issue",
  "issus",
  "jacky",
  "jacob",
  "jadis",
  "jambe",
  "james",
  "japon",
  "jaser",
  "jauge",
  "jaune",
  "javel",
  "jeans",
  "jesse",
  "jesus",
  "jesus",
  "jesus",
  "jetee",
  "jeter",
  "jetes",
  "jeton",
  "jette",
  "jeudi",
  "jeune",
  "jeune",
  "jimmy",
  "joies",
  "joint",
  "joker",
  "jolie",
  "jolis",
  "jones",
  "josef",
  "jouee",
  "jouer",
  "jouet",
  "jouir",
  "jouit",
  "jours",
  "joyce",
  "judas",
  "jugee",
  "juger",
  "juges",
  "juges",
  "juifs",
  "juive",
  "juive",
  "jules",
  "junte",
  "jupes",
  "jupon",
  "juppe",
  "jurer",
  "jures",
  "juron",
  "jurys",
  "juste",
  "kadar",
  "kafka",
  "kanak",
  "kazan",
  "kelly",
  "kenya",
  "kharg",
  "kilos",
  "klein",
  "krach",
  "kurde",
  "kyste",
  "label",
  "lacet",
  "lacez",
  "lache",
  "lache",
  "lacte",
  "laics",
  "laine",
  "laine",
  "laius",
  "lamie",
  "lampe",
  "lampe",
  "lampe",
  "lance",
  "lance",
  "lange",
  "lapin",
  "laque",
  "large",
  "larme",
  "laser",
  "lasse",
  "latin",
  "latte",
  "laure",
  "laval",
  "laver",
  "leche",
  "lecon",
  "leeds",
  "legal",
  "leger",
  "lendl",
  "lente",
  "lents",
  "leroy",
  "leser",
  "letal",
  "leurs",
  "levee",
  "lever",
  "leves",
  "levre",
  "lewis",
  "liant",
  "liban",
  "libre",
  "libye",
  "licou",
  "liees",
  "liege",
  "liens",
  "lieux",
  "ligne",
  "ligue",
  "lilas",
  "lille",
  "limer",
  "linda",
  "linge",
  "lions",
  "lires",
  "lisse",
  "liste",
  "liszt",
  "litre",
  "livre",
  "livre",
  "lobby",
  "local",
  "loger",
  "loges",
  "loges",
  "logis",
  "loire",
  "longo",
  "longs",
  "lopin",
  "loque",
  "louer",
  "louis",
  "loupe",
  "loups",
  "lourd",
  "loyal",
  "loyer",
  "lucas",
  "lucie",
  "lueur",
  "lueur",
  "luire",
  "lundi",
  "lutte",
  "luxer",
  "lycee",
  "lysee",
  "macao",
  "macon",
  "macro",
  "madre",
  "madre",
  "mafia",
  "magie",
  "maine",
  "mains",
  "maire",
  "major",
  "malin",
  "malle",
  "malle",
  "malte",
  "maman",
  "manet",
  "mange",
  "manie",
  "manne",
  "marat",
  "mardi",
  "maree",
  "marge",
  "maria",
  "marie",
  "marie",
  "marin",
  "mario",
  "marks",
  "marne",
  "maroc",
  "marre",
  "masse",
  "massy",
  "match",
  "match",
  "mater",
  "maths",
  "matif",
  "matin",
  "matra",
  "mauve",
  "mayas",
  "meche",
  "media",
  "media",
  "mefie",
  "melee",
  "meler",
  "meles",
  "melon",
  "melon",
  "memes",
  "menee",
  "mener",
  "menes",
  "menus",
  "merci",
  "merde",
  "meres",
  "merle",
  "messe",
  "metal",
  "meteo",
  "metis",
  "metre",
  "metro",
  "mette",
  "meurt",
  "meuse",
  "meyer",
  "miami",
  "micro",
  "micro",
  "micro",
  "micro",
  "miens",
  "mieux",
  "milan",
  "milan",
  "mille",
  "mince",
  "mines",
  "minet",
  "minou",
  "miser",
  "mises",
  "mitre",
  "mixte",
  "moche",
  "modes",
  "moine",
  "moins",
  "moise",
  "moisi",
  "moite",
  "monde",
  "monte",
  "monte",
  "moore",
  "moque",
  "moral",
  "morin",
  "morne",
  "morte",
  "morts",
  "morue",
  "morve",
  "motif",
  "motos",
  "moule",
  "moyen",
  "moyen",
  "muets",
  "mufle",
  "mules",
  "munir",
  "munis",
  "mures",
  "muret",
  "murir",
  "musee",
  "music",
  "myope",
  "mythe",
  "nabot",
  "nager",
  "nancy",
  "nappe",
  "natal",
  "natif",
  "natte",
  "naval",
  "navet",
  "nazie",
  "nazis",
  "neant",
  "negre",
  "neige",
  "nerfs",
  "nerfs",
  "nerfs",
  "nervi",
  "nette",
  "neufs",
  "neuve",
  "neveu",
  "niais",
  "niant",
  "niece",
  "nigel",
  "niger",
  "nimes",
  "nobel",
  "noble",
  "noces",
  "noeud",
  "noire",
  "noirs",
  "nolis",
  "nomme",
  "nomme",
  "nonce",
  "nonne",
  "norme",
  "noter",
  "notes",
  "notre",
  "notre",
  "nouer",
  "noyau",
  "noyer",
  "nuage",
  "nuire",
  "nuits",
  "nulle",
  "nulle",
  "nuque",
  "nurse",
  "oasis",
  "obeir",
  "obese",
  "objet",
  "obtus",
  "ocean",
  "odeon",
  "odeur",
  "odile",
  "oeufs",
  "offre",
  "oisif",
  "olive",
  "ombre",
  "oncle",
  "oncle",
  "ondes",
  "ongle",
  "opera",
  "opere",
  "opere",
  "orage",
  "orale",
  "ordre",
  "orgue",
  "orner",
  "orsay",
  "ortie",
  "oscar",
  "osent",
  "otage",
  "ouate",
  "oubli",
  "ouest",
  "ouest",
  "ouies",
  "oulan",
  "oural",
  "ourga",
  "ourse",
  "outil",
  "outre",
  "ouvre",
  "ovule",
  "oxyde",
  "pablo",
  "pacte",
  "pages",
  "paien",
  "paire",
  "pairs",
  "palir",
  "palis",
  "palme",
  "panne",
  "parce",
  "parce",
  "parcs",
  "parer",
  "paris",
  "paris",
  "parle",
  "parle",
  "parmi",
  "paroi",
  "parti",
  "parts",
  "parue",
  "parus",
  "parut",
  "passa",
  "passe",
  "passe",
  "passe",
  "patee",
  "pater",
  "pates",
  "pathe",
  "patir",
  "patre",
  "patte",
  "paume",
  "pause",
  "payee",
  "payer",
  "payes",
  "payot",
  "peage",
  "peche",
  "peche",
  "pedro",
  "pegre",
  "peine",
  "peine",
  "peint",
  "pekin",
  "peler",
  "pelle",
  "penal",
  "pence",
  "pendu",
  "penis",
  "penne",
  "pense",
  "pense",
  "pente",
  "pentu",
  "pepin",
  "percu",
  "perdu",
  "perec",
  "peres",
  "peres",
  "peril",
  "perir",
  "perle",
  "perme",
  "perou",
  "perte",
  "peser",
  "peste",
  "peter",
  "petit",
  "petit",
  "petre",
  "petri",
  "peuhl",
  "phare",
  "phase",
  "philo",
  "phono",
  "photo",
  "piano",
  "piano",
  "piece",
  "pieds",
  "piege",
  "piete",
  "pieux",
  "piger",
  "pilon",
  "pilot",
  "pince",
  "pinte",
  "pique",
  "pires",
  "piste",
  "pitie",
  "pitre",
  "pivot",
  "place",
  "place",
  "plage",
  "plaie",
  "plait",
  "plane",
  "plans",
  "plant",
  "plate",
  "plate",
  "plate",
  "plats",
  "plebe",
  "plein",
  "plier",
  "plomb",
  "plouc",
  "pluie",
  "plume",
  "pneus",
  "poche",
  "poele",
  "poeme",
  "poete",
  "poher",
  "poids",
  "poing",
  "point",
  "poire",
  "polar",
  "poles",
  "polir",
  "pomme",
  "pomme",
  "pomme",
  "pomme",
  "pompe",
  "poney",
  "ponts",
  "porta",
  "porte",
  "porte",
  "porte",
  "porte",
  "porte",
  "porte",
  "porte",
  "porte",
  "porte",
  "porte",
  "porto",
  "ports",
  "posee",
  "poser",
  "poses",
  "poste",
  "poste",
  "potes",
  "potin",
  "pouce",
  "poule",
  "pouls",
  "poupe",
  "prend",
  "prete",
  "prete",
  "prets",
  "preux",
  "prevu",
  "prier",
  "pries",
  "prime",
  "prime",
  "prise",
  "prive",
  "prive",
  "prive",
  "probe",
  "proie",
  "promu",
  "prone",
  "prose",
  "prost",
  "prote",
  "proue",
  "prune",
  "puant",
  "puces",
  "puche",
  "puise",
  "puits",
  "pulpe",
  "punir",
  "pures",
  "purge",
  "purin",
  "quais",
  "quand",
  "quant",
  "quart",
  "quasi",
  "quels",
  "quete",
  "queue",
  "queue",
  "queue",
  "quota",
  "rabat",
  "races",
  "radar",
  "radin",
  "radio",
  "radis",
  "radis",
  "ragot",
  "raide",
  "raids",
  "rails",
  "raler",
  "rames",
  "rampe",
  "range",
  "range",
  "rangs",
  "raoul",
  "raper",
  "rares",
  "raser",
  "rater",
  "rates",
  "ravel",
  "ravin",
  "ravir",
  "ravis",
  "rayer",
  "rayon",
  "reagi",
  "rebut",
  "recel",
  "reche",
  "recif",
  "recit",
  "recit",
  "recit",
  "recit",
  "recre",
  "recue",
  "recul",
  "recus",
  "recut",
  "reels",
  "reelu",
  "refus",
  "regie",
  "regis",
  "regle",
  "regle",
  "reglo",
  "regne",
  "reich",
  "reims",
  "reine",
  "reins",
  "rejet",
  "relie",
  "relie",
  "remet",
  "remis",
  "remue",
  "rende",
  "rendu",
  "renes",
  "renie",
  "renom",
  "rente",
  "repas",
  "repit",
  "repli",
  "repos",
  "resta",
  "reste",
  "reste",
  "reuni",
  "rever",
  "reves",
  "revet",
  "revue",
  "rhone",
  "rhume",
  "riche",
  "riper",
  "rires",
  "risee",
  "rites",
  "rival",
  "rives",
  "robes",
  "robin",
  "robot",
  "roche",
  "roder",
  "roger",
  "roles",
  "roman",
  "romeo",
  "rompt",
  "rompu",
  "ronde",
  "roneo",
  "rosee",
  "roses",
  "rossi",
  "rotir",
  "rouen",
  "rouer",
  "roues",
  "rouge",
  "rouge",
  "roule",
  "round",
  "route",
  "rover",
  "royal",
  "ruban",
  "ruche",
  "rudes",
  "rugby",
  "ruine",
  "rupin",
  "rural",
  "ruser",
  "russe",
  "sabir",
  "sable",
  "sabre",
  "sache",
  "sacre",
  "sacro",
  "sages",
  "saine",
  "saint",
  "saint",
  "saisi",
  "saler",
  "sales",
  "salin",
  "salir",
  "salle",
  "salon",
  "salon",
  "salue",
  "salue",
  "salut",
  "sante",
  "saone",
  "saoul",
  "saper",
  "sapes",
  "sapin",
  "sapin",
  "sarre",
  "satan",
  "satin",
  "sauce",
  "sauge",
  "saule",
  "saura",
  "saute",
  "saute",
  "sauve",
  "sauve",
  "savez",
  "savon",
  "saxon",
  "sceau",
  "scene",
  "scene",
  "scier",
  "scoop",
  "score",
  "scott",
  "seche",
  "seide",
  "seine",
  "seing",
  "seins",
  "seize",
  "selim",
  "selle",
  "selon",
  "semer",
  "semis",
  "senat",
  "senna",
  "senne",
  "sense",
  "sente",
  "senti",
  "seoir",
  "seoul",
  "serai",
  "serbe",
  "serge",
  "serie",
  "serra",
  "serre",
  "serre",
  "serve",
  "servi",
  "seuil",
  "seule",
  "seuls",
  "sevir",
  "shell",
  "shoah",
  "shoot",
  "short",
  "sicav",
  "siege",
  "siens",
  "sigle",
  "signe",
  "signe",
  "signe",
  "simon",
  "singe",
  "sinon",
  "sioux",
  "sirop",
  "sites",
  "sitot",
  "situe",
  "situe",
  "smith",
  "snack",
  "snack",
  "sobre",
  "socio",
  "socio",
  "socle",
  "soeur",
  "sofia",
  "soins",
  "soirs",
  "solde",
  "somme",
  "sonde",
  "songe",
  "songe",
  "sonne",
  "sonne",
  "sorte",
  "sorte",
  "sorti",
  "souci",
  "soude",
  "soupe",
  "sourd",
  "soute",
  "speed",
  "spore",
  "sport",
  "stade",
  "stage",
  "stand",
  "stars",
  "start",
  "stasi",
  "statu",
  "stein",
  "stern",
  "steve",
  "stick",
  "stock",
  "style",
  "stylo",
  "stylo",
  "stylo",
  "suant",
  "suave",
  "subie",
  "subir",
  "subit",
  "sucer",
  "sucre",
  "sucre",
  "suede",
  "sueur",
  "suffi",
  "suite",
  "suivi",
  "sujet",
  "super",
  "sures",
  "surgi",
  "swapo",
  "sympa",
  "syrie",
  "tabac",
  "table",
  "tabou",
  "tache",
  "tache",
  "taire",
  "talon",
  "talus",
  "tamis",
  "tango",
  "tanin",
  "tante",
  "taper",
  "tapie",
  "tapis",
  "tarde",
  "tarde",
  "tarif",
  "tarin",
  "tarir",
  "taris",
  "tarte",
  "tassa",
  "tasse",
  "tasse",
  "tater",
  "tater",
  "taule",
  "taupe",
  "taxer",
  "taxes",
  "taxis",
  "tchad",
  "teint",
  "telex",
  "telle",
  "telle",
  "tempe",
  "tempo",
  "temps",
  "temps",
  "tendu",
  "tenir",
  "tenor",
  "tenta",
  "tente",
  "tente",
  "tenue",
  "tenus",
  "terme",
  "terne",
  "terre",
  "tests",
  "tetes",
  "texas",
  "texte",
  "theme",
  "theme",
  "these",
  "thune",
  "tiare",
  "tibet",
  "tibia",
  "tiede",
  "tiens",
  "tient",
  "tiers",
  "tigre",
  "tigre",
  "tilde",
  "times",
  "timon",
  "timor",
  "tiree",
  "tirer",
  "tires",
  "tisse",
  "tissu",
  "titan",
  "titre",
  "titus",
  "todor",
  "toile",
  "toits",
  "tokyo",
  "tokyo",
  "tolle",
  "tombe",
  "tombe",
  "tonne",
  "tonus",
  "toque",
  "torse",
  "total",
  "touer",
  "tours",
  "trace",
  "trace",
  "trace",
  "tract",
  "trahi",
  "train",
  "trait",
  "trame",
  "treve",
  "tribu",
  "trier",
  "trois",
  "trois",
  "trois",
  "trois",
  "trois",
  "tronc",
  "tronc",
  "trone",
  "trous",
  "trust",
  "tuant",
  "tubes",
  "tuees",
  "tuent",
  "tueur",
  "tuile",
  "tunis",
  "turbo",
  "turin",
  "tuyau",
  "types",
  "tyran",
  "ukase",
  "ultra",
  "ultra",
  "unies",
  "union",
  "union",
  "unite",
  "urine",
  "urnes",
  "usage",
  "usine",
  "usuel",
  "usure",
  "utile",
  "vache",
  "vadim",
  "vague",
  "valet",
  "valmy",
  "valse",
  "value",
  "vanne",
  "varie",
  "varie",
  "varna",
  "vaste",
  "vecue",
  "veine",
  "vendu",
  "venez",
  "venin",
  "venir",
  "vente",
  "vents",
  "venue",
  "venus",
  "venus",
  "verbe",
  "verge",
  "verne",
  "verni",
  "verra",
  "verre",
  "verse",
  "verse",
  "verso",
  "verte",
  "verts",
  "vertu",
  "verve",
  "veste",
  "vetir",
  "vetus",
  "veule",
  "veuve",
  "vexer",
  "vichy",
  "video",
  "vider",
  "vides",
  "vieil",
  "viens",
  "vient",
  "vieux",
  "vigne",
  "villa",
  "ville",
  "vingt",
  "virer",
  "viril",
  "virus",
  "visas",
  "visee",
  "viser",
  "vital",
  "vitez",
  "vitre",
  "vitro",
  "vives",
  "vivra",
  "vivre",
  "vodka",
  "voeux",
  "vogel",
  "vogue",
  "voici",
  "voies",
  "voile",
  "voire",
  "volee",
  "voler",
  "volet",
  "volga",
  "volvo",
  "vomir",
  "votee",
  "voter",
  "votes",
  "votre",
  "votre",
  "vouee",
  "vouer",
  "voues",
  "voulu",
  "voute",
  "voyez",
  "voyou",
  "vraca",
  "vraie",
  "vrais",
  "vulgo",
  "wagon",
  "wagon",
  "wales",
  "wayne",
  "wells",
  "white",
  "willy",
  "world",
  "worms",
  "yacht",
  "yalta",
  "yemen",
  "young",
  "yukon",
  "zaire",
  "zebre",
  "zones",
];
