export const WORDS_IT = [
  "abaca",
  "abaco",
  "abate",
  "abati",
  "abato",
  "abavi",
  "abavo",
  "abbai",
  "abbia",
  "abbui",
  "abena",
  "abene",
  "abete",
  "abeti",
  "abeto",
  "abici",
  "abile",
  "abili",
  "abita",
  "abiti",
  "abito",
  "aboli",
  "abuna",
  "abusa",
  "abusi",
  "abuso",
  "acari",
  "acaro",
  "acati",
  "acato",
  "acazi",
  "acche",
  "accia",
  "accio",
  "aceri",
  "acero",
  "acese",
  "acesi",
  "aceti",
  "aceto",
  "achea",
  "achee",
  "achei",
  "acheo",
  "acida",
  "acide",
  "acidi",
  "acido",
  "acini",
  "acino",
  "acori",
  "acoro",
  "acqua",
  "acque",
  "acqui",
  "acquo",
  "actea",
  "actee",
  "acuii",
  "acume",
  "acumi",
  "acuta",
  "acute",
  "acuti",
  "acuto",
  "adagi",
  "adami",
  "adamo",
  "addai",
  "addax",
  "addia",
  "addio",
  "aderi",
  "adesa",
  "adese",
  "adesi",
  "adeso",
  "adibi",
  "adima",
  "adimi",
  "adimo",
  "adipe",
  "adipi",
  "adira",
  "adire",
  "adiri",
  "adiro",
  "adita",
  "adite",
  "aditi",
  "adito",
  "adiva",
  "adivi",
  "adivo",
  "adobe",
  "adona",
  "adone",
  "adoni",
  "adono",
  "adora",
  "adori",
  "adoro",
  "adoxa",
  "adoxe",
  "adula",
  "aduli",
  "adulo",
  "aduna",
  "aduni",
  "aduno",
  "adusa",
  "aduse",
  "adusi",
  "aduso",
  "aerai",
  "aerea",
  "aeree",
  "aerei",
  "aereo",
  "afaca",
  "afeli",
  "affla",
  "affli",
  "afide",
  "afidi",
  "afnii",
  "afnio",
  "afona",
  "afone",
  "afoni",
  "afono",
  "afosa",
  "afose",
  "afosi",
  "afoso",
  "afoxe",
  "agama",
  "agame",
  "agami",
  "agamo",
  "agape",
  "agapi",
  "agata",
  "agate",
  "agave",
  "agavi",
  "agera",
  "agero",
  "aggia",
  "aggio",
  "agiai",
  "agile",
  "agili",
  "agira",
  "agire",
  "agiro",
  "agita",
  "agite",
  "agiti",
  "agito",
  "agiva",
  "agivi",
  "agivo",
  "aglio",
  "agone",
  "agoni",
  "agora",
  "aguna",
  "aguni",
  "aguno",
  "agura",
  "aguri",
  "aguro",
  "aguti",
  "aguto",
  "ahime",
  "aiola",
  "aiole",
  "aioli",
  "aiolo",
  "aitai",
  "aiuga",
  "aiuta",
  "aiuti",
  "aiuto",
  "aizza",
  "aizzi",
  "aizzo",
  "ajour",
  "alala",
  "alana",
  "alane",
  "alani",
  "alano",
  "alare",
  "alari",
  "alata",
  "alate",
  "alati",
  "alato",
  "alava",
  "alavi",
  "alavo",
  "album",
  "alcea",
  "alcee",
  "alche",
  "alcol",
  "alcun",
  "aldia",
  "aldie",
  "aldio",
  "alena",
  "aleni",
  "alera",
  "alere",
  "alero",
  "alesa",
  "alesi",
  "aleso",
  "alghe",
  "algia",
  "algie",
  "aliai",
  "alias",
  "alibi",
  "alice",
  "alici",
  "alida",
  "alide",
  "alidi",
  "alido",
  "aliga",
  "alimi",
  "alimo",
  "alino",
  "alita",
  "alite",
  "aliti",
  "alito",
  "allea",
  "allei",
  "alleo",
  "almea",
  "almee",
  "alobi",
  "alona",
  "alone",
  "aloni",
  "alono",
  "alosa",
  "alose",
  "alpax",
  "altea",
  "altee",
  "altra",
  "altre",
  "altri",
  "altro",
  "alula",
  "alule",
  "alvei",
  "alveo",
  "alzai",
  "amaca",
  "amano",
  "amara",
  "amare",
  "amari",
  "amaro",
  "amasi",
  "amata",
  "amate",
  "amati",
  "amato",
  "amava",
  "amavi",
  "amavo",
  "ambia",
  "ambii",
  "ambio",
  "ambra",
  "ambre",
  "ameba",
  "amebe",
  "amena",
  "amene",
  "ameni",
  "ameno",
  "amera",
  "amero",
  "amica",
  "amici",
  "amico",
  "amida",
  "amide",
  "amidi",
  "amido",
  "amina",
  "amine",
  "amino",
  "ammai",
  "ammen",
  "amnii",
  "amnio",
  "amomi",
  "amomo",
  "amora",
  "amore",
  "amori",
  "ampex",
  "ampia",
  "ampie",
  "ampio",
  "ampli",
  "amplo",
  "amuli",
  "amulo",
  "anace",
  "anale",
  "anali",
  "anche",
  "ancia",
  "ancoi",
  "andai",
  "andra",
  "andro",
  "anela",
  "anele",
  "aneli",
  "anelo",
  "aneti",
  "aneto",
  "angla",
  "angle",
  "angli",
  "anglo",
  "angue",
  "angui",
  "anice",
  "anici",
  "anile",
  "anili",
  "anima",
  "anime",
  "animi",
  "animo",
  "annoi",
  "annua",
  "annue",
  "annui",
  "annuo",
  "anobi",
  "anodi",
  "anodo",
  "anona",
  "anone",
  "ansai",
  "ansia",
  "ansie",
  "ansio",
  "antri",
  "antro",
  "anura",
  "anure",
  "anuri",
  "anuro",
  "aonia",
  "aonie",
  "aonio",
  "aoppi",
  "aorta",
  "aorte",
  "apale",
  "apali",
  "apiai",
  "apice",
  "apici",
  "apide",
  "apidi",
  "apina",
  "apine",
  "apnea",
  "apnee",
  "apoda",
  "apode",
  "apodi",
  "apodo",
  "appai",
  "appia",
  "appie",
  "appio",
  "aprii",
  "apula",
  "apule",
  "apuli",
  "apulo",
  "aquea",
  "aquee",
  "aquei",
  "aqueo",
  "araba",
  "arabe",
  "arabi",
  "arabo",
  "arano",
  "arare",
  "arata",
  "arate",
  "arati",
  "arato",
  "arava",
  "aravi",
  "aravo",
  "arcai",
  "arche",
  "archi",
  "arcua",
  "arcui",
  "arcuo",
  "ardea",
  "ardee",
  "ardii",
  "ardua",
  "ardue",
  "ardui",
  "arduo",
  "areai",
  "areca",
  "arena",
  "arene",
  "areni",
  "areno",
  "arera",
  "arero",
  "arghi",
  "argon",
  "argot",
  "argui",
  "arida",
  "aride",
  "aridi",
  "arido",
  "arile",
  "arili",
  "arino",
  "armai",
  "arnia",
  "arnie",
  "aroma",
  "aromi",
  "arpia",
  "arpie",
  "arrak",
  "array",
  "artai",
  "asari",
  "asaro",
  "aschi",
  "ascia",
  "ascio",
  "asdic",
  "asili",
  "asilo",
  "asina",
  "asine",
  "asini",
  "asino",
  "asola",
  "asole",
  "asoli",
  "asolo",
  "aspic",
  "aspra",
  "aspre",
  "aspri",
  "aspro",
  "assai",
  "asset",
  "aster",
  "astio",
  "astri",
  "astro",
  "atano",
  "atare",
  "atata",
  "atate",
  "atati",
  "atato",
  "atava",
  "atave",
  "atavi",
  "atavo",
  "atciu",
  "atele",
  "ateli",
  "atera",
  "atero",
  "atino",
  "atomi",
  "atomo",
  "atona",
  "atone",
  "atoni",
  "atono",
  "atout",
  "atrii",
  "atrio",
  "attai",
  "attua",
  "attui",
  "attuo",
  "audio",
  "audit",
  "auffa",
  "auggi",
  "augna",
  "augni",
  "augno",
  "aulos",
  "aunai",
  "aurea",
  "auree",
  "aurei",
  "aureo",
  "aurio",
  "ausai",
  "autos",
  "avana",
  "avara",
  "avare",
  "avari",
  "avaro",
  "avemo",
  "avena",
  "avene",
  "avere",
  "averi",
  "avero",
  "avete",
  "aveva",
  "avevi",
  "avevo",
  "avida",
  "avide",
  "avidi",
  "avido",
  "avita",
  "avite",
  "aviti",
  "avito",
  "avoca",
  "avoco",
  "avola",
  "avole",
  "avoli",
  "avolo",
  "avori",
  "avrai",
  "avrei",
  "avria",
  "avuta",
  "avute",
  "avuti",
  "avuto",
  "avvia",
  "avvii",
  "avvio",
  "axone",
  "axoni",
  "azera",
  "azere",
  "azeri",
  "azero",
  "azima",
  "azime",
  "azimo",
  "azoni",
  "azoti",
  "azoto",
  "azuki",
  "babao",
  "babau",
  "babbi",
  "babbo",
  "bacai",
  "bacca",
  "bacco",
  "bachi",
  "bacia",
  "bacie",
  "bacii",
  "bacio",
  "bacon",
  "badai",
  "badge",
  "badia",
  "badie",
  "baffi",
  "baffo",
  "bagel",
  "bagli",
  "bagna",
  "bagne",
  "bagni",
  "bagno",
  "baiai",
  "baila",
  "baile",
  "baili",
  "bailo",
  "baino",
  "baita",
  "baite",
  "balbo",
  "balco",
  "balda",
  "balde",
  "baldi",
  "baldo",
  "balia",
  "balie",
  "balii",
  "balio",
  "balla",
  "balle",
  "balli",
  "ballo",
  "balma",
  "balme",
  "balsa",
  "balse",
  "balta",
  "balte",
  "balza",
  "balze",
  "balzi",
  "balzo",
  "bamba",
  "bambe",
  "bambi",
  "bambo",
  "bambu",
  "banca",
  "banco",
  "banda",
  "bande",
  "bandi",
  "bando",
  "bangi",
  "banjo",
  "banna",
  "banni",
  "banno",
  "bantu",
  "barai",
  "barba",
  "barbe",
  "barbi",
  "barbo",
  "barca",
  "barco",
  "barda",
  "barde",
  "bardi",
  "bardo",
  "barge",
  "baria",
  "barie",
  "barii",
  "bario",
  "barra",
  "barre",
  "barri",
  "barro",
  "basai",
  "basca",
  "basco",
  "basic",
  "basii",
  "bassa",
  "basse",
  "bassi",
  "basso",
  "basta",
  "baste",
  "basti",
  "basto",
  "batch",
  "batik",
  "batta",
  "batte",
  "batti",
  "batto",
  "baula",
  "baule",
  "bauli",
  "baulo",
  "bauta",
  "bazar",
  "bazza",
  "bazze",
  "beano",
  "beare",
  "beata",
  "beate",
  "beati",
  "beato",
  "beava",
  "beavi",
  "beavo",
  "becca",
  "becco",
  "beche",
  "bechi",
  "beera",
  "beero",
  "beffa",
  "beffe",
  "beffi",
  "beffo",
  "beghe",
  "beghi",
  "begli",
  "begum",
  "beige",
  "beino",
  "beisa",
  "beise",
  "belai",
  "belga",
  "belgi",
  "belii",
  "belio",
  "bella",
  "belle",
  "belli",
  "bello",
  "belta",
  "belva",
  "belve",
  "bembe",
  "benda",
  "bende",
  "bendi",
  "bendo",
  "benna",
  "benne",
  "bensi",
  "beola",
  "beole",
  "beona",
  "beone",
  "beoni",
  "beota",
  "beote",
  "beoti",
  "berci",
  "berga",
  "berma",
  "berme",
  "berra",
  "berro",
  "bersi",
  "berso",
  "berta",
  "berte",
  "beruf",
  "berza",
  "berze",
  "besci",
  "bessa",
  "besse",
  "bessi",
  "besso",
  "betel",
  "beton",
  "betta",
  "bette",
  "beuta",
  "beute",
  "bevei",
  "bevve",
  "bevvi",
  "bezzi",
  "bezzo",
  "biada",
  "biade",
  "biadi",
  "biado",
  "biava",
  "biave",
  "biavi",
  "biavo",
  "bibbi",
  "bicci",
  "biche",
  "bidet",
  "bieca",
  "bieco",
  "bieta",
  "biete",
  "biffa",
  "biffe",
  "biffi",
  "biffo",
  "bighe",
  "bighi",
  "bigia",
  "bigie",
  "bigio",
  "bigiu",
  "bigne",
  "bijou",
  "biker",
  "bilia",
  "bilie",
  "bille",
  "billi",
  "bilta",
  "bimba",
  "bimbe",
  "bimbi",
  "bimbo",
  "binai",
  "binda",
  "binde",
  "bingo",
  "biodi",
  "biodo",
  "bioma",
  "biomi",
  "biova",
  "biove",
  "birba",
  "birbe",
  "birbi",
  "birbo",
  "birce",
  "birci",
  "birra",
  "birre",
  "birri",
  "birro",
  "bisca",
  "bisce",
  "bisci",
  "bisex",
  "bissa",
  "bissi",
  "bisso",
  "bitta",
  "bitte",
  "bitti",
  "bitto",
  "biuta",
  "biute",
  "bivio",
  "bizza",
  "bizze",
  "blase",
  "bleah",
  "blesa",
  "blese",
  "blesi",
  "bleso",
  "blimp",
  "blini",
  "bliny",
  "blitz",
  "block",
  "bloom",
  "blues",
  "bluff",
  "blumi",
  "blumo",
  "blusa",
  "bluse",
  "boara",
  "board",
  "boare",
  "boari",
  "boaro",
  "boati",
  "boato",
  "bobba",
  "bobbe",
  "bocca",
  "bocce",
  "bocci",
  "boche",
  "bocia",
  "bocio",
  "bodda",
  "bodde",
  "boema",
  "boeme",
  "boemi",
  "boemo",
  "boera",
  "boere",
  "boeri",
  "boero",
  "boghe",
  "bogli",
  "bohri",
  "boide",
  "boidi",
  "boina",
  "boite",
  "boldi",
  "boldo",
  "bolge",
  "bolla",
  "bolle",
  "bolli",
  "bollo",
  "bolsa",
  "bolse",
  "bolsi",
  "bolso",
  "bomba",
  "bombe",
  "bombi",
  "bombo",
  "bomii",
  "bonci",
  "bonet",
  "bongo",
  "bonne",
  "bonta",
  "bonus",
  "bonza",
  "bonze",
  "bonzi",
  "bonzo",
  "borda",
  "borde",
  "bordi",
  "bordo",
  "borea",
  "borei",
  "borgo",
  "boria",
  "borie",
  "borii",
  "borio",
  "borni",
  "borra",
  "borre",
  "borri",
  "borro",
  "borsa",
  "borsc",
  "borse",
  "bosco",
  "bossi",
  "bosso",
  "botai",
  "botri",
  "botro",
  "botta",
  "botte",
  "botti",
  "botto",
  "boule",
  "boxai",
  "boxer",
  "bozza",
  "bozze",
  "bozzi",
  "bozzo",
  "braca",
  "brace",
  "braci",
  "braco",
  "brada",
  "brade",
  "bradi",
  "brado",
  "braga",
  "brage",
  "brago",
  "braii",
  "brama",
  "brame",
  "brami",
  "bramo",
  "brand",
  "brani",
  "brano",
  "brasa",
  "brasi",
  "braso",
  "brava",
  "brave",
  "bravi",
  "bravo",
  "break",
  "brent",
  "breva",
  "breve",
  "brevi",
  "brica",
  "brice",
  "brida",
  "bride",
  "brief",
  "briga",
  "brigo",
  "brina",
  "brine",
  "brini",
  "brino",
  "broda",
  "brode",
  "brodi",
  "brodo",
  "broli",
  "brolo",
  "bromi",
  "bromo",
  "bruca",
  "bruce",
  "bruci",
  "bruco",
  "brugo",
  "bruii",
  "brule",
  "bruma",
  "brume",
  "bruna",
  "brune",
  "bruni",
  "bruno",
  "brusi",
  "bruta",
  "brute",
  "bruti",
  "bruto",
  "buana",
  "bucai",
  "bucce",
  "bucci",
  "buche",
  "buchi",
  "bucio",
  "budda",
  "buddi",
  "bufai",
  "buffa",
  "buffe",
  "buffi",
  "buffo",
  "bugia",
  "bugie",
  "bugio",
  "bugli",
  "bugna",
  "bugne",
  "bugni",
  "bugno",
  "buina",
  "buine",
  "bulbi",
  "bulbo",
  "bulge",
  "bulla",
  "bulle",
  "bulli",
  "bullo",
  "bunet",
  "buona",
  "buone",
  "buoni",
  "buono",
  "burba",
  "burbe",
  "burga",
  "burka",
  "burla",
  "burle",
  "burli",
  "burlo",
  "burqa",
  "burri",
  "burro",
  "burst",
  "busca",
  "busco",
  "bussa",
  "busse",
  "bussi",
  "busso",
  "busta",
  "buste",
  "busti",
  "busto",
  "butta",
  "butte",
  "butti",
  "butto",
  "buyer",
  "buzza",
  "buzze",
  "buzzi",
  "buzzo",
  "bwana",
  "caban",
  "cabla",
  "cable",
  "cabli",
  "cablo",
  "cabra",
  "cabri",
  "cabro",
  "cacai",
  "cacao",
  "cacca",
  "cacce",
  "cacci",
  "cache",
  "cachi",
  "cacio",
  "cacti",
  "cacto",
  "cadde",
  "caddi",
  "cadmi",
  "cadra",
  "cadro",
  "caffa",
  "caffe",
  "caffi",
  "caffo",
  "cafra",
  "cafre",
  "cafri",
  "cafro",
  "cagai",
  "caghi",
  "cagiu",
  "cagli",
  "cagna",
  "cagne",
  "caiac",
  "caiba",
  "caibe",
  "caini",
  "caino",
  "cairn",
  "cajun",
  "calai",
  "calao",
  "calca",
  "calce",
  "calci",
  "calco",
  "calda",
  "calde",
  "caldi",
  "caldo",
  "calen",
  "calia",
  "calie",
  "calla",
  "calle",
  "calli",
  "callo",
  "calma",
  "calme",
  "calmi",
  "calmo",
  "calse",
  "calta",
  "calte",
  "calva",
  "calve",
  "calvi",
  "calvo",
  "calza",
  "calze",
  "calzi",
  "calzo",
  "cambi",
  "cameo",
  "camma",
  "camme",
  "campa",
  "campi",
  "campo",
  "canai",
  "candi",
  "canea",
  "canee",
  "canga",
  "cange",
  "cangi",
  "canna",
  "canne",
  "canni",
  "canno",
  "canoa",
  "canoe",
  "canon",
  "cansa",
  "cansi",
  "canso",
  "canta",
  "cante",
  "canti",
  "canto",
  "capai",
  "capii",
  "capoc",
  "capok",
  "cappa",
  "cappe",
  "cappi",
  "capra",
  "capre",
  "capri",
  "capro",
  "capta",
  "capti",
  "capto",
  "caput",
  "carca",
  "carco",
  "carda",
  "carde",
  "cardi",
  "cardo",
  "cargo",
  "caria",
  "carie",
  "cario",
  "carme",
  "carmi",
  "carne",
  "carni",
  "carol",
  "caron",
  "carpa",
  "carpe",
  "carpi",
  "carpo",
  "carre",
  "carri",
  "carro",
  "carta",
  "carte",
  "carvi",
  "casba",
  "casbe",
  "casca",
  "casci",
  "casco",
  "casei",
  "caseo",
  "caspa",
  "caspe",
  "cassa",
  "casse",
  "cassi",
  "casso",
  "casta",
  "caste",
  "casti",
  "casto",
  "catch",
  "catta",
  "catte",
  "catti",
  "catto",
  "caule",
  "cauli",
  "caulo",
  "cauri",
  "cauro",
  "causa",
  "cause",
  "causi",
  "causo",
  "cauta",
  "caute",
  "cauti",
  "cauto",
  "cavai",
  "cavea",
  "cavee",
  "cavia",
  "cavie",
  "cayak",
  "cazza",
  "cazze",
  "cazzi",
  "cazzo",
  "cecai",
  "cecca",
  "ceche",
  "cechi",
  "cecio",
  "cedei",
  "cedra",
  "cedri",
  "cedro",
  "cedua",
  "cedue",
  "cedui",
  "ceduo",
  "ceffi",
  "ceffo",
  "ceiba",
  "ceibe",
  "celai",
  "celia",
  "celie",
  "celio",
  "cella",
  "celle",
  "celli",
  "cello",
  "celta",
  "celte",
  "celti",
  "celzi",
  "cenai",
  "cenci",
  "cenge",
  "cengi",
  "cenna",
  "cenni",
  "cenno",
  "censi",
  "censo",
  "centa",
  "cente",
  "cento",
  "ceppa",
  "ceppe",
  "ceppi",
  "ceppo",
  "cerai",
  "cerbi",
  "cerca",
  "cerci",
  "cerco",
  "cerea",
  "ceree",
  "cerei",
  "cereo",
  "cerii",
  "cerio",
  "cerna",
  "cerne",
  "cerni",
  "cerno",
  "cerri",
  "cerro",
  "certa",
  "certe",
  "certi",
  "certo",
  "cerva",
  "cerve",
  "cervi",
  "cervo",
  "cesia",
  "cesie",
  "cesii",
  "cesio",
  "cespi",
  "cespo",
  "cessa",
  "cesse",
  "cessi",
  "cesso",
  "cesta",
  "ceste",
  "cesti",
  "cesto",
  "cetra",
  "cetre",
  "charm",
  "cheap",
  "check",
  "chela",
  "chele",
  "cheli",
  "chepi",
  "cheta",
  "chete",
  "cheti",
  "cheto",
  "chili",
  "chilo",
  "chimi",
  "chimo",
  "china",
  "chine",
  "chini",
  "chino",
  "chips",
  "chola",
  "cholo",
  "chope",
  "ciaba",
  "ciack",
  "ciana",
  "ciane",
  "ciani",
  "ciano",
  "ciati",
  "ciato",
  "ciazi",
  "cibai",
  "cicca",
  "cicce",
  "cicci",
  "cicco",
  "ciche",
  "cicli",
  "ciclo",
  "cieca",
  "cieco",
  "cieli",
  "cielo",
  "ciera",
  "ciere",
  "ciesa",
  "ciese",
  "cifra",
  "cifre",
  "cifri",
  "cifro",
  "cigli",
  "cigna",
  "cigne",
  "cigni",
  "cigno",
  "cimai",
  "cimba",
  "cimbe",
  "cince",
  "cinga",
  "cinge",
  "cingi",
  "cingo",
  "cinna",
  "cinne",
  "cinse",
  "cinsi",
  "cinta",
  "cinte",
  "cinti",
  "cinto",
  "cintz",
  "cinzi",
  "cioce",
  "ciofa",
  "ciofe",
  "ciofi",
  "ciofo",
  "cippi",
  "cippo",
  "cipri",
  "circa",
  "circe",
  "circi",
  "circo",
  "cirri",
  "cirro",
  "cisio",
  "cispa",
  "cispe",
  "cispi",
  "cispo",
  "cista",
  "ciste",
  "cisti",
  "cisto",
  "citai",
  "citra",
  "citta",
  "citte",
  "citti",
  "citto",
  "ciuca",
  "ciuco",
  "ciuff",
  "ciula",
  "ciuli",
  "ciulo",
  "ciunf",
  "civet",
  "civii",
  "clade",
  "cladi",
  "clama",
  "clami",
  "clamo",
  "clang",
  "clari",
  "claro",
  "clava",
  "clave",
  "clavi",
  "clavo",
  "cleri",
  "clero",
  "click",
  "clima",
  "climi",
  "cline",
  "clini",
  "clivi",
  "clivo",
  "cloch",
  "clock",
  "clona",
  "clone",
  "cloni",
  "clono",
  "clori",
  "cloro",
  "clown",
  "cloze",
  "cnidi",
  "coach",
  "coala",
  "coana",
  "coane",
  "cobas",
  "cobea",
  "cobee",
  "cobla",
  "coble",
  "cobol",
  "cobra",
  "cocca",
  "cocce",
  "cocci",
  "cocco",
  "coche",
  "cochi",
  "codia",
  "codio",
  "coesa",
  "coese",
  "coesi",
  "coeso",
  "coeva",
  "coeve",
  "coevi",
  "coevo",
  "coffa",
  "coffe",
  "cofta",
  "cofte",
  "cofti",
  "cofto",
  "cogli",
  "cogna",
  "cogni",
  "cogno",
  "coiai",
  "coine",
  "coira",
  "coire",
  "coiro",
  "coita",
  "coite",
  "coiti",
  "coito",
  "coiva",
  "coivi",
  "coivo",
  "colai",
  "colca",
  "colco",
  "colei",
  "colga",
  "colgo",
  "colia",
  "colie",
  "colii",
  "colio",
  "colla",
  "colle",
  "colli",
  "collo",
  "colma",
  "colme",
  "colmi",
  "colmo",
  "colon",
  "colpa",
  "colpe",
  "colpi",
  "colpo",
  "colse",
  "colsi",
  "colta",
  "colte",
  "colti",
  "colto",
  "colui",
  "colza",
  "colze",
  "colzi",
  "comba",
  "combe",
  "combo",
  "comic",
  "comma",
  "commi",
  "commo",
  "compi",
  "conca",
  "conce",
  "conci",
  "condi",
  "confa",
  "conga",
  "congi",
  "conia",
  "conie",
  "conii",
  "conio",
  "conni",
  "conno",
  "conta",
  "conte",
  "conti",
  "conto",
  "cooba",
  "coobi",
  "coobo",
  "copia",
  "copie",
  "copio",
  "coppa",
  "coppe",
  "coppi",
  "coppo",
  "copra",
  "copre",
  "copri",
  "copro",
  "copta",
  "copte",
  "copti",
  "copto",
  "coque",
  "corba",
  "corbe",
  "corbi",
  "corbo",
  "corca",
  "corco",
  "corda",
  "corde",
  "cordi",
  "cordo",
  "corea",
  "coree",
  "corei",
  "coreo",
  "corio",
  "cormi",
  "cormo",
  "corna",
  "corne",
  "corni",
  "corno",
  "corpi",
  "corpo",
  "corra",
  "corre",
  "corri",
  "corro",
  "corsa",
  "corse",
  "corsi",
  "corso",
  "corta",
  "corte",
  "corti",
  "corto",
  "corve",
  "corvi",
  "corvo",
  "cosai",
  "cosca",
  "cosce",
  "cosci",
  "cosfi",
  "cosmi",
  "cosmo",
  "cosse",
  "cossi",
  "cosso",
  "costa",
  "coste",
  "costi",
  "costo",
  "cotta",
  "cotte",
  "cotti",
  "cotto",
  "coupe",
  "cousi",
  "couso",
  "covai",
  "cover",
  "covra",
  "covre",
  "covri",
  "covro",
  "cozza",
  "cozze",
  "cozzi",
  "cozzo",
  "crace",
  "craci",
  "crack",
  "crani",
  "crapa",
  "crape",
  "crash",
  "crasi",
  "crawl",
  "creai",
  "creda",
  "crede",
  "credi",
  "credo",
  "crema",
  "creme",
  "cremi",
  "cremo",
  "crena",
  "crene",
  "crepa",
  "crepe",
  "crepi",
  "crepo",
  "cresi",
  "creso",
  "crest",
  "creta",
  "crete",
  "crich",
  "crime",
  "crina",
  "crine",
  "crini",
  "crino",
  "crisi",
  "croce",
  "croci",
  "croco",
  "croda",
  "crode",
  "croio",
  "croma",
  "crome",
  "cromi",
  "cromo",
  "crono",
  "cross",
  "crown",
  "cruci",
  "cruda",
  "crude",
  "crudi",
  "crudo",
  "cruna",
  "crune",
  "ctoni",
  "cubai",
  "cubia",
  "cubie",
  "cucca",
  "cucce",
  "cucci",
  "cucco",
  "cuccu",
  "cucia",
  "cucii",
  "cucio",
  "cugna",
  "cugne",
  "cugni",
  "cugno",
  "culai",
  "culla",
  "culle",
  "culli",
  "cullo",
  "culmi",
  "culmo",
  "culti",
  "culto",
  "cunei",
  "cuneo",
  "cunta",
  "cunte",
  "cuoca",
  "cuoce",
  "cuoci",
  "cuoco",
  "cuoia",
  "cuoio",
  "cuora",
  "cuore",
  "cuori",
  "curai",
  "curda",
  "curde",
  "curdi",
  "curdo",
  "curia",
  "curie",
  "curii",
  "curio",
  "curro",
  "curry",
  "curto",
  "curva",
  "curve",
  "curvi",
  "curvo",
  "cusco",
  "cussi",
  "cusso",
  "cuvee",
  "cycas",
  "czeca",
  "czeco",
  "dacci",
  "dache",
  "dacia",
  "dacie",
  "daere",
  "dafne",
  "dafni",
  "daghe",
  "dagli",
  "daini",
  "daino",
  "dalia",
  "dalie",
  "dalla",
  "dalle",
  "dalli",
  "dallo",
  "damai",
  "damma",
  "damme",
  "danai",
  "danda",
  "dande",
  "dando",
  "dandy",
  "danna",
  "danni",
  "danno",
  "dante",
  "danti",
  "danza",
  "danze",
  "danzi",
  "danzo",
  "darai",
  "dardi",
  "dardo",
  "darei",
  "darsi",
  "darts",
  "datai",
  "dauni",
  "dazia",
  "dazio",
  "debba",
  "debbe",
  "debbi",
  "debbo",
  "debug",
  "decco",
  "decor",
  "degge",
  "deggi",
  "degli",
  "degna",
  "degne",
  "degni",
  "degno",
  "deita",
  "delay",
  "delei",
  "delia",
  "delie",
  "delio",
  "della",
  "delle",
  "dello",
  "delta",
  "demmo",
  "denai",
  "denim",
  "densa",
  "dense",
  "densi",
  "denso",
  "denta",
  "dente",
  "denti",
  "dento",
  "derby",
  "derma",
  "dermi",
  "derno",
  "desco",
  "desia",
  "desie",
  "desii",
  "desio",
  "dessa",
  "desse",
  "dessi",
  "desso",
  "desta",
  "deste",
  "desti",
  "desto",
  "destr",
  "detta",
  "dette",
  "detti",
  "detto",
  "deuce",
  "devia",
  "devie",
  "devii",
  "devio",
  "devon",
  "diace",
  "diaci",
  "diade",
  "diadi",
  "diale",
  "diali",
  "diamo",
  "diana",
  "diane",
  "diano",
  "diapo",
  "diari",
  "diate",
  "dicai",
  "dicco",
  "dichi",
  "diece",
  "dieci",
  "diede",
  "diedi",
  "diene",
  "dieni",
  "diesa",
  "diesi",
  "dieso",
  "dieta",
  "diete",
  "dieti",
  "dieto",
  "digei",
  "dighe",
  "digna",
  "digni",
  "digno",
  "dildi",
  "dildo",
  "dilui",
  "dimoi",
  "dinar",
  "dindi",
  "dindo",
  "dingo",
  "diodi",
  "diodo",
  "dioli",
  "diolo",
  "dipoi",
  "dirai",
  "direi",
  "dirsi",
  "disco",
  "disda",
  "disdo",
  "disfa",
  "disfi",
  "disfo",
  "disia",
  "disie",
  "disii",
  "disio",
  "disse",
  "dissi",
  "dista",
  "disti",
  "disto",
  "ditta",
  "ditte",
  "ditti",
  "ditto",
  "divia",
  "divii",
  "divio",
  "dixit",
  "doagi",
  "doana",
  "doane",
  "doari",
  "dobla",
  "doble",
  "dobli",
  "doblo",
  "docce",
  "docci",
  "docet",
  "docmi",
  "dogai",
  "doghe",
  "doghi",
  "dogio",
  "dogli",
  "dogma",
  "dogmi",
  "dogre",
  "dogri",
  "dolai",
  "dolby",
  "dolca",
  "dolce",
  "dolci",
  "dolco",
  "dolga",
  "dolgo",
  "dolia",
  "dolie",
  "dolii",
  "dolio",
  "dolly",
  "dolse",
  "dolsi",
  "dolta",
  "dolte",
  "dolti",
  "dolto",
  "dolze",
  "dolzi",
  "domai",
  "domma",
  "dommi",
  "domus",
  "donai",
  "donde",
  "donna",
  "donne",
  "donni",
  "donno",
  "dopai",
  "dopia",
  "dopio",
  "dopla",
  "dople",
  "dopli",
  "doplo",
  "doppi",
  "doppo",
  "dorai",
  "doree",
  "dorii",
  "dorio",
  "dorma",
  "dorme",
  "dormi",
  "dormo",
  "dorra",
  "dorro",
  "dorsi",
  "dorso",
  "dosai",
  "dossi",
  "dosso",
  "dotai",
  "dotta",
  "dotte",
  "dotti",
  "dotto",
  "dovea",
  "dovei",
  "dovra",
  "dovro",
  "draba",
  "drabe",
  "draco",
  "draga",
  "drago",
  "drama",
  "drami",
  "drena",
  "dreni",
  "dreno",
  "dreto",
  "drink",
  "drive",
  "droga",
  "drogo",
  "droma",
  "drome",
  "dromi",
  "dromo",
  "drone",
  "droni",
  "druda",
  "drude",
  "drudi",
  "drudo",
  "drupa",
  "drupe",
  "drusa",
  "druse",
  "drusi",
  "druso",
  "duagi",
  "duale",
  "duali",
  "dubat",
  "dubbi",
  "dubia",
  "dubio",
  "dubni",
  "ducai",
  "ducea",
  "ducee",
  "duchi",
  "dugli",
  "duina",
  "duine",
  "duini",
  "duino",
  "dulia",
  "dulie",
  "duole",
  "duoli",
  "duolo",
  "duomi",
  "duomo",
  "dupla",
  "duple",
  "dupli",
  "duplo",
  "durai",
  "durra",
  "durre",
  "dutti",
  "dutto",
  "duvet",
  "ebani",
  "ebano",
  "ebbio",
  "ebbra",
  "ebbre",
  "ebbri",
  "ebbro",
  "ebeni",
  "ebeno",
  "ebere",
  "ebete",
  "ebeti",
  "ebola",
  "ebrea",
  "ebree",
  "ebrei",
  "ebreo",
  "ebria",
  "ebrie",
  "ebrio",
  "ebuli",
  "ebulo",
  "ecciu",
  "echei",
  "echeo",
  "echii",
  "echio",
  "ecidi",
  "edace",
  "edaci",
  "eddai",
  "edema",
  "edemi",
  "edera",
  "edere",
  "edile",
  "edili",
  "edima",
  "edime",
  "edipo",
  "edita",
  "edite",
  "editi",
  "edito",
  "educa",
  "educo",
  "edule",
  "eduli",
  "efebi",
  "efebo",
  "efesi",
  "efori",
  "eforo",
  "egeno",
  "egida",
  "egide",
  "egira",
  "egizi",
  "eguai",
  "ehila",
  "ehime",
  "elafe",
  "elafi",
  "elata",
  "elate",
  "elati",
  "elato",
  "elego",
  "eleni",
  "eleva",
  "elevi",
  "elevo",
  "elica",
  "elice",
  "elici",
  "elida",
  "elide",
  "elidi",
  "elido",
  "eliea",
  "eliee",
  "elisa",
  "elise",
  "elisi",
  "eliso",
  "elite",
  "elogi",
  "elson",
  "eluda",
  "elude",
  "eludi",
  "eludo",
  "eluii",
  "elusa",
  "eluse",
  "elusi",
  "eluso",
  "eluvi",
  "emaci",
  "emale",
  "emali",
  "emana",
  "emani",
  "emano",
  "emeri",
  "emero",
  "emica",
  "emici",
  "emico",
  "emide",
  "emidi",
  "emina",
  "emine",
  "emiri",
  "emiro",
  "emise",
  "emisi",
  "emolo",
  "emoni",
  "empia",
  "empie",
  "empii",
  "empio",
  "emula",
  "emule",
  "emuli",
  "emulo",
  "enfia",
  "enfie",
  "enfio",
  "enoli",
  "enolo",
  "enter",
  "entra",
  "entri",
  "entro",
  "enula",
  "enule",
  "envea",
  "envee",
  "eolia",
  "eolie",
  "eolio",
  "epate",
  "epica",
  "epici",
  "epico",
  "epoca",
  "epodi",
  "epodo",
  "epura",
  "epuri",
  "epuro",
  "equai",
  "eramo",
  "erano",
  "erari",
  "erasa",
  "erase",
  "erasi",
  "eraso",
  "erbai",
  "erbii",
  "erbio",
  "erebi",
  "erebo",
  "ereda",
  "erede",
  "eredi",
  "eredo",
  "erema",
  "ereme",
  "eremi",
  "eremo",
  "ereta",
  "ereti",
  "ereto",
  "ergal",
  "erica",
  "eriga",
  "erige",
  "erigi",
  "erigo",
  "erile",
  "ernia",
  "ernie",
  "eroda",
  "erode",
  "erodi",
  "erodo",
  "eroga",
  "erogo",
  "erosa",
  "erose",
  "erosi",
  "eroso",
  "errai",
  "eruca",
  "erudi",
  "esala",
  "esali",
  "esalo",
  "esame",
  "esami",
  "esani",
  "esano",
  "esche",
  "eschi",
  "esibi",
  "esiga",
  "esige",
  "esigi",
  "esigo",
  "esile",
  "esili",
  "esima",
  "esime",
  "esimi",
  "esimo",
  "esipi",
  "esipo",
  "esita",
  "esiti",
  "esito",
  "esizi",
  "esodi",
  "esodo",
  "esone",
  "esoni",
  "esora",
  "esori",
  "esoro",
  "esosa",
  "esose",
  "esosi",
  "esoso",
  "espia",
  "espii",
  "espio",
  "espri",
  "espro",
  "essai",
  "estri",
  "estro",
  "esula",
  "esule",
  "esuli",
  "esulo",
  "esuma",
  "esumi",
  "esumo",
  "etade",
  "etani",
  "etano",
  "etate",
  "etciu",
  "etene",
  "eteni",
  "etera",
  "etere",
  "eteri",
  "etero",
  "etesi",
  "ethos",
  "etica",
  "etici",
  "etico",
  "etile",
  "etili",
  "etimi",
  "etimo",
  "etini",
  "etino",
  "etnea",
  "etnee",
  "etnei",
  "etneo",
  "etnia",
  "etnie",
  "evada",
  "evade",
  "evadi",
  "evado",
  "evasa",
  "evase",
  "evasi",
  "evaso",
  "evira",
  "eviri",
  "eviro",
  "evita",
  "eviti",
  "evito",
  "evoca",
  "evoco",
  "evvai",
  "evvia",
  "extra",
  "fabra",
  "fabre",
  "fabri",
  "fabro",
  "facce",
  "facci",
  "facia",
  "facon",
  "fader",
  "faggi",
  "fagli",
  "faida",
  "faide",
  "faina",
  "faine",
  "falba",
  "falbe",
  "falbi",
  "falbo",
  "falca",
  "falce",
  "falci",
  "falco",
  "falda",
  "falde",
  "faldi",
  "faldo",
  "falla",
  "falle",
  "falli",
  "fallo",
  "falsa",
  "false",
  "falsi",
  "falso",
  "famai",
  "fanga",
  "fango",
  "fanno",
  "fante",
  "fanti",
  "farad",
  "farai",
  "farce",
  "farci",
  "farda",
  "fardi",
  "fardo",
  "farea",
  "faree",
  "farei",
  "faria",
  "farri",
  "farro",
  "farsa",
  "farse",
  "farsi",
  "fasce",
  "fasci",
  "fasta",
  "faste",
  "fasti",
  "fasto",
  "fatai",
  "fatta",
  "fatte",
  "fatti",
  "fatto",
  "fatua",
  "fatue",
  "fatui",
  "fatuo",
  "fatwa",
  "fauce",
  "fauci",
  "fault",
  "fauna",
  "faune",
  "fauni",
  "fauno",
  "fauve",
  "favai",
  "faxai",
  "fazza",
  "feace",
  "feaci",
  "feano",
  "febea",
  "febee",
  "febei",
  "febeo",
  "febre",
  "fecce",
  "fedai",
  "fedii",
  "felce",
  "felci",
  "fella",
  "felle",
  "felli",
  "fello",
  "felpa",
  "felpe",
  "felpi",
  "felpo",
  "felse",
  "felsi",
  "felze",
  "felzi",
  "femmo",
  "fence",
  "fenda",
  "fende",
  "fendi",
  "fendo",
  "fenno",
  "feria",
  "ferie",
  "ferii",
  "ferla",
  "ferle",
  "ferma",
  "ferme",
  "fermi",
  "fermo",
  "ferne",
  "ferra",
  "ferri",
  "ferro",
  "fersa",
  "ferse",
  "ferva",
  "ferve",
  "fervi",
  "fervo",
  "ferza",
  "ferze",
  "ferzi",
  "ferzo",
  "fessa",
  "fesse",
  "fessi",
  "fesso",
  "festa",
  "feste",
  "festi",
  "festo",
  "fetch",
  "fetei",
  "fetta",
  "fette",
  "feudi",
  "feudo",
  "fiaba",
  "fiabe",
  "fiala",
  "fiale",
  "fiani",
  "fiano",
  "fiata",
  "fiati",
  "fiato",
  "fibra",
  "fibre",
  "ficai",
  "ficca",
  "ficco",
  "fiche",
  "fichi",
  "fichu",
  "ficus",
  "fidai",
  "fiele",
  "fieli",
  "fieni",
  "fieno",
  "fiera",
  "fiere",
  "fieri",
  "fiero",
  "figga",
  "figge",
  "figgi",
  "figgo",
  "fighe",
  "fighi",
  "figli",
  "filai",
  "filet",
  "filia",
  "filie",
  "filio",
  "filli",
  "filma",
  "filme",
  "filmi",
  "filmo",
  "filza",
  "filze",
  "finca",
  "finga",
  "finge",
  "fingi",
  "fingo",
  "finii",
  "finis",
  "finse",
  "finsi",
  "finta",
  "finte",
  "finti",
  "finto",
  "fioca",
  "fioco",
  "fiore",
  "fiori",
  "firma",
  "firme",
  "firmi",
  "firmo",
  "fisco",
  "fissa",
  "fisse",
  "fissi",
  "fisso",
  "fisti",
  "fitta",
  "fitte",
  "fitti",
  "fitto",
  "fiume",
  "fiumi",
  "fiuta",
  "fiuti",
  "fiuto",
  "fiuuu",
  "flana",
  "flani",
  "flano",
  "flash",
  "flati",
  "flato",
  "flava",
  "flave",
  "flavi",
  "flavo",
  "flebo",
  "fleti",
  "fleto",
  "flint",
  "flirt",
  "float",
  "flock",
  "flora",
  "flore",
  "flori",
  "fluff",
  "fluii",
  "flute",
  "fluvi",
  "flyby",
  "fobia",
  "fobie",
  "foche",
  "focus",
  "fodri",
  "fodro",
  "foehn",
  "foffa",
  "foffe",
  "fogge",
  "foggi",
  "foghe",
  "fogli",
  "fogna",
  "fogne",
  "fogni",
  "fogno",
  "foiba",
  "foibe",
  "folce",
  "folci",
  "folco",
  "folla",
  "folle",
  "folli",
  "follo",
  "folse",
  "folta",
  "folte",
  "folti",
  "folto",
  "fonai",
  "fonce",
  "fonda",
  "fonde",
  "fondi",
  "fondo",
  "fonia",
  "fonie",
  "fonte",
  "fonti",
  "forai",
  "forbi",
  "forca",
  "force",
  "forci",
  "forfa",
  "forfe",
  "forfi",
  "forfo",
  "forge",
  "forgi",
  "forma",
  "forme",
  "formi",
  "formo",
  "forni",
  "forno",
  "forra",
  "forre",
  "forse",
  "forte",
  "forti",
  "forum",
  "forza",
  "forze",
  "forzi",
  "forzo",
  "fosca",
  "fosco",
  "fossa",
  "fosse",
  "fossi",
  "fosso",
  "foste",
  "fosti",
  "fotta",
  "fotte",
  "fotti",
  "fotto",
  "foule",
  "fovea",
  "fovee",
  "foyer",
  "fraga",
  "frale",
  "frali",
  "frame",
  "frana",
  "frane",
  "frani",
  "frano",
  "frase",
  "frasi",
  "frate",
  "frati",
  "frazi",
  "freak",
  "frega",
  "fregi",
  "frego",
  "frema",
  "freme",
  "fremi",
  "fremo",
  "frena",
  "freni",
  "freno",
  "freon",
  "fresa",
  "frese",
  "fresi",
  "freso",
  "frigi",
  "frigo",
  "frine",
  "frini",
  "frisa",
  "frise",
  "frisi",
  "friso",
  "froce",
  "froci",
  "froda",
  "frode",
  "frodi",
  "frodo",
  "froge",
  "front",
  "fruca",
  "fruco",
  "fruga",
  "frugo",
  "fruii",
  "fruit",
  "fuchi",
  "fuero",
  "fuffa",
  "fuffe",
  "fugai",
  "fugga",
  "fugge",
  "fuggi",
  "fuggo",
  "fughe",
  "fughi",
  "fuina",
  "fulga",
  "fulge",
  "fulgi",
  "fulgo",
  "fulse",
  "fulsi",
  "fulva",
  "fulve",
  "fulvi",
  "fulvo",
  "fumai",
  "fumea",
  "fumee",
  "fummo",
  "funai",
  "funda",
  "fundo",
  "funga",
  "funge",
  "fungi",
  "fungo",
  "funky",
  "funse",
  "funsi",
  "funta",
  "funte",
  "funti",
  "funto",
  "fuoco",
  "fuora",
  "fuore",
  "fuori",
  "fuoro",
  "furai",
  "furba",
  "furbe",
  "furbi",
  "furbo",
  "furfa",
  "furfi",
  "furfo",
  "furia",
  "furie",
  "furio",
  "furno",
  "furti",
  "furto",
  "fusai",
  "fusca",
  "fusco",
  "fussi",
  "fusta",
  "fuste",
  "fusti",
  "fusto",
  "futon",
  "gabba",
  "gabbi",
  "gabbo",
  "gabia",
  "gabie",
  "gaffa",
  "gaffe",
  "gaggi",
  "gagia",
  "gagio",
  "gagni",
  "gagno",
  "galea",
  "galee",
  "galei",
  "galeo",
  "galla",
  "galle",
  "galli",
  "gallo",
  "galop",
  "gamba",
  "gambe",
  "gambi",
  "gambo",
  "gamia",
  "gamie",
  "gamma",
  "gamme",
  "ganci",
  "ganda",
  "gande",
  "ganga",
  "ganni",
  "ganza",
  "ganze",
  "ganzi",
  "ganzo",
  "garba",
  "garbi",
  "garbo",
  "garga",
  "gargo",
  "garni",
  "garri",
  "garza",
  "garze",
  "garzi",
  "garzo",
  "gasai",
  "gasco",
  "gassa",
  "gasse",
  "gassi",
  "gasso",
  "gatta",
  "gatte",
  "gatti",
  "gatto",
  "gauda",
  "gaude",
  "gaudi",
  "gauss",
  "gazai",
  "gazza",
  "gazze",
  "gazzi",
  "gazzo",
  "gebel",
  "gechi",
  "gelai",
  "gelsa",
  "gelse",
  "gelsi",
  "gelso",
  "gemei",
  "gemma",
  "gemme",
  "gemmi",
  "gemmo",
  "genai",
  "genco",
  "genge",
  "genia",
  "genie",
  "genii",
  "genio",
  "genoa",
  "gente",
  "genti",
  "genue",
  "geode",
  "geodi",
  "gerba",
  "gerbe",
  "gerbi",
  "gerbo",
  "gergo",
  "gerla",
  "gerle",
  "gerli",
  "gerlo",
  "germa",
  "germe",
  "germi",
  "gerna",
  "gerne",
  "gersa",
  "gerse",
  "gessa",
  "gessi",
  "gesso",
  "gesta",
  "geste",
  "gesti",
  "gesto",
  "getta",
  "getti",
  "getto",
  "ghebi",
  "ghega",
  "ghego",
  "ghieu",
  "ghiri",
  "ghiro",
  "ghisa",
  "ghise",
  "giace",
  "giaci",
  "giaco",
  "giada",
  "giade",
  "giamo",
  "giani",
  "giano",
  "giara",
  "giare",
  "giava",
  "giave",
  "giazz",
  "gibba",
  "gibbe",
  "gibbi",
  "gibbo",
  "gibus",
  "giela",
  "gieli",
  "gielo",
  "gighe",
  "gigli",
  "gihad",
  "gilda",
  "gilde",
  "gilet",
  "gimmo",
  "ginna",
  "ginni",
  "ginno",
  "gioca",
  "gioco",
  "giogo",
  "gioia",
  "gioie",
  "gioii",
  "gioio",
  "giova",
  "giove",
  "giovi",
  "giovo",
  "girai",
  "girei",
  "girii",
  "girio",
  "girli",
  "girlo",
  "gisse",
  "gissi",
  "giste",
  "gisti",
  "gitta",
  "gitti",
  "gitto",
  "giuba",
  "giube",
  "giuca",
  "giuco",
  "giuda",
  "giudo",
  "giuli",
  "giura",
  "giure",
  "giuri",
  "giuro",
  "giuso",
  "glace",
  "gladi",
  "gleba",
  "glebe",
  "glena",
  "glene",
  "gleni",
  "glifi",
  "glifo",
  "globi",
  "globo",
  "glomi",
  "glomo",
  "glori",
  "glosa",
  "glose",
  "glosi",
  "gloso",
  "gloss",
  "gluma",
  "glume",
  "gnais",
  "gneis",
  "gnome",
  "gnomi",
  "gnomo",
  "gnora",
  "gnore",
  "gnori",
  "gnoro",
  "gnosi",
  "gnuda",
  "gnude",
  "gnudi",
  "gnudo",
  "gobba",
  "gobbe",
  "gobbi",
  "gobbo",
  "gocce",
  "gocci",
  "godei",
  "godet",
  "godii",
  "godio",
  "godra",
  "godro",
  "goffa",
  "goffe",
  "goffi",
  "goffo",
  "gogna",
  "gogne",
  "golem",
  "golfi",
  "golfo",
  "golpe",
  "golpi",
  "gombo",
  "gomii",
  "gomma",
  "gomme",
  "gommi",
  "gommo",
  "gonfi",
  "gonna",
  "gonne",
  "gonza",
  "gonze",
  "gonzi",
  "gonzo",
  "gordi",
  "gorga",
  "gorge",
  "gorgo",
  "gotha",
  "gotta",
  "gotte",
  "gotti",
  "gotto",
  "gouda",
  "gozzi",
  "gozzo",
  "grada",
  "grade",
  "gradi",
  "grado",
  "grafi",
  "grafo",
  "grama",
  "grame",
  "grami",
  "gramo",
  "grana",
  "grane",
  "grani",
  "grano",
  "grata",
  "grate",
  "grati",
  "grato",
  "grava",
  "grave",
  "gravi",
  "gravo",
  "grazi",
  "greca",
  "greci",
  "greco",
  "green",
  "gremi",
  "greti",
  "greto",
  "greve",
  "grevi",
  "grica",
  "grici",
  "grico",
  "grida",
  "gride",
  "gridi",
  "grido",
  "grifa",
  "grifi",
  "grifo",
  "grige",
  "grigi",
  "grill",
  "grima",
  "grime",
  "grimi",
  "grimo",
  "grind",
  "gripi",
  "gripo",
  "grisu",
  "grola",
  "grole",
  "groma",
  "grome",
  "groom",
  "growl",
  "grufa",
  "grufi",
  "grufo",
  "gruga",
  "grugo",
  "gruma",
  "grume",
  "grumi",
  "grumo",
  "grunt",
  "guaco",
  "guada",
  "guade",
  "guadi",
  "guado",
  "guaii",
  "guaio",
  "guale",
  "guali",
  "guani",
  "guano",
  "guari",
  "guata",
  "guati",
  "guato",
  "guava",
  "guave",
  "gueri",
  "gufai",
  "guida",
  "guide",
  "guidi",
  "guido",
  "guina",
  "guini",
  "guino",
  "guisa",
  "guise",
  "gulag",
  "gurge",
  "gurgi",
  "gurka",
  "gusci",
  "gusla",
  "gusle",
  "gusta",
  "gusti",
  "gusto",
  "gutti",
  "gutto",
  "guzla",
  "guzle",
  "hacek",
  "hache",
  "hafni",
  "haiku",
  "halal",
  "haler",
  "hallo",
  "hanno",
  "hapax",
  "happy",
  "harem",
  "hassi",
  "haute",
  "havvi",
  "hello",
  "henna",
  "henne",
  "henry",
  "hertz",
  "hevea",
  "hevee",
  "hijab",
  "hindi",
  "hippy",
  "hobby",
  "holmi",
  "hopla",
  "hotel",
  "house",
  "huchi",
  "humus",
  "hurra",
  "husky",
  "iacea",
  "iacee",
  "iambi",
  "iambo",
  "iarda",
  "iarde",
  "ibera",
  "ibere",
  "iberi",
  "ibero",
  "ibice",
  "ibici",
  "iblea",
  "iblee",
  "iblei",
  "ibleo",
  "icari",
  "icona",
  "icone",
  "icore",
  "icori",
  "ictus",
  "idali",
  "iddea",
  "iddee",
  "iddei",
  "iddeo",
  "iddia",
  "iddii",
  "iddio",
  "ideai",
  "idest",
  "idoli",
  "idolo",
  "idoni",
  "idria",
  "idrie",
  "iella",
  "ielle",
  "ietta",
  "ietti",
  "ietto",
  "igloo",
  "ignea",
  "ignee",
  "ignei",
  "igneo",
  "ilare",
  "ilari",
  "ilice",
  "ilici",
  "ilota",
  "ilote",
  "iloti",
  "iloto",
  "image",
  "imago",
  "imani",
  "imano",
  "imati",
  "imbui",
  "imene",
  "imeni",
  "imita",
  "imiti",
  "imito",
  "impia",
  "impie",
  "impio",
  "inala",
  "inali",
  "inalo",
  "inane",
  "inani",
  "india",
  "indie",
  "indii",
  "indio",
  "indri",
  "ineri",
  "infia",
  "infio",
  "infoi",
  "infra",
  "inghe",
  "ingiu",
  "ingoi",
  "inibi",
  "inizi",
  "inlei",
  "inlui",
  "inmia",
  "inmii",
  "inmio",
  "inoli",
  "inope",
  "inopi",
  "input",
  "insta",
  "insti",
  "insto",
  "intra",
  "intro",
  "intui",
  "inuco",
  "inuit",
  "inula",
  "inule",
  "inuma",
  "inumi",
  "inumo",
  "invai",
  "invar",
  "invei",
  "inver",
  "invia",
  "invii",
  "invio",
  "iochi",
  "iodii",
  "iodio",
  "ioide",
  "ioidi",
  "iolla",
  "iolle",
  "ionia",
  "ionie",
  "ionii",
  "ionio",
  "iorni",
  "iorno",
  "ipate",
  "ipati",
  "irata",
  "irate",
  "irati",
  "irato",
  "irbis",
  "irchi",
  "iremo",
  "ireos",
  "irete",
  "irida",
  "iride",
  "iridi",
  "irido",
  "irite",
  "iriti",
  "iroko",
  "irono",
  "irosa",
  "irose",
  "irosi",
  "iroso",
  "irpef",
  "irpeg",
  "ischi",
  "islam",
  "isodi",
  "isola",
  "isole",
  "isoli",
  "isolo",
  "isopo",
  "issai",
  "istai",
  "ister",
  "istia",
  "istmi",
  "istmo",
  "itala",
  "itale",
  "itali",
  "italo",
  "itera",
  "iteri",
  "itero",
  "ittri",
  "iucca",
  "iudeo",
  "iughi",
  "iulco",
  "iurai",
  "iurta",
  "iusto",
  "ivamo",
  "ivano",
  "ivata",
  "ivate",
  "ivati",
  "ivato",
  "jabot",
  "jager",
  "jaina",
  "jaine",
  "jaini",
  "jaleo",
  "jeans",
  "jihad",
  "jobel",
  "jodel",
  "jolly",
  "jondo",
  "joule",
  "jumbo",
  "jupon",
  "kafta",
  "kajal",
  "kamut",
  "kaone",
  "kaoni",
  "kapoc",
  "kapok",
  "kaput",
  "karma",
  "kasak",
  "kasba",
  "kasbe",
  "kasko",
  "kayak",
  "kazoo",
  "kebab",
  "kefir",
  "kenaf",
  "kendo",
  "ketch",
  "khmer",
  "kilim",
  "koala",
  "koine",
  "kraal",
  "krill",
  "kriss",
  "kulak",
  "kumis",
  "kumys",
  "kuros",
  "kylix",
  "kyrie",
  "labbi",
  "labbo",
  "labio",
  "labri",
  "labro",
  "lacca",
  "lacce",
  "lacci",
  "lacco",
  "lachi",
  "lacmi",
  "lacmo",
  "ladar",
  "ladra",
  "ladre",
  "ladri",
  "ladro",
  "lager",
  "laghi",
  "lagna",
  "lagne",
  "lagni",
  "lagno",
  "laica",
  "laici",
  "laico",
  "laida",
  "laide",
  "laidi",
  "laido",
  "lamai",
  "lamba",
  "lambe",
  "lambi",
  "lambo",
  "lamia",
  "lamie",
  "lamio",
  "lampa",
  "lampe",
  "lampi",
  "lampo",
  "lanca",
  "lance",
  "lanci",
  "landa",
  "lande",
  "lando",
  "lanfa",
  "lanfe",
  "langa",
  "lania",
  "lanio",
  "lanza",
  "lanze",
  "lanzi",
  "lanzo",
  "lapin",
  "lapis",
  "lappa",
  "lappe",
  "lappi",
  "lappo",
  "larda",
  "lardi",
  "lardo",
  "larga",
  "large",
  "largi",
  "largo",
  "larva",
  "larve",
  "larvi",
  "larvo",
  "lasca",
  "lasci",
  "lasco",
  "laser",
  "lassa",
  "lasse",
  "lassi",
  "lasso",
  "lassu",
  "lasta",
  "laste",
  "latex",
  "latra",
  "latri",
  "latro",
  "latta",
  "latte",
  "latti",
  "latto",
  "lauda",
  "laude",
  "laudi",
  "laudo",
  "laura",
  "laure",
  "lauri",
  "lauro",
  "lauta",
  "laute",
  "lauti",
  "lauto",
  "lavai",
  "lazia",
  "lazie",
  "lazio",
  "lazza",
  "lazze",
  "lazzi",
  "lazzo",
  "leale",
  "leali",
  "leben",
  "lebra",
  "lebre",
  "lecca",
  "lecce",
  "lecci",
  "lecco",
  "leena",
  "leene",
  "legai",
  "legga",
  "legge",
  "leggi",
  "leggo",
  "leghe",
  "leghi",
  "legna",
  "legne",
  "legni",
  "legno",
  "lella",
  "lelli",
  "lello",
  "lembi",
  "lembo",
  "lemma",
  "lemme",
  "lemmi",
  "lemmo",
  "lemna",
  "lemne",
  "lenci",
  "lenea",
  "lenee",
  "lenei",
  "leneo",
  "lenii",
  "lenta",
  "lente",
  "lenti",
  "lento",
  "lenza",
  "lenze",
  "lenzi",
  "lenzo",
  "leone",
  "leoni",
  "leppa",
  "leppi",
  "leppo",
  "lepra",
  "lepre",
  "lepri",
  "lerce",
  "lerci",
  "lesbi",
  "lesbo",
  "lesca",
  "lessa",
  "lesse",
  "lessi",
  "lesso",
  "lesta",
  "leste",
  "lesti",
  "lesto",
  "letea",
  "letee",
  "letei",
  "leteo",
  "letri",
  "letro",
  "letta",
  "lette",
  "letti",
  "letto",
  "leudi",
  "leudo",
  "leuti",
  "leuto",
  "levai",
  "levra",
  "levre",
  "lezia",
  "lezie",
  "lezii",
  "lezio",
  "lezza",
  "lezze",
  "lezzi",
  "lezzo",
  "liale",
  "liali",
  "liama",
  "liame",
  "liana",
  "liane",
  "libai",
  "libra",
  "libre",
  "libri",
  "libro",
  "licci",
  "licei",
  "liceo",
  "licet",
  "licia",
  "licie",
  "licio",
  "lidar",
  "lidia",
  "lidie",
  "lidio",
  "lieta",
  "liete",
  "lieti",
  "lieto",
  "lieve",
  "lievi",
  "lifta",
  "lifti",
  "lifto",
  "ligai",
  "ligei",
  "ligeo",
  "lighe",
  "lighi",
  "light",
  "ligia",
  "ligie",
  "ligio",
  "ligne",
  "ligre",
  "ligri",
  "lilla",
  "lilli",
  "lillo",
  "limai",
  "liman",
  "limbi",
  "limbo",
  "limii",
  "limio",
  "limma",
  "limmi",
  "linai",
  "lince",
  "linci",
  "linda",
  "linde",
  "lindi",
  "lindo",
  "linea",
  "linee",
  "linei",
  "lineo",
  "linfa",
  "linfe",
  "linka",
  "linki",
  "linko",
  "lione",
  "lioni",
  "lippa",
  "lippe",
  "lippi",
  "lippo",
  "liqua",
  "liqui",
  "liquo",
  "lirio",
  "lisai",
  "lisca",
  "lisce",
  "lisci",
  "lissa",
  "lisse",
  "lissi",
  "lisso",
  "lista",
  "liste",
  "listi",
  "listo",
  "litai",
  "litii",
  "litio",
  "litri",
  "litro",
  "litta",
  "litte",
  "litui",
  "lituo",
  "liuti",
  "liuto",
  "lizza",
  "lizze",
  "lizzi",
  "lizzo",
  "llano",
  "lloyd",
  "lobby",
  "locai",
  "locca",
  "locco",
  "lochi",
  "locus",
  "lodai",
  "loden",
  "lodge",
  "loess",
  "loffa",
  "loffe",
  "loffi",
  "lofii",
  "lofio",
  "logga",
  "logge",
  "loggo",
  "loghi",
  "login",
  "logli",
  "logon",
  "logos",
  "logra",
  "logri",
  "logro",
  "loica",
  "loico",
  "lokum",
  "lolla",
  "lolle",
  "lombi",
  "lombo",
  "lompi",
  "lompo",
  "longa",
  "longi",
  "longo",
  "lonza",
  "lonze",
  "lonzi",
  "lonzo",
  "loppa",
  "loppe",
  "loppi",
  "loppo",
  "loran",
  "lorda",
  "lorde",
  "lordi",
  "lordo",
  "lorio",
  "losca",
  "losco",
  "lotta",
  "lotte",
  "lotti",
  "lotto",
  "loure",
  "lucci",
  "lucco",
  "lucei",
  "luchi",
  "lucia",
  "lucie",
  "lucio",
  "lucra",
  "lucri",
  "lucro",
  "ludei",
  "ludra",
  "ludre",
  "ludri",
  "ludro",
  "luffa",
  "luffe",
  "luffi",
  "luffo",
  "lugli",
  "luigi",
  "lulla",
  "lulle",
  "lumai",
  "lumen",
  "lumia",
  "lumie",
  "lumpi",
  "lumpo",
  "lunch",
  "lunga",
  "lunge",
  "lungi",
  "lungo",
  "luogo",
  "lupai",
  "lupus",
  "lurca",
  "lurco",
  "lurex",
  "lusca",
  "lusco",
  "lussa",
  "lussi",
  "lusso",
  "lutai",
  "lutea",
  "lutee",
  "lutei",
  "luteo",
  "lutta",
  "lutti",
  "lutto",
  "lycra",
  "macai",
  "macao",
  "macca",
  "macco",
  "macea",
  "mache",
  "macho",
  "macia",
  "macie",
  "macis",
  "macro",
  "madia",
  "madie",
  "madre",
  "madri",
  "mafia",
  "mafie",
  "magge",
  "maggi",
  "maghe",
  "maghi",
  "magia",
  "magie",
  "magio",
  "magli",
  "magma",
  "magmi",
  "magna",
  "magne",
  "magni",
  "magno",
  "magra",
  "magre",
  "magri",
  "magro",
  "mahdi",
  "maina",
  "maino",
  "maisi",
  "major",
  "malga",
  "malia",
  "malie",
  "malli",
  "mallo",
  "malta",
  "malte",
  "malti",
  "malto",
  "malva",
  "malve",
  "mamba",
  "mambi",
  "mambo",
  "mamma",
  "mamme",
  "mammi",
  "mammo",
  "manca",
  "mance",
  "manco",
  "manda",
  "mande",
  "mandi",
  "mando",
  "manga",
  "mangi",
  "mango",
  "mania",
  "manie",
  "manna",
  "manne",
  "manni",
  "manno",
  "mansa",
  "manse",
  "mansi",
  "manso",
  "manta",
  "mante",
  "manti",
  "manto",
  "manul",
  "manza",
  "manze",
  "manzi",
  "manzo",
  "maona",
  "maone",
  "maori",
  "mappa",
  "mappe",
  "mappi",
  "mappo",
  "marca",
  "marce",
  "march",
  "marci",
  "marco",
  "marea",
  "maree",
  "marga",
  "margo",
  "maria",
  "marie",
  "marma",
  "marmi",
  "marmo",
  "marna",
  "marne",
  "marni",
  "marno",
  "marra",
  "marre",
  "marsa",
  "marsc",
  "marse",
  "marsi",
  "marso",
  "marte",
  "marza",
  "marze",
  "marzi",
  "marzo",
  "masca",
  "masce",
  "maser",
  "massa",
  "masse",
  "massi",
  "masso",
  "masti",
  "masut",
  "match",
  "matre",
  "matta",
  "matte",
  "matti",
  "matto",
  "maura",
  "maure",
  "mauri",
  "mauro",
  "mauve",
  "mazut",
  "mazza",
  "mazze",
  "mazzi",
  "mazzo",
  "meano",
  "meare",
  "meata",
  "meate",
  "meati",
  "meato",
  "meava",
  "meavi",
  "meavo",
  "mecca",
  "meche",
  "media",
  "medie",
  "medio",
  "meera",
  "meero",
  "meino",
  "melea",
  "melee",
  "melia",
  "melie",
  "melma",
  "melme",
  "meloe",
  "meloi",
  "melos",
  "menai",
  "mence",
  "menci",
  "menda",
  "mende",
  "mendo",
  "menna",
  "menne",
  "menni",
  "menno",
  "mensa",
  "mense",
  "menta",
  "mente",
  "menti",
  "mento",
  "meoli",
  "meolo",
  "meoni",
  "merca",
  "merce",
  "merci",
  "merco",
  "merda",
  "merde",
  "mergo",
  "meria",
  "merie",
  "merio",
  "merla",
  "merle",
  "merli",
  "merlo",
  "merta",
  "merti",
  "merto",
  "merze",
  "mesca",
  "mesce",
  "mesci",
  "mesco",
  "messa",
  "messe",
  "messi",
  "messo",
  "mesta",
  "meste",
  "mesti",
  "mesto",
  "metal",
  "meteo",
  "meter",
  "metri",
  "metro",
  "metta",
  "mette",
  "metti",
  "metto",
  "mevio",
  "mezza",
  "mezze",
  "mezzi",
  "mezzo",
  "miasi",
  "micca",
  "micce",
  "micci",
  "micco",
  "miche",
  "micia",
  "micie",
  "micio",
  "miele",
  "mieli",
  "mieta",
  "miete",
  "mieti",
  "mieto",
  "migli",
  "migma",
  "migmi",
  "migra",
  "migri",
  "migro",
  "miler",
  "milia",
  "milii",
  "milio",
  "mille",
  "milza",
  "milze",
  "milzi",
  "milzo",
  "mimai",
  "mimma",
  "mimme",
  "mimmi",
  "mimmo",
  "minai",
  "minga",
  "minge",
  "mingi",
  "mingo",
  "minia",
  "minii",
  "minio",
  "minse",
  "minsi",
  "minta",
  "minte",
  "minti",
  "minto",
  "mioma",
  "miomi",
  "miope",
  "miopi",
  "miosi",
  "mirai",
  "mirra",
  "mirre",
  "mirri",
  "mirro",
  "mirti",
  "mirto",
  "misfa",
  "misfo",
  "missa",
  "missi",
  "misso",
  "mista",
  "miste",
  "misti",
  "misto",
  "mitra",
  "mitre",
  "mitri",
  "mitro",
  "mixai",
  "mixer",
  "mocci",
  "mochi",
  "modem",
  "modii",
  "modio",
  "moena",
  "moene",
  "moggi",
  "mogia",
  "mogie",
  "mogio",
  "mogli",
  "mogol",
  "mohur",
  "moina",
  "moine",
  "moira",
  "moire",
  "molai",
  "molca",
  "molce",
  "molci",
  "molco",
  "molla",
  "molle",
  "molli",
  "mollo",
  "moloc",
  "molse",
  "molsi",
  "molta",
  "molte",
  "molti",
  "molto",
  "molva",
  "molve",
  "mommi",
  "mommo",
  "monca",
  "monco",
  "monda",
  "monde",
  "mondi",
  "mondo",
  "monel",
  "monna",
  "monne",
  "monsu",
  "monta",
  "monte",
  "monti",
  "monto",
  "morai",
  "morbi",
  "morbo",
  "morda",
  "morde",
  "mordi",
  "mordo",
  "moria",
  "morie",
  "morii",
  "morio",
  "morra",
  "morre",
  "morro",
  "morsa",
  "morse",
  "morsi",
  "morso",
  "morta",
  "morte",
  "morti",
  "morto",
  "morva",
  "morve",
  "mosca",
  "mosce",
  "mosci",
  "mosco",
  "mossa",
  "mosse",
  "mossi",
  "mosso",
  "mosti",
  "mosto",
  "motel",
  "motta",
  "motte",
  "motti",
  "motto",
  "mouse",
  "movie",
  "movil",
  "mozza",
  "mozze",
  "mozzi",
  "mozzo",
  "mucca",
  "mucci",
  "mucco",
  "muchi",
  "mudai",
  "muffa",
  "muffe",
  "muffi",
  "muffo",
  "mufti",
  "mugge",
  "muggi",
  "mughi",
  "mugic",
  "mugik",
  "mugli",
  "mugne",
  "mugni",
  "mulsa",
  "mulse",
  "mulsi",
  "mulso",
  "multa",
  "multe",
  "multi",
  "multo",
  "mundi",
  "munga",
  "munge",
  "mungi",
  "mungo",
  "munii",
  "munse",
  "munsi",
  "munta",
  "munte",
  "munti",
  "munto",
  "muoia",
  "muoio",
  "muone",
  "muoni",
  "muore",
  "muori",
  "muova",
  "muove",
  "muovi",
  "muovo",
  "murai",
  "murge",
  "muria",
  "murie",
  "murra",
  "murre",
  "musai",
  "musco",
  "musei",
  "museo",
  "music",
  "musli",
  "musme",
  "mussa",
  "mussi",
  "musso",
  "mutai",
  "mutto",
  "mutua",
  "mutue",
  "mutui",
  "mutuo",
  "nabab",
  "nabla",
  "nabuk",
  "nadir",
  "nafta",
  "nafte",
  "nagra",
  "nahua",
  "naibi",
  "naide",
  "naidi",
  "naive",
  "nandu",
  "nanfa",
  "nanfe",
  "nanna",
  "nanne",
  "nante",
  "nanzi",
  "napea",
  "napee",
  "nappa",
  "nappe",
  "nappi",
  "nappo",
  "narco",
  "nardi",
  "nardo",
  "narra",
  "narri",
  "narro",
  "nasai",
  "nasca",
  "nasce",
  "nasci",
  "nasco",
  "nashi",
  "naspi",
  "naspo",
  "nassa",
  "nasse",
  "nasua",
  "nasue",
  "natai",
  "natel",
  "natia",
  "natie",
  "natii",
  "natio",
  "natta",
  "natte",
  "nauta",
  "nauti",
  "necai",
  "necce",
  "necci",
  "nefas",
  "negai",
  "neghi",
  "negli",
  "negra",
  "negre",
  "negri",
  "negro",
  "negus",
  "nella",
  "nelle",
  "nelli",
  "nello",
  "nembi",
  "nembo",
  "nemea",
  "nemee",
  "nemei",
  "nemeo",
  "nenia",
  "nenie",
  "neper",
  "nerba",
  "nerbi",
  "nerbo",
  "nervi",
  "nervo",
  "nesce",
  "nesci",
  "nessi",
  "nesso",
  "nesta",
  "nesti",
  "nesto",
  "netta",
  "nette",
  "netti",
  "netto",
  "neuma",
  "neumi",
  "neuna",
  "neuno",
  "neuro",
  "nevai",
  "newco",
  "nibbi",
  "nicce",
  "nicci",
  "nicol",
  "nidio",
  "niego",
  "nieve",
  "nievi",
  "nievo",
  "niffa",
  "niffe",
  "niffi",
  "niffo",
  "night",
  "nigra",
  "nigre",
  "nigri",
  "nigro",
  "nimbi",
  "nimbo",
  "nimby",
  "ninfa",
  "ninfe",
  "ninfi",
  "ninfo",
  "ninna",
  "ninne",
  "ninni",
  "ninno",
  "niobi",
  "niqab",
  "nisba",
  "nitra",
  "nitri",
  "nitro",
  "niuna",
  "niuno",
  "nivea",
  "nivee",
  "nivei",
  "niveo",
  "nobel",
  "nocca",
  "nocuo",
  "nodri",
  "noema",
  "noemi",
  "noesi",
  "noire",
  "nomai",
  "nomea",
  "nomee",
  "nonii",
  "nonio",
  "nonna",
  "nonne",
  "nonni",
  "nonno",
  "noria",
  "norie",
  "norma",
  "norme",
  "normi",
  "normo",
  "norna",
  "norne",
  "norte",
  "nosco",
  "notai",
  "notte",
  "notti",
  "novai",
  "novio",
  "nozze",
  "nuche",
  "nudai",
  "nudri",
  "nulla",
  "nulle",
  "nulli",
  "nullo",
  "nummi",
  "nummo",
  "nunci",
  "nunzi",
  "nuoce",
  "nuoci",
  "nuora",
  "nuore",
  "nuota",
  "nuoti",
  "nuoto",
  "nuova",
  "nuove",
  "nuovi",
  "nuovo",
  "nurse",
  "nutra",
  "nutre",
  "nutri",
  "nutro",
  "nylon",
  "obedi",
  "obeli",
  "obelo",
  "obera",
  "oberi",
  "obero",
  "obesa",
  "obese",
  "obesi",
  "obeso",
  "obice",
  "obici",
  "obiti",
  "obito",
  "oblai",
  "oblia",
  "oblii",
  "oblio",
  "oboli",
  "obolo",
  "ocche",
  "occhi",
  "ocimi",
  "ocimo",
  "ocrea",
  "ocree",
  "odano",
  "oddio",
  "odeon",
  "odiai",
  "odino",
  "odire",
  "odita",
  "odite",
  "oditi",
  "odito",
  "odono",
  "odora",
  "odore",
  "odori",
  "odoro",
  "odrai",
  "oecio",
  "offra",
  "offre",
  "offri",
  "offro",
  "ofide",
  "ofidi",
  "ofite",
  "ofiti",
  "ogiva",
  "ogive",
  "ohibo",
  "ohila",
  "ohime",
  "oidii",
  "oidio",
  "okapi",
  "oleai",
  "oleum",
  "olfai",
  "oliai",
  "olino",
  "olire",
  "oliva",
  "olive",
  "olivi",
  "olivo",
  "olmii",
  "olmio",
  "olona",
  "olone",
  "olore",
  "oltra",
  "oltre",
  "oltri",
  "oltro",
  "omari",
  "omaro",
  "omasi",
  "omaso",
  "ombra",
  "ombre",
  "ombri",
  "ombro",
  "omega",
  "omeri",
  "omero",
  "omini",
  "omino",
  "omise",
  "omisi",
  "omone",
  "omoni",
  "omore",
  "omori",
  "onchi",
  "oncia",
  "ondai",
  "onera",
  "onere",
  "oneri",
  "onero",
  "onice",
  "onici",
  "onira",
  "onire",
  "oniro",
  "onita",
  "onite",
  "oniti",
  "onito",
  "oniva",
  "onivi",
  "onivo",
  "onlus",
  "onora",
  "onore",
  "onori",
  "onoro",
  "onrai",
  "opaca",
  "opaco",
  "opala",
  "opale",
  "opali",
  "opalo",
  "opera",
  "opere",
  "operi",
  "opero",
  "opima",
  "opime",
  "opimi",
  "opimo",
  "opina",
  "opini",
  "opino",
  "oppia",
  "oppio",
  "oppla",
  "oprai",
  "oprii",
  "optai",
  "orada",
  "orade",
  "orafa",
  "orafe",
  "orafi",
  "orafo",
  "orale",
  "orali",
  "orano",
  "orare",
  "orari",
  "orata",
  "orate",
  "orati",
  "orato",
  "orava",
  "oravi",
  "oravo",
  "orbai",
  "orche",
  "orchi",
  "orcio",
  "ordii",
  "orera",
  "orero",
  "orgia",
  "orgie",
  "oribi",
  "orice",
  "orici",
  "orige",
  "origi",
  "origo",
  "orina",
  "orine",
  "orini",
  "orino",
  "orlai",
  "orlon",
  "ormai",
  "ornai",
  "orrai",
  "orzai",
  "osano",
  "osare",
  "osata",
  "osate",
  "osati",
  "osato",
  "osava",
  "osavi",
  "osavo",
  "oscar",
  "osche",
  "oschi",
  "osera",
  "osero",
  "osino",
  "osmii",
  "osmio",
  "ossea",
  "ossee",
  "ossei",
  "osseo",
  "ossia",
  "ostai",
  "ostia",
  "ostie",
  "ostio",
  "ostri",
  "ostro",
  "osura",
  "osure",
  "otica",
  "otici",
  "otico",
  "otite",
  "otiti",
  "otria",
  "otrio",
  "ovaia",
  "ovaie",
  "ovaio",
  "ovale",
  "ovali",
  "ovari",
  "ovata",
  "ovate",
  "ovati",
  "ovato",
  "overa",
  "overi",
  "overo",
  "ovest",
  "ovile",
  "ovili",
  "ovina",
  "ovine",
  "ovini",
  "ovino",
  "ovoli",
  "ovolo",
  "ovrai",
  "ovula",
  "ovuli",
  "ovulo",
  "ovvia",
  "ovvie",
  "ovvii",
  "ovvio",
  "ozena",
  "ozene",
  "oziai",
  "ozino",
  "ozoni",
  "ozono",
  "pabbi",
  "pacai",
  "pacca",
  "pacco",
  "pachi",
  "pacia",
  "pacio",
  "padda",
  "padre",
  "padri",
  "paese",
  "paesi",
  "pagai",
  "pager",
  "paggi",
  "paghe",
  "paghi",
  "pagri",
  "pagro",
  "paini",
  "paino",
  "paisa",
  "palai",
  "palco",
  "palea",
  "palee",
  "palei",
  "paleo",
  "palii",
  "palio",
  "palla",
  "palle",
  "palli",
  "pallo",
  "palma",
  "palme",
  "palmi",
  "palmo",
  "palpa",
  "palpi",
  "palpo",
  "palta",
  "palte",
  "palto",
  "pampa",
  "pampe",
  "panai",
  "panca",
  "pance",
  "panda",
  "pande",
  "pandi",
  "pando",
  "panel",
  "pania",
  "panie",
  "panna",
  "panne",
  "panni",
  "panno",
  "panse",
  "panta",
  "pants",
  "panza",
  "panze",
  "paoli",
  "paolo",
  "paone",
  "paoni",
  "papas",
  "pappa",
  "pappe",
  "pappi",
  "pappo",
  "papua",
  "parai",
  "parca",
  "parce",
  "parci",
  "parco",
  "pardi",
  "pardo",
  "parei",
  "pareo",
  "paria",
  "parie",
  "pario",
  "parka",
  "parla",
  "parli",
  "parlo",
  "parma",
  "parme",
  "parra",
  "parre",
  "parro",
  "parsa",
  "parse",
  "parsi",
  "parso",
  "parta",
  "parte",
  "parti",
  "parto",
  "party",
  "parva",
  "parve",
  "parvi",
  "parvo",
  "pasca",
  "pasce",
  "pasci",
  "pasco",
  "pasmi",
  "pasmo",
  "passa",
  "passe",
  "passi",
  "passo",
  "pasta",
  "paste",
  "pasti",
  "pasto",
  "patch",
  "pater",
  "patii",
  "patio",
  "patos",
  "patre",
  "patri",
  "patta",
  "patte",
  "patti",
  "patto",
  "paura",
  "paure",
  "pausa",
  "pause",
  "pausi",
  "pauso",
  "pavia",
  "pavie",
  "pazza",
  "pazze",
  "pazzi",
  "pazzo",
  "peana",
  "peani",
  "peara",
  "peata",
  "peate",
  "pecan",
  "pecca",
  "pecce",
  "pecci",
  "pecco",
  "pecia",
  "pecie",
  "pegli",
  "pegni",
  "pegno",
  "pelai",
  "pella",
  "pelle",
  "pelli",
  "pello",
  "pelta",
  "pelte",
  "pelvi",
  "penai",
  "pence",
  "penda",
  "pende",
  "pendi",
  "pendo",
  "penei",
  "peneo",
  "penna",
  "penne",
  "penny",
  "pensa",
  "pensi",
  "penso",
  "penta",
  "pente",
  "penti",
  "pento",
  "peoci",
  "peone",
  "peoni",
  "peota",
  "peote",
  "pepai",
  "pepli",
  "peplo",
  "pepsi",
  "perbi",
  "perca",
  "perda",
  "perde",
  "perdi",
  "perdo",
  "perfa",
  "perfo",
  "perii",
  "perla",
  "perle",
  "perni",
  "perno",
  "persa",
  "perse",
  "persi",
  "perso",
  "pervi",
  "pesah",
  "pesai",
  "pesca",
  "pesce",
  "pesci",
  "pesco",
  "pesta",
  "peste",
  "pesti",
  "pesto",
  "petra",
  "petre",
  "petti",
  "petto",
  "pezza",
  "pezze",
  "pezzi",
  "pezzo",
  "piace",
  "piaci",
  "piada",
  "piade",
  "piaga",
  "piage",
  "piagi",
  "piago",
  "piamo",
  "piana",
  "piane",
  "piani",
  "piano",
  "piare",
  "piata",
  "piate",
  "piati",
  "piato",
  "piava",
  "piave",
  "piavi",
  "piavo",
  "picca",
  "picce",
  "picco",
  "picea",
  "picee",
  "picei",
  "piceo",
  "piche",
  "picio",
  "piece",
  "piede",
  "piedi",
  "piega",
  "piego",
  "piena",
  "piene",
  "pieni",
  "pieno",
  "piera",
  "pieri",
  "piero",
  "pieta",
  "pieti",
  "pieve",
  "pievi",
  "pighi",
  "pigia",
  "pigio",
  "pigli",
  "pigna",
  "pigne",
  "pigra",
  "pigre",
  "pigri",
  "pigro",
  "piino",
  "pilaf",
  "pilai",
  "pilei",
  "pileo",
  "pilla",
  "pilli",
  "pillo",
  "pinca",
  "pince",
  "pinco",
  "pinga",
  "pinge",
  "pingi",
  "pingo",
  "pinna",
  "pinne",
  "pinot",
  "pinse",
  "pinsi",
  "pinta",
  "pinte",
  "pinti",
  "pinto",
  "pinza",
  "pinze",
  "pinzi",
  "pinzo",
  "pioda",
  "piode",
  "piola",
  "piole",
  "pioli",
  "piolo",
  "pione",
  "pioni",
  "piota",
  "piote",
  "pioti",
  "pioto",
  "piova",
  "piove",
  "piovi",
  "piovo",
  "pipai",
  "pipia",
  "pipii",
  "pipio",
  "pippa",
  "pippe",
  "pipra",
  "pipre",
  "pique",
  "pirex",
  "pirla",
  "pirli",
  "pisce",
  "pisci",
  "pissi",
  "pista",
  "piste",
  "pitia",
  "pitie",
  "pitta",
  "pitti",
  "pitto",
  "piuma",
  "piume",
  "pivot",
  "pixel",
  "pizia",
  "pizie",
  "pizio",
  "pizza",
  "pizze",
  "pizzi",
  "pizzo",
  "placa",
  "place",
  "placo",
  "plaga",
  "plage",
  "plagi",
  "plaid",
  "plana",
  "plani",
  "plano",
  "plebe",
  "plebi",
  "plena",
  "plica",
  "plico",
  "ploia",
  "ploie",
  "plora",
  "plori",
  "ploro",
  "ploti",
  "ploto",
  "plugo",
  "plume",
  "pluvi",
  "poana",
  "poane",
  "pocce",
  "pocci",
  "poche",
  "pochi",
  "podii",
  "podio",
  "poema",
  "poemi",
  "poesi",
  "poeta",
  "poeti",
  "poeto",
  "pogai",
  "pogge",
  "poggi",
  "poghi",
  "pogna",
  "poina",
  "poise",
  "poker",
  "polca",
  "polii",
  "polio",
  "polis",
  "polka",
  "polke",
  "polla",
  "polle",
  "polli",
  "pollo",
  "polpa",
  "polpe",
  "polpi",
  "polpo",
  "polsi",
  "polso",
  "polta",
  "polte",
  "polve",
  "pomai",
  "pomfi",
  "pomfo",
  "pompa",
  "pompe",
  "pompi",
  "pompo",
  "ponce",
  "ponci",
  "pondi",
  "pondo",
  "ponfi",
  "ponfo",
  "ponga",
  "pongo",
  "ponno",
  "ponso",
  "ponta",
  "ponte",
  "ponti",
  "ponto",
  "ponza",
  "ponzi",
  "ponzo",
  "poppa",
  "poppe",
  "poppi",
  "poppo",
  "popup",
  "porca",
  "porci",
  "porco",
  "porei",
  "porga",
  "porge",
  "porgi",
  "porgo",
  "poria",
  "pormi",
  "porno",
  "porra",
  "porre",
  "porri",
  "porro",
  "porse",
  "porsi",
  "porta",
  "porte",
  "porti",
  "porto",
  "posai",
  "posca",
  "possa",
  "posse",
  "possi",
  "posso",
  "posta",
  "poste",
  "posti",
  "posto",
  "potai",
  "potei",
  "potos",
  "potra",
  "potro",
  "potta",
  "potte",
  "poule",
  "pozza",
  "pozze",
  "pozzi",
  "pozzo",
  "praho",
  "praia",
  "praie",
  "prana",
  "prati",
  "prato",
  "prava",
  "prave",
  "pravi",
  "pravo",
  "prece",
  "preci",
  "preco",
  "preda",
  "prede",
  "predi",
  "predo",
  "prefa",
  "prefo",
  "prega",
  "pregi",
  "prego",
  "prema",
  "preme",
  "premi",
  "premo",
  "presa",
  "prese",
  "presi",
  "preso",
  "prete",
  "preti",
  "previ",
  "prima",
  "prime",
  "primi",
  "primo",
  "priva",
  "prive",
  "privi",
  "privo",
  "proba",
  "probe",
  "probi",
  "probo",
  "proci",
  "proco",
  "proda",
  "prode",
  "prodi",
  "prole",
  "promo",
  "prona",
  "prone",
  "proni",
  "prono",
  "prope",
  "prora",
  "prore",
  "prosa",
  "prose",
  "prosi",
  "proso",
  "proti",
  "proto",
  "prova",
  "prove",
  "provi",
  "provo",
  "proxy",
  "prozi",
  "pruda",
  "prude",
  "prudi",
  "prudo",
  "pruna",
  "pruni",
  "pruno",
  "pruri",
  "psoas",
  "psoco",
  "psofi",
  "psofo",
  "ptini",
  "ptino",
  "ptisi",
  "ptosi",
  "pugge",
  "puggi",
  "pugio",
  "pugna",
  "pugne",
  "pugni",
  "pugno",
  "puina",
  "puine",
  "pulca",
  "pulce",
  "pulci",
  "pulii",
  "pulka",
  "pulke",
  "pullo",
  "pulsa",
  "pulsi",
  "pulso",
  "punch",
  "punci",
  "punga",
  "punge",
  "pungi",
  "pungo",
  "punii",
  "punse",
  "punsi",
  "punta",
  "punte",
  "punti",
  "punto",
  "puole",
  "puose",
  "puosi",
  "puote",
  "puppa",
  "purea",
  "puree",
  "purga",
  "purgo",
  "purim",
  "pusta",
  "puste",
  "putii",
  "putra",
  "putre",
  "putri",
  "putro",
  "putta",
  "putte",
  "putti",
  "putto",
  "puzza",
  "puzze",
  "puzzi",
  "puzzo",
  "pyrex",
  "qasba",
  "qasbe",
  "qibla",
  "quale",
  "quali",
  "quare",
  "quark",
  "quasi",
  "queer",
  "query",
  "quesi",
  "queta",
  "queti",
  "queto",
  "quici",
  "quine",
  "quipu",
  "quita",
  "quiti",
  "quito",
  "quivi",
  "quoca",
  "quoce",
  "quoci",
  "quoco",
  "quoia",
  "quoio",
  "quore",
  "quori",
  "quota",
  "quote",
  "quoti",
  "quoto",
  "rabbi",
  "racca",
  "racco",
  "racer",
  "rachi",
  "racon",
  "radar",
  "radei",
  "radia",
  "radii",
  "radio",
  "radon",
  "raffa",
  "raffe",
  "raffi",
  "raffo",
  "rafia",
  "rafie",
  "ragas",
  "raggi",
  "ragia",
  "ragie",
  "ragli",
  "ragna",
  "ragne",
  "ragni",
  "ragno",
  "raiai",
  "raide",
  "raidi",
  "raino",
  "raion",
  "rajah",
  "ralla",
  "ralle",
  "ralli",
  "rallo",
  "rally",
  "ramai",
  "rambo",
  "ramia",
  "ramie",
  "ramni",
  "ramno",
  "rampa",
  "rampe",
  "rampi",
  "rampo",
  "ranca",
  "ranch",
  "ranci",
  "ranco",
  "randa",
  "rande",
  "ranfi",
  "range",
  "rango",
  "ranni",
  "ranno",
  "rapai",
  "rapii",
  "rappa",
  "rappe",
  "rappi",
  "rappo",
  "rasai",
  "rasce",
  "rasoi",
  "raspa",
  "raspe",
  "raspi",
  "raspo",
  "rasta",
  "ratea",
  "ratei",
  "rateo",
  "ratia",
  "ratie",
  "ratii",
  "ratio",
  "ratta",
  "ratte",
  "ratti",
  "ratto",
  "rauca",
  "rauco",
  "raudi",
  "raudo",
  "rauna",
  "rauni",
  "rauno",
  "rayon",
  "razza",
  "razze",
  "razzi",
  "razzo",
  "ready",
  "reagi",
  "reale",
  "reali",
  "reame",
  "reami",
  "reati",
  "reato",
  "rebbi",
  "rebus",
  "recai",
  "rechi",
  "recto",
  "redai",
  "redan",
  "redda",
  "redde",
  "reddi",
  "reddo",
  "redii",
  "redox",
  "regga",
  "regge",
  "reggi",
  "reggo",
  "regia",
  "regie",
  "regio",
  "regna",
  "regni",
  "regno",
  "reich",
  "reiki",
  "reina",
  "reine",
  "reini",
  "reita",
  "relax",
  "remai",
  "remix",
  "renai",
  "renda",
  "rende",
  "rendi",
  "rendo",
  "renii",
  "renio",
  "renna",
  "renne",
  "rensa",
  "rense",
  "repli",
  "replo",
  "requi",
  "reset",
  "resia",
  "resie",
  "respi",
  "respo",
  "ressa",
  "resse",
  "ressi",
  "resta",
  "reste",
  "resti",
  "resto",
  "retai",
  "retro",
  "retta",
  "rette",
  "retti",
  "retto",
  "reuma",
  "reumi",
  "rezza",
  "rezze",
  "rezzi",
  "rezzo",
  "riagi",
  "riale",
  "riama",
  "riami",
  "riamo",
  "riara",
  "riari",
  "riaro",
  "ribes",
  "ricca",
  "ricce",
  "ricci",
  "ricco",
  "ricso",
  "ridai",
  "ridda",
  "ridde",
  "riddi",
  "riddo",
  "rider",
  "ridia",
  "rieda",
  "riede",
  "riedi",
  "riedo",
  "riera",
  "rieri",
  "riero",
  "rieto",
  "rifai",
  "riffa",
  "riffe",
  "rifui",
  "rigai",
  "rigge",
  "righe",
  "righi",
  "right",
  "rigno",
  "rimai",
  "rinco",
  "rioda",
  "riode",
  "riodi",
  "riodo",
  "rione",
  "rioni",
  "risai",
  "risco",
  "risei",
  "risia",
  "risii",
  "risma",
  "risme",
  "rissa",
  "risse",
  "rissi",
  "risso",
  "rista",
  "risto",
  "ritma",
  "ritmi",
  "ritmo",
  "ritta",
  "ritte",
  "ritti",
  "ritto",
  "riudi",
  "riuni",
  "riusa",
  "riusi",
  "riuso",
  "rivai",
  "rizza",
  "rizze",
  "rizzi",
  "rizzo",
  "roana",
  "roane",
  "roani",
  "roano",
  "robai",
  "robba",
  "robot",
  "rocca",
  "rocce",
  "rocco",
  "roche",
  "rochi",
  "rodai",
  "rodei",
  "rodeo",
  "rodia",
  "rodie",
  "rodii",
  "rodio",
  "rogai",
  "rogge",
  "roghi",
  "rogna",
  "rogne",
  "rogni",
  "rogno",
  "rolla",
  "rolle",
  "rolli",
  "rollo",
  "roman",
  "romba",
  "rombe",
  "rombi",
  "rombo",
  "romea",
  "romee",
  "romei",
  "romeo",
  "rompa",
  "rompe",
  "rompi",
  "rompo",
  "ronca",
  "ronco",
  "ronda",
  "ronde",
  "rondi",
  "rondo",
  "ronfa",
  "ronfe",
  "ronfi",
  "ronfo",
  "ronza",
  "ronzi",
  "ronzo",
  "rorai",
  "rosai",
  "rosea",
  "rosee",
  "rosei",
  "roseo",
  "rospi",
  "rospo",
  "rossa",
  "rosse",
  "rossi",
  "rosso",
  "rosta",
  "roste",
  "rosti",
  "rosto",
  "rotai",
  "rotea",
  "rotei",
  "roteo",
  "rotor",
  "rotta",
  "rotte",
  "rotti",
  "rotto",
  "rough",
  "round",
  "rovai",
  "rover",
  "rozza",
  "rozze",
  "rozzi",
  "rozzo",
  "rubai",
  "rubba",
  "rubbi",
  "rubbo",
  "rubli",
  "rublo",
  "rubra",
  "rubre",
  "rubri",
  "rubro",
  "ruche",
  "ruere",
  "ruffa",
  "ruffe",
  "rugai",
  "rugby",
  "rugge",
  "ruggi",
  "rughe",
  "rughi",
  "rugli",
  "ruina",
  "ruine",
  "ruini",
  "ruino",
  "rulla",
  "rulle",
  "rulli",
  "rullo",
  "rumai",
  "rumba",
  "rumbe",
  "rumme",
  "rummi",
  "rumor",
  "ruoli",
  "ruolo",
  "ruota",
  "ruote",
  "ruoti",
  "ruoto",
  "rupia",
  "rupie",
  "ruppe",
  "ruppi",
  "rusco",
  "ruspa",
  "ruspe",
  "ruspi",
  "ruspo",
  "russa",
  "russe",
  "russi",
  "russo",
  "rutta",
  "rutti",
  "rutto",
  "ruzza",
  "ruzzi",
  "ruzzo",
  "ryton",
  "sabba",
  "sabbi",
  "sabea",
  "sabee",
  "sabei",
  "sabeo",
  "sabir",
  "sabot",
  "sabra",
  "sabre",
  "sacca",
  "sacci",
  "sacco",
  "sache",
  "sacra",
  "sacre",
  "sacri",
  "sacro",
  "saffo",
  "sagge",
  "saggi",
  "saghe",
  "saghi",
  "sagii",
  "sagli",
  "sagna",
  "sagni",
  "sagno",
  "sagra",
  "sagre",
  "sagri",
  "sagro",
  "sahib",
  "saiga",
  "saime",
  "saimi",
  "saina",
  "saine",
  "salai",
  "salce",
  "salci",
  "salda",
  "salde",
  "saldi",
  "saldo",
  "salga",
  "salgo",
  "salii",
  "salio",
  "salma",
  "salme",
  "salmi",
  "salmo",
  "salpa",
  "salpe",
  "salpi",
  "salpo",
  "salsa",
  "salse",
  "salsi",
  "salso",
  "salta",
  "salti",
  "salto",
  "salva",
  "salve",
  "salvi",
  "salvo",
  "samba",
  "sambe",
  "sambo",
  "samum",
  "sanai",
  "sanci",
  "sanie",
  "sanna",
  "sanne",
  "sanno",
  "sansa",
  "sanse",
  "santa",
  "sante",
  "santi",
  "santo",
  "sanza",
  "sapei",
  "sapia",
  "sapie",
  "sapio",
  "sappi",
  "sappo",
  "sapra",
  "sapro",
  "sarai",
  "sarda",
  "sarde",
  "sardi",
  "sardo",
  "sarei",
  "sarge",
  "sargo",
  "saria",
  "sarin",
  "saros",
  "sarpa",
  "sarpe",
  "sarpi",
  "sarpo",
  "sarro",
  "sarta",
  "sarte",
  "sarti",
  "sarto",
  "sartu",
  "sassi",
  "sasso",
  "satem",
  "satin",
  "sauna",
  "saune",
  "saura",
  "saure",
  "sauri",
  "sauro",
  "saute",
  "savei",
  "savia",
  "savie",
  "savio",
  "sazia",
  "sazie",
  "sazio",
  "sbadi",
  "sbafa",
  "sbafi",
  "sbafo",
  "sbasi",
  "sbava",
  "sbavi",
  "sbavo",
  "sboba",
  "sbobe",
  "sboom",
  "sbovi",
  "sbovo",
  "sbrai",
  "sbuca",
  "sbuco",
  "scada",
  "scade",
  "scadi",
  "scado",
  "scafa",
  "scafe",
  "scafi",
  "scafo",
  "scala",
  "scale",
  "scali",
  "scalo",
  "scana",
  "scane",
  "scapa",
  "scapi",
  "scapo",
  "scari",
  "scaro",
  "scart",
  "scasa",
  "scasi",
  "scaso",
  "scaut",
  "scava",
  "scavi",
  "scavo",
  "sceda",
  "scede",
  "sceli",
  "scelo",
  "scema",
  "sceme",
  "scemi",
  "scemo",
  "scena",
  "scene",
  "scesa",
  "scese",
  "scesi",
  "sceso",
  "sceva",
  "scevi",
  "schei",
  "scheo",
  "sciah",
  "sciai",
  "scifi",
  "scifo",
  "scilp",
  "scipa",
  "scipi",
  "scipo",
  "scire",
  "scoda",
  "scodi",
  "scodo",
  "scoia",
  "scoio",
  "scola",
  "scole",
  "scoli",
  "scolo",
  "scoop",
  "scopa",
  "scope",
  "scopi",
  "scopo",
  "scora",
  "score",
  "scori",
  "scoro",
  "scota",
  "scote",
  "scoti",
  "scoto",
  "scout",
  "scova",
  "scovi",
  "scovo",
  "scria",
  "scrie",
  "scrii",
  "scrio",
  "scrub",
  "scuce",
  "scuci",
  "scudi",
  "scudo",
  "scuna",
  "scune",
  "scuoi",
  "scura",
  "scure",
  "scuri",
  "scuro",
  "scusa",
  "scuse",
  "scusi",
  "scuso",
  "scuti",
  "scuto",
  "sdama",
  "sdami",
  "sdamo",
  "sdara",
  "sdare",
  "sdaro",
  "sdata",
  "sdate",
  "sdati",
  "sdato",
  "sdava",
  "sdavi",
  "sdavo",
  "sdazi",
  "sdire",
  "sdoga",
  "sdogo",
  "sdora",
  "sdori",
  "sdoro",
  "sdrai",
  "sduri",
  "seada",
  "seade",
  "secai",
  "secca",
  "secce",
  "secco",
  "sechi",
  "sedai",
  "sedei",
  "sedia",
  "sedie",
  "sedio",
  "segai",
  "segga",
  "segge",
  "seggi",
  "seggo",
  "seghe",
  "seghi",
  "segna",
  "segni",
  "segno",
  "segua",
  "segue",
  "segui",
  "seguo",
  "selce",
  "selci",
  "sella",
  "selle",
  "selli",
  "sello",
  "seltz",
  "selva",
  "selve",
  "semai",
  "sembi",
  "semel",
  "sendo",
  "senio",
  "senna",
  "senne",
  "senni",
  "senno",
  "sensi",
  "senso",
  "senta",
  "sente",
  "senti",
  "sento",
  "senza",
  "sepia",
  "sepie",
  "sepoy",
  "seppe",
  "seppi",
  "sepsi",
  "serba",
  "serbe",
  "serbi",
  "serbo",
  "serei",
  "serge",
  "seria",
  "serie",
  "serio",
  "serir",
  "sermi",
  "sermo",
  "serpa",
  "serpe",
  "serpi",
  "serpo",
  "serra",
  "serre",
  "serri",
  "serro",
  "serti",
  "serto",
  "serva",
  "serve",
  "servi",
  "servo",
  "sesia",
  "sesie",
  "sessa",
  "sesse",
  "sessi",
  "sesso",
  "sesta",
  "seste",
  "sesti",
  "sesto",
  "setta",
  "sette",
  "setti",
  "setto",
  "setup",
  "sevra",
  "sevre",
  "sevri",
  "sevro",
  "sezza",
  "sezze",
  "sezzi",
  "sezzo",
  "sfama",
  "sfami",
  "sfamo",
  "sfara",
  "sfare",
  "sfaro",
  "sfasa",
  "sfasi",
  "sfaso",
  "sfata",
  "sfate",
  "sfati",
  "sfato",
  "sfece",
  "sfeci",
  "sfera",
  "sfere",
  "sferi",
  "sfida",
  "sfide",
  "sfidi",
  "sfido",
  "sfiga",
  "sfila",
  "sfili",
  "sfilo",
  "sfina",
  "sfini",
  "sfino",
  "sfizi",
  "sfoca",
  "sfoci",
  "sfoco",
  "sfoga",
  "sfogo",
  "sfora",
  "sfori",
  "sforo",
  "sfuma",
  "sfumi",
  "sfumo",
  "sfuri",
  "sfusa",
  "sfuse",
  "sfusi",
  "sfuso",
  "sgama",
  "sgami",
  "sgamo",
  "sgara",
  "sgari",
  "sgaro",
  "sgasa",
  "sgasi",
  "sgaso",
  "sgela",
  "sgeli",
  "sgelo",
  "sghei",
  "sgheo",
  "sgola",
  "sgoli",
  "sgolo",
  "sgura",
  "sguri",
  "sguro",
  "shake",
  "share",
  "shell",
  "shift",
  "shoah",
  "shock",
  "short",
  "shunt",
  "siamo",
  "siano",
  "siare",
  "siasi",
  "siate",
  "siato",
  "siava",
  "siavi",
  "siavo",
  "sicav",
  "siche",
  "sicli",
  "siclo",
  "sidei",
  "sidri",
  "sidro",
  "sieda",
  "siede",
  "siedi",
  "siedo",
  "sieno",
  "siepa",
  "siepe",
  "siepi",
  "siepo",
  "siera",
  "sieri",
  "siero",
  "siete",
  "sigla",
  "sigle",
  "sigli",
  "siglo",
  "sigma",
  "silei",
  "silfi",
  "silfo",
  "silio",
  "silli",
  "sillo",
  "silos",
  "silvi",
  "simia",
  "simie",
  "simun",
  "sinti",
  "siora",
  "siore",
  "siori",
  "sipai",
  "sirma",
  "sirme",
  "sirte",
  "sirti",
  "sisal",
  "sisma",
  "sismi",
  "sismo",
  "sitai",
  "sitar",
  "sitii",
  "situa",
  "situi",
  "situo",
  "sizio",
  "sizza",
  "sizze",
  "skate",
  "skeet",
  "skiff",
  "skunk",
  "slama",
  "slami",
  "slamo",
  "slang",
  "slash",
  "slava",
  "slave",
  "slavi",
  "slavo",
  "slebi",
  "slebo",
  "slega",
  "slego",
  "slice",
  "slide",
  "sloca",
  "sloga",
  "slogo",
  "sloop",
  "slurp",
  "smack",
  "smaga",
  "smago",
  "small",
  "smani",
  "smash",
  "smela",
  "smeli",
  "smelo",
  "smena",
  "smeni",
  "smeno",
  "smina",
  "smini",
  "smino",
  "smira",
  "smiri",
  "smiro",
  "smise",
  "smisi",
  "smoda",
  "smodi",
  "smodo",
  "smori",
  "smova",
  "smove",
  "smovi",
  "smovo",
  "smura",
  "smuri",
  "smuro",
  "smusa",
  "smusi",
  "smuso",
  "snack",
  "snida",
  "snidi",
  "snido",
  "sniff",
  "snipe",
  "snoda",
  "snodi",
  "snodo",
  "snort",
  "snuda",
  "snudi",
  "snudo",
  "soave",
  "soavi",
  "sobri",
  "socca",
  "socci",
  "socco",
  "socia",
  "socie",
  "socio",
  "sodai",
  "sodii",
  "sodio",
  "soffi",
  "sofia",
  "sofie",
  "soghe",
  "sogli",
  "sogna",
  "sogni",
  "sogno",
  "solai",
  "solca",
  "solco",
  "solda",
  "soldi",
  "soldo",
  "solei",
  "soleo",
  "solfa",
  "solfe",
  "solfi",
  "solfo",
  "solgo",
  "solio",
  "solla",
  "solle",
  "solli",
  "sollo",
  "solta",
  "solte",
  "solti",
  "solto",
  "somma",
  "somme",
  "sommi",
  "sommo",
  "somni",
  "somno",
  "sonai",
  "sonar",
  "sonda",
  "sonde",
  "sondi",
  "sondo",
  "sonii",
  "sonio",
  "sonni",
  "sonno",
  "sopii",
  "sopra",
  "sopri",
  "sopro",
  "sorai",
  "sorba",
  "sorbe",
  "sorbi",
  "sorbo",
  "sorca",
  "sorce",
  "sorci",
  "sorco",
  "sorda",
  "sorde",
  "sordi",
  "sordo",
  "sorga",
  "sorge",
  "sorgi",
  "sorgo",
  "sorra",
  "sorre",
  "sorsa",
  "sorse",
  "sorsi",
  "sorso",
  "sorta",
  "sorte",
  "sorti",
  "sorto",
  "sosia",
  "sosta",
  "soste",
  "sosti",
  "sosto",
  "sotto",
  "sound",
  "sovra",
  "sozia",
  "sozie",
  "sozio",
  "sozza",
  "sozze",
  "sozzi",
  "sozzo",
  "spada",
  "spade",
  "spago",
  "spahi",
  "spaia",
  "spaio",
  "spala",
  "spali",
  "spalo",
  "spana",
  "spani",
  "spano",
  "spara",
  "spari",
  "sparo",
  "spasa",
  "spase",
  "spasi",
  "spaso",
  "spata",
  "spate",
  "spati",
  "spato",
  "spazi",
  "spece",
  "speck",
  "speco",
  "speda",
  "spedi",
  "spedo",
  "spela",
  "speli",
  "spelo",
  "speme",
  "spemi",
  "spene",
  "speni",
  "spepa",
  "spepe",
  "spera",
  "spere",
  "speri",
  "spero",
  "spesa",
  "spese",
  "spesi",
  "speso",
  "spezi",
  "spiai",
  "spica",
  "spico",
  "spiga",
  "spigo",
  "spike",
  "spina",
  "spine",
  "spini",
  "spino",
  "spira",
  "spire",
  "spiri",
  "spiro",
  "split",
  "spola",
  "spole",
  "spone",
  "sponi",
  "spoon",
  "spora",
  "spore",
  "sport",
  "sposa",
  "spose",
  "sposi",
  "sposo",
  "spray",
  "sprue",
  "spula",
  "spuli",
  "spulo",
  "spuma",
  "spume",
  "spumi",
  "spumo",
  "spura",
  "spuri",
  "spuro",
  "sputa",
  "sputi",
  "sputo",
  "squaw",
  "squit",
  "squoi",
  "stadi",
  "staff",
  "stage",
  "staia",
  "staio",
  "stame",
  "stami",
  "stana",
  "stand",
  "stani",
  "stano",
  "stara",
  "stare",
  "stari",
  "staro",
  "start",
  "stasa",
  "stasi",
  "staso",
  "stata",
  "state",
  "stati",
  "stato",
  "stava",
  "stavi",
  "stavo",
  "staza",
  "staze",
  "stazi",
  "stazo",
  "stela",
  "stele",
  "steli",
  "stelo",
  "stent",
  "steri",
  "stero",
  "stesa",
  "stese",
  "stesi",
  "steso",
  "stiai",
  "stibi",
  "stick",
  "stico",
  "stiga",
  "stigi",
  "stigo",
  "stila",
  "stilb",
  "stile",
  "stili",
  "stilo",
  "stima",
  "stime",
  "stimi",
  "stimo",
  "stipa",
  "stipe",
  "stipi",
  "stipo",
  "stira",
  "stiri",
  "stiro",
  "stiva",
  "stive",
  "stivi",
  "stivo",
  "stock",
  "stoga",
  "stogo",
  "stoia",
  "stoie",
  "stola",
  "stole",
  "stoma",
  "stomi",
  "stona",
  "stoni",
  "stono",
  "stori",
  "strae",
  "strai",
  "strap",
  "stria",
  "strie",
  "strii",
  "strio",
  "strip",
  "stuba",
  "stube",
  "stubi",
  "stubo",
  "studi",
  "stufa",
  "stufe",
  "stufi",
  "stufo",
  "stuka",
  "stuoi",
  "stupa",
  "stupi",
  "stura",
  "sture",
  "sturi",
  "sturo",
  "stuta",
  "stuti",
  "stuto",
  "suada",
  "suade",
  "suadi",
  "suado",
  "suari",
  "suasa",
  "suase",
  "suasi",
  "suaso",
  "suave",
  "suavi",
  "subbi",
  "subii",
  "succi",
  "succo",
  "suchi",
  "sucre",
  "sudai",
  "suede",
  "sugai",
  "sugga",
  "sugge",
  "suggi",
  "suggo",
  "sughi",
  "sugli",
  "sugna",
  "sugne",
  "suide",
  "suidi",
  "suina",
  "suine",
  "suini",
  "suino",
  "suite",
  "sulky",
  "sulla",
  "sulle",
  "sullo",
  "summa",
  "summe",
  "summi",
  "summo",
  "sunna",
  "sunti",
  "sunto",
  "suola",
  "suole",
  "suoli",
  "suolo",
  "suona",
  "suoni",
  "suono",
  "suora",
  "suore",
  "super",
  "suppa",
  "suppe",
  "surah",
  "surfa",
  "surfi",
  "surfo",
  "surga",
  "surge",
  "surgi",
  "surgo",
  "surra",
  "surre",
  "surta",
  "surte",
  "surti",
  "surto",
  "sushi",
  "sussi",
  "susta",
  "suste",
  "sutra",
  "sutto",
  "suvvi",
  "suzza",
  "suzzi",
  "suzzo",
  "svaga",
  "svago",
  "svani",
  "svari",
  "svasa",
  "svasi",
  "svaso",
  "svela",
  "sveli",
  "svelo",
  "svena",
  "sveni",
  "sveno",
  "sveva",
  "sveve",
  "svevi",
  "svevo",
  "sviai",
  "svili",
  "svina",
  "svini",
  "svino",
  "svisa",
  "svisi",
  "sviso",
  "svita",
  "sviti",
  "svito",
  "svizi",
  "svola",
  "svoli",
  "svolo",
  "svota",
  "svoti",
  "svoto",
  "svuoi",
  "swing",
  "tabla",
  "tacan",
  "tacca",
  "tacce",
  "tacci",
  "tacco",
  "tacet",
  "taffe",
  "taffi",
  "tagal",
  "tagli",
  "taide",
  "taidi",
  "taiga",
  "taiko",
  "talco",
  "talea",
  "taled",
  "talee",
  "talli",
  "tallo",
  "talpa",
  "talpe",
  "tamia",
  "tamil",
  "tanca",
  "tanfi",
  "tanfo",
  "tanga",
  "tange",
  "tangi",
  "tango",
  "tanka",
  "tanna",
  "tanni",
  "tanno",
  "tanta",
  "tante",
  "tanti",
  "tanto",
  "tapas",
  "tappa",
  "tappe",
  "tappi",
  "tappo",
  "tapum",
  "taraa",
  "tarai",
  "tarda",
  "tarde",
  "tardi",
  "tardo",
  "targa",
  "targo",
  "tarla",
  "tarli",
  "tarlo",
  "tarma",
  "tarme",
  "tarmi",
  "tarmo",
  "tarpa",
  "tarpi",
  "tarpo",
  "tarsi",
  "tarso",
  "tasca",
  "taser",
  "tassa",
  "tasse",
  "tassi",
  "tasso",
  "tasta",
  "taste",
  "tasti",
  "tasto",
  "tataa",
  "tatti",
  "tatto",
  "tatua",
  "tatui",
  "tatuo",
  "tauri",
  "tauro",
  "taxis",
  "tazza",
  "tazze",
  "tebea",
  "tebee",
  "tebei",
  "tebeo",
  "tecca",
  "teche",
  "tecne",
  "tecno",
  "tedia",
  "tedii",
  "tedio",
  "teghe",
  "tegna",
  "tegni",
  "tegno",
  "teina",
  "teine",
  "telai",
  "telex",
  "temei",
  "tempa",
  "tempe",
  "tempi",
  "tempo",
  "tenar",
  "tenda",
  "tende",
  "tendi",
  "tendo",
  "tenei",
  "tenga",
  "tengo",
  "tenia",
  "tenie",
  "tenne",
  "tenni",
  "tenno",
  "tenor",
  "tensa",
  "tense",
  "tenta",
  "tenti",
  "tento",
  "tenue",
  "tenui",
  "tenza",
  "tenze",
  "tepee",
  "teppa",
  "teppe",
  "terbi",
  "terga",
  "terge",
  "tergi",
  "tergo",
  "terme",
  "termo",
  "terna",
  "terne",
  "terni",
  "terno",
  "terra",
  "terre",
  "terri",
  "terro",
  "tersa",
  "terse",
  "tersi",
  "terso",
  "terza",
  "terze",
  "terzi",
  "terzo",
  "tesai",
  "tesla",
  "tessa",
  "tesse",
  "tessi",
  "tesso",
  "testa",
  "teste",
  "testi",
  "testo",
  "tetra",
  "tetre",
  "tetri",
  "tetro",
  "tetta",
  "tette",
  "tetti",
  "tetto",
  "theta",
  "tiade",
  "tiadi",
  "tiara",
  "tiare",
  "tiasi",
  "tiaso",
  "tibet",
  "tibia",
  "tibie",
  "tiene",
  "tieni",
  "tiera",
  "tiere",
  "tifai",
  "tiggi",
  "tight",
  "tigli",
  "tigna",
  "tigne",
  "tigni",
  "tigno",
  "tigra",
  "tigre",
  "tigri",
  "tigro",
  "tilde",
  "tildi",
  "tilla",
  "tille",
  "tilli",
  "tillo",
  "timer",
  "timpa",
  "timpe",
  "tinca",
  "tinga",
  "tinge",
  "tingi",
  "tingo",
  "tinni",
  "tinse",
  "tinsi",
  "tinta",
  "tinte",
  "tinti",
  "tinto",
  "tirai",
  "tirsi",
  "tirso",
  "tivvu",
  "tizia",
  "tizie",
  "tizio",
  "tizzi",
  "tizzo",
  "tmesi",
  "toast",
  "tocai",
  "tocca",
  "tocci",
  "tocco",
  "tochi",
  "toddy",
  "toghe",
  "toghi",
  "togli",
  "tokaj",
  "tolda",
  "tolde",
  "tolga",
  "tolgo",
  "tolla",
  "tolle",
  "tolli",
  "tollo",
  "tolse",
  "tolsi",
  "tolta",
  "tolte",
  "tolti",
  "tolto",
  "tomai",
  "tomba",
  "tombe",
  "tonai",
  "tonda",
  "tonde",
  "tondi",
  "tondo",
  "toner",
  "tonfa",
  "tonfi",
  "tonfo",
  "tonne",
  "tonni",
  "tonno",
  "tonta",
  "tonte",
  "tonti",
  "tonto",
  "topai",
  "topos",
  "toppa",
  "toppe",
  "toppi",
  "toppo",
  "toque",
  "torah",
  "torba",
  "torbe",
  "torbi",
  "torbo",
  "torca",
  "torce",
  "torci",
  "torco",
  "tordi",
  "tordo",
  "torea",
  "torei",
  "toreo",
  "torio",
  "torli",
  "torlo",
  "torma",
  "torme",
  "torna",
  "torni",
  "torno",
  "toron",
  "torpa",
  "torpe",
  "torpi",
  "torpo",
  "torre",
  "torri",
  "torro",
  "torse",
  "torsi",
  "torso",
  "torta",
  "torte",
  "torti",
  "torto",
  "torva",
  "torve",
  "torvi",
  "torvo",
  "tosai",
  "tosca",
  "tosco",
  "tossa",
  "tosse",
  "tossi",
  "tosso",
  "tosta",
  "toste",
  "tosti",
  "tosto",
  "totem",
  "totip",
  "toufu",
  "tower",
  "tozza",
  "tozze",
  "tozzi",
  "tozzo",
  "trabe",
  "trabi",
  "trace",
  "traci",
  "tradi",
  "trago",
  "traii",
  "trama",
  "trame",
  "trami",
  "tramo",
  "tramp",
  "trana",
  "trani",
  "trano",
  "trans",
  "trapa",
  "trape",
  "traps",
  "trash",
  "trasi",
  "trave",
  "travi",
  "trawl",
  "trema",
  "tremi",
  "tremo",
  "trend",
  "treni",
  "treno",
  "treti",
  "treto",
  "trevi",
  "trevo",
  "triai",
  "trial",
  "trias",
  "tribo",
  "tribu",
  "triga",
  "trina",
  "trine",
  "trini",
  "trino",
  "trita",
  "trite",
  "triti",
  "trito",
  "trivi",
  "trizi",
  "troco",
  "troia",
  "troie",
  "troio",
  "troll",
  "trona",
  "troni",
  "trono",
  "tropi",
  "tropo",
  "trota",
  "trote",
  "trova",
  "trovi",
  "trovo",
  "truca",
  "truce",
  "truci",
  "truck",
  "truka",
  "truke",
  "trumo",
  "trust",
  "truth",
  "tubai",
  "tucul",
  "tufai",
  "tuffa",
  "tuffi",
  "tuffo",
  "tughe",
  "tuina",
  "tulii",
  "tulio",
  "tulle",
  "tuner",
  "tuona",
  "tuoni",
  "tuono",
  "tupla",
  "tuple",
  "tuppe",
  "turai",
  "turba",
  "turbe",
  "turbi",
  "turbo",
  "turca",
  "turco",
  "turfa",
  "turfe",
  "turga",
  "turge",
  "turgi",
  "turgo",
  "turma",
  "turme",
  "turna",
  "turni",
  "turno",
  "turpa",
  "turpe",
  "turpi",
  "turpo",
  "tusca",
  "tusci",
  "tusco",
  "tutor",
  "tutsi",
  "tutta",
  "tutte",
  "tutti",
  "tutto",
  "tuyau",
  "tuzia",
  "tuzie",
  "tweed",
  "twill",
  "twist",
  "ubbia",
  "ubbie",
  "ubera",
  "ubere",
  "uberi",
  "ubero",
  "ubica",
  "ubico",
  "ubidi",
  "ubini",
  "ubino",
  "ucase",
  "uccia",
  "uccio",
  "udira",
  "udire",
  "udiro",
  "udita",
  "udite",
  "uditi",
  "udito",
  "udiva",
  "udivi",
  "udivo",
  "udrai",
  "udrei",
  "ufici",
  "ufizi",
  "uggia",
  "uggii",
  "ugola",
  "ugole",
  "ukase",
  "ulama",
  "ulani",
  "ulano",
  "ulema",
  "ulimi",
  "ulire",
  "ulite",
  "uliti",
  "uliva",
  "ulive",
  "ulivi",
  "ulivo",
  "ulola",
  "uloli",
  "ulolo",
  "ultra",
  "ulula",
  "ulule",
  "ululi",
  "ululo",
  "umami",
  "umana",
  "umane",
  "umani",
  "umano",
  "umata",
  "umate",
  "umati",
  "umato",
  "umbra",
  "umbre",
  "umbri",
  "umbro",
  "umeri",
  "umero",
  "umica",
  "umici",
  "umico",
  "umida",
  "umide",
  "umidi",
  "umido",
  "umile",
  "umili",
  "umore",
  "umori",
  "unari",
  "uncia",
  "under",
  "ungue",
  "unica",
  "unici",
  "unico",
  "unira",
  "unire",
  "uniro",
  "unita",
  "unite",
  "uniti",
  "unito",
  "univa",
  "univi",
  "univo",
  "unqua",
  "unque",
  "untai",
  "upupa",
  "upupe",
  "uraci",
  "uraco",
  "urani",
  "urano",
  "urati",
  "urato",
  "urica",
  "urici",
  "urico",
  "urina",
  "urine",
  "urini",
  "urino",
  "urlai",
  "urlii",
  "urlio",
  "urtai",
  "urubu",
  "usano",
  "usare",
  "usata",
  "usate",
  "usati",
  "usato",
  "usava",
  "usavi",
  "usavo",
  "uscia",
  "uscii",
  "uscio",
  "usera",
  "usero",
  "usino",
  "usmai",
  "usola",
  "usoli",
  "usolo",
  "usura",
  "usure",
  "usuri",
  "usuro",
  "uteri",
  "utero",
  "utile",
  "utili",
  "utima",
  "utime",
  "utimi",
  "utimo",
  "uvala",
  "uvale",
  "uvero",
  "uvina",
  "uvine",
  "uvola",
  "uvole",
  "uvosa",
  "uvose",
  "uvosi",
  "uvoso",
  "uvula",
  "uvule",
  "uxori",
  "vabbe",
  "vabbo",
  "vacai",
  "vacca",
  "vacci",
  "vachi",
  "vacua",
  "vacue",
  "vacui",
  "vacuo",
  "vadai",
  "vafer",
  "vaffa",
  "vafra",
  "vafre",
  "vafri",
  "vafro",
  "vagai",
  "vaghe",
  "vaghi",
  "vagii",
  "vagli",
  "vaiai",
  "valca",
  "valco",
  "valga",
  "valgo",
  "valla",
  "valle",
  "valli",
  "vallo",
  "valsa",
  "valse",
  "valsi",
  "valso",
  "valva",
  "valve",
  "vampa",
  "vampe",
  "vampi",
  "vampo",
  "vanai",
  "vanea",
  "vanei",
  "vaneo",
  "vanga",
  "vango",
  "vania",
  "vanie",
  "vanii",
  "vanio",
  "vanni",
  "vanno",
  "vanta",
  "vanti",
  "vanto",
  "vappa",
  "vappe",
  "varai",
  "varca",
  "varco",
  "varea",
  "varee",
  "varia",
  "varie",
  "vario",
  "varra",
  "varro",
  "varva",
  "varve",
  "vasai",
  "vasca",
  "vasel",
  "vasta",
  "vaste",
  "vasti",
  "vasto",
  "vatti",
  "vauda",
  "vaude",
  "vecce",
  "veddi",
  "vedei",
  "vedia",
  "vedra",
  "vedro",
  "vegan",
  "vegga",
  "veggi",
  "veggo",
  "vegli",
  "velai",
  "velga",
  "velgo",
  "velia",
  "velie",
  "vella",
  "velle",
  "velli",
  "vello",
  "velma",
  "velme",
  "velse",
  "velsi",
  "velta",
  "velte",
  "velti",
  "velto",
  "venai",
  "venda",
  "vende",
  "vendi",
  "vendo",
  "venga",
  "vengi",
  "vengo",
  "venia",
  "venie",
  "venne",
  "venni",
  "venta",
  "venti",
  "vento",
  "vepre",
  "vepri",
  "verbi",
  "verbo",
  "verde",
  "verdi",
  "verga",
  "verge",
  "vergi",
  "vergo",
  "verla",
  "verme",
  "vermi",
  "vermo",
  "verna",
  "verni",
  "verno",
  "verra",
  "verre",
  "verri",
  "verro",
  "versa",
  "verse",
  "versi",
  "verso",
  "verta",
  "verte",
  "verti",
  "verto",
  "vertu",
  "verve",
  "verza",
  "verze",
  "verzi",
  "vesce",
  "vesco",
  "vespa",
  "vespe",
  "vessa",
  "vessi",
  "vesso",
  "vesta",
  "veste",
  "vesti",
  "vesto",
  "vetri",
  "vetro",
  "vetta",
  "vette",
  "vezzi",
  "vezzo",
  "viado",
  "viale",
  "viali",
  "viari",
  "vibra",
  "vibri",
  "vibro",
  "vichi",
  "vidde",
  "viddi",
  "video",
  "viene",
  "vieni",
  "viera",
  "viere",
  "vieri",
  "viero",
  "vieta",
  "viete",
  "vieti",
  "vieto",
  "vigli",
  "vigna",
  "vigne",
  "vilai",
  "vilia",
  "vilie",
  "vilii",
  "vilio",
  "villa",
  "ville",
  "villi",
  "villo",
  "vilta",
  "vinai",
  "vinca",
  "vince",
  "vinci",
  "vinco",
  "vinea",
  "vinee",
  "vinse",
  "vinsi",
  "vinta",
  "vinte",
  "vinti",
  "vinto",
  "viola",
  "viole",
  "violi",
  "violo",
  "vipla",
  "viple",
  "vipra",
  "vipre",
  "virai",
  "virga",
  "virgo",
  "virtu",
  "virus",
  "visai",
  "visco",
  "visir",
  "vispa",
  "vispe",
  "vispi",
  "vispo",
  "vissa",
  "visse",
  "vissi",
  "visso",
  "vista",
  "viste",
  "visti",
  "visto",
  "visus",
  "vitai",
  "viton",
  "vitta",
  "vitte",
  "vitti",
  "vitto",
  "vivai",
  "vivra",
  "vivro",
  "vizia",
  "vizio",
  "vizza",
  "vizze",
  "vizzi",
  "vizzo",
  "vocai",
  "vochi",
  "vocia",
  "vocii",
  "vocio",
  "vodka",
  "vodke",
  "vogai",
  "voghe",
  "voghi",
  "vogli",
  "voila",
  "voile",
  "volai",
  "volan",
  "volee",
  "volga",
  "volge",
  "volgi",
  "volgo",
  "volle",
  "volli",
  "volpa",
  "volpe",
  "volpi",
  "volpo",
  "volse",
  "volsi",
  "volta",
  "volte",
  "volti",
  "volto",
  "volva",
  "volve",
  "vomii",
  "vonno",
  "vorai",
  "vorra",
  "vorro",
  "vosco",
  "votai",
  "votii",
  "vroom",
  "vulgo",
  "vulva",
  "vulve",
  "vuole",
  "vuopi",
  "vuopo",
  "vuota",
  "vuote",
  "vuoti",
  "vuoto",
  "vuova",
  "vuovo",
  "wafer",
  "water",
  "watsu",
  "weber",
  "whist",
  "wicca",
  "widia",
  "winch",
  "xenia",
  "xenie",
  "xeres",
  "yacht",
  "yogah",
  "yogin",
  "yttri",
  "yucca",
  "yuppy",
  "yurta",
  "yurty",
  "zabri",
  "zabro",
  "zaffa",
  "zaffe",
  "zaffi",
  "zaffo",
  "zafra",
  "zafre",
  "zaina",
  "zaine",
  "zaini",
  "zaino",
  "zaire",
  "zambo",
  "zampa",
  "zampe",
  "zampi",
  "zampo",
  "zanai",
  "zanca",
  "zanna",
  "zanne",
  "zanni",
  "zanno",
  "zanza",
  "zappa",
  "zappe",
  "zappi",
  "zappo",
  "zarra",
  "zarre",
  "zarri",
  "zarro",
  "zatta",
  "zatte",
  "zebra",
  "zebre",
  "zecca",
  "zecco",
  "zefir",
  "zelai",
  "zelig",
  "zendi",
  "zendo",
  "zenit",
  "zeppa",
  "zeppe",
  "zeppi",
  "zeppo",
  "zerbi",
  "zerbo",
  "ziani",
  "ziano",
  "ziffe",
  "zifra",
  "zifre",
  "zigai",
  "zighi",
  "zilla",
  "zilli",
  "zillo",
  "zimar",
  "zinca",
  "zinco",
  "zinia",
  "zinie",
  "zinna",
  "zinne",
  "zinni",
  "zinno",
  "zippa",
  "zippi",
  "zippo",
  "zirbi",
  "zirbo",
  "zirla",
  "zirli",
  "zirlo",
  "zitta",
  "zitte",
  "zitti",
  "zitto",
  "zizza",
  "zizze",
  "zloty",
  "zocco",
  "zoili",
  "zoilo",
  "zolfa",
  "zolfi",
  "zolfo",
  "zolla",
  "zolle",
  "zolli",
  "zollo",
  "zomba",
  "zombi",
  "zombo",
  "zompa",
  "zompi",
  "zompo",
  "zonai",
  "zonzo",
  "zooma",
  "zoomi",
  "zoomo",
  "zoppa",
  "zoppe",
  "zoppi",
  "zoppo",
  "zozza",
  "zozze",
  "zozzo",
  "zuava",
  "zuave",
  "zuavi",
  "zuavo",
  "zucca",
  "zuffa",
  "zuffe",
  "zughi",
  "zumai",
  "zumba",
  "zumbe",
  "zuppa",
  "zuppe",
  "zuppi",
  "zuppo",
  "zurla",
  "zurli",
  "zurlo",
  "zurri",
  "zurro",
];
