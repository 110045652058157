/* eslint-disable react/jsx-no-target-blank */
export const AboutModal = () => {
  return (
    <p className="flex justify-center text-sm text-gray-700 mt-4 m-2 dark:text-gray-300">
      <a href="https://play.google.com/store/apps/details?id=com.jovid.jordle" target="_blank" className="underline font-light focus:outline-none dark:text-white"  >Google Play Store</a>
      &ensp;-&ensp;
      <a href="https://jordle.jovid.win" target="_blank" className="underline font-light focus:outline-none dark:text-white"  >Web Browser</a>
  </p>
  );
}

