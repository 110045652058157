

export const TEXTS_FR = {
  GAME_TITLE: 'Jordle 🇫🇷',
  WIN_MESSAGES: ['Excellent travail !', 'Génial', 'Bravo!', "Incroyable!", "Spectaculaire!", "Incroyable!", "Extraordinaire!"],
  GAME_COPIED_MESSAGE: 'Jeu copié dans le presse-papiers',
  ABOUT_GAME_MESSAGE: 'À propos de ce jeu',
  NOT_ENOUGH_LETTERS_MESSAGE: 'Pas assez de lettres',
  WORD_NOT_FOUND_MESSAGE: 'Mot introuvable',
  HARD_MODE_DESC: "Les lettres devinées doivent apparaître dans le mot suivant",
  HARD_MODE_ALERT_MESSAGE: "mode difficile activé!",
  CORRECT_WORD_MESSAGE: (solution: string) => `Le mot était ${solution}`,
  WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Doit utiliser ${guess} en position ${position}`,
  NOT_CONTAINED_MESSAGE: (letter: string) => `Supposition doit contenir ${letter}`,
  URL_DEFINITION: (word: string) => `https://www.le-dictionnaire.com/definition/${word}`,
  ENTER_TEXT: 'Enter',
  DELETE_TEXT: 'Supprimer',
  STATISTICS_TITLE: 'Statistiques',
  GUESS_DISTRIBUTION_TEXT: 'Répartition devinée',
  NEW_WORD_TEXT: 'Nouveau mot dans ',
  SHARE_TEXT: 'Partager',
  TOTAL_TRIES_TEXT: 'Nombre total d\'essais',
  SUCCESS_RATE_TEXT: 'Taux de réussite',
  CURRENT_STREAK_TEXT: 'Série actuelle',
  BEST_STREAK_TEXT: 'Meilleure séquence',
  INFO_TITLE: "Comment jouer",
  INFO_INTRO: "Devinez le mot en 6 essais. Après chaque supposition, la couleur des tuiles changera pour montrer à quel point votre supposition était proche du mot.",
  INFO_CORRECT: "La lettre 'W' est dans le mot et au bon endroit.",
  INFO_PRESENT: "La lettre 'L' est dans le mot mais au mauvais endroit.",
  INFO_ABSENT: "La lettre 'U' n'est dans le mot à aucun endroit.",
  PLAY_MORE: "Rejouer!"
};


