

export const Settings = {
  DEFAULT_LANG: 'en',
  MAX_WORD_LENGTH: 5,
  MAX_CHALLENGES: 6,
  ALERT_TIME_MS: 2000,
  REVEAL_TIME_MS: 350,
  get GAME_LOST_INFO_DELAY() {
    return (Settings.MAX_WORD_LENGTH + 1) * Settings.REVEAL_TIME_MS;
  }
};



