

export const TEXTS_CAT = {
  GAME_TITLE: 'Jordle ',
  WIN_MESSAGES: ['Ben Fet!', 'Impressionant!', 'Bona feina!', "Genial!", "Sombrós!", "Espectacular!", "Increïble!", "Al·lucinant!", "Extraordinari!"],
  GAME_COPIED_MESSAGE: 'Joc copiat al porta-retalls',
  ABOUT_GAME_MESSAGE: 'Quant a aquest joc',
  NOT_ENOUGH_LETTERS_MESSAGE: 'No hi ha prou lletres',
  WORD_NOT_FOUND_MESSAGE: 'Paraula no trobada',
  HARD_MODE_DESC: "Les lletres endevinades han d'aparèixer a la paraula següent",
  HARD_MODE_ALERT_MESSAGE: "Mode dificil habilitat!",
  CORRECT_WORD_MESSAGE: (solution: string) => `La paraula era ${solution}`,
  WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Has d'usar ${guess} en aquesta posició: ${position}`,
  NOT_CONTAINED_MESSAGE: (letter: string) => `Ha de contenir ${letter}`,
  URL_DEFINITION: (word: string) => `https://www.diccionaris.cat/diccionari/catala/${word}`,
  ENTER_TEXT: 'Enviar',
  DELETE_TEXT: 'Borrar',
  STATISTICS_TITLE: 'Estadístiques',
  GUESS_DISTRIBUTION_TEXT: 'Distribució de conjectures',
  NEW_WORD_TEXT: 'Nueva palabra en ',
  SHARE_TEXT: 'Compartir',
  TOTAL_TRIES_TEXT: 'Total intents',
  SUCCESS_RATE_TEXT: 'Encerts',
  CURRENT_STREAK_TEXT: 'Ratxa actual',
  BEST_STREAK_TEXT: 'Millor ratxa',
  INFO_TITLE: "Com jugar",
  INFO_INTRO: "Endevina la paraula en 6 intents. Després de cada suposició, el color de les fitxes canviarà per mostrar què tan a prop va estar la suposició de la paraula.",
  INFO_CORRECT: "La lletra 'W' és a la paraula i al lloc correcte.",
  INFO_PRESENT: "La lletra 'L' està a la paraula però al lloc equivocat.",
  INFO_ABSENT: "La lletra 'U' no és a la paraula enlloc.",
  PLAY_MORE: "Juga un altre cop!"
};


