
import {
  InformationCircleIcon,
  ChartBarIcon,
  RefreshIcon,
  CogIcon,
} from '@heroicons/react/outline'

import './Home.css';

import { useEffect, useState } from 'react';

import { AlertContainer } from '../components/alerts/AlertContainer';
import { useAlert } from '../context/AlertContext';
import { Grid } from '../components/grid/Grid';
import { Keyboard } from '../components/keyboard/Keyboard';
import { AboutModal } from '../components/modals/AboutModal';
import { InfoModal } from '../components/modals/InfoModal';
import { StatsModal } from '../components/modals/StatsModal'

import { Text } from "../i18n/LangHandler";
import { Settings } from '../constants/settings'

import { isWordInWordList, isWinningWord, WordPlaying, findFirstUnusedReveal } from '../lib/words';
import { addStatsForCompletedGame, loadStats } from '../lib/stats';
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from '../lib/localStorage'

import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { SettingsModal } from '../components/modals/SettingsModal';

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

const ALERT_TIME_MS = 2000;

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

const Home: React.FC = () => {
  const prefersDarkMode = true;
  //   window.matchMedia(
  //   '(prefers-color-scheme: dark)'
  // ).matches;

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('');
  const [isGameWon, setIsGameWon] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false);
  const [lang, setLangMode] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : Settings.DEFAULT_LANG);
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
        ? true
        : false //false
  );
  const [isRevealing, setIsRevealing] = useState(false);
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage();
    if (loaded?.solution !== WordPlaying.text) {
      return [];
    }
    const gameWasWon = loaded.guesses.includes(WordPlaying.text);
    if (gameWasWon) {
      setIsGameWon(true);
    }
    if (loaded.guesses.length === Settings.MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true);
      showErrorAlert(Text.CORRECT_WORD_MESSAGE(WordPlaying.text), {
        persist: false,
      })
    }
    return loaded.guesses;
  });

  const [stats, setStats] = useState(() => loadStats());

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  );

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const playOtherWord = () => {
    showSuccessAlert(Text.PLAY_MORE, {
      onClose: () => window.location.assign('/')
    });
  };

  const handleLangMode = (lang: string) => {
    setLangMode(lang);
    localStorage.setItem('lang', lang ? lang : "male");
    window.location.assign('/');
  };

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark);
    localStorage.setItem('theme', isDark ? 'dark' : 'light');
  };

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard);
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal');
    } else {
      showErrorAlert(Text.HARD_MODE_ALERT_MESSAGE);
    }
  }

  useEffect(() => {
    const solution: string = WordPlaying.text;
    saveGameStateToLocalStorage({ guesses, solution });
  }, [guesses]);

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        Text.WIN_MESSAGES[Math.floor(Math.random() * Text.WIN_MESSAGES.length)];
      const delayMs = Settings.REVEAL_TIME_MS;

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      });
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true);
      }, Settings.ALERT_TIME_MS);
    }
  }, [isGameWon, isGameLost, showSuccessAlert]);

  const onChar = (value: string) => {
    if (
      currentGuess.length < Settings.MAX_WORD_LENGTH &&
      guesses.length < Settings.MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`);
    }
  };

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1));
  };

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return;
    }
    if (!(currentGuess.length === Settings.MAX_WORD_LENGTH)) {
      showErrorAlert(Text.NOT_ENOUGH_LETTERS_MESSAGE);
      setCurrentRowClass('jiggle');
      return setTimeout(() => {
        setCurrentRowClass('');
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess)) {
      showErrorAlert(Text.WORD_NOT_FOUND_MESSAGE);
      setCurrentRowClass('jiggle');
      return setTimeout(() => {
        setCurrentRowClass('');
      }, ALERT_TIME_MS)
    }


    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses);
      if (firstMissingReveal) {
        showErrorAlert(firstMissingReveal);
        setCurrentRowClass('jiggle');
        return setTimeout(() => {
          setCurrentRowClass('');
        }, ALERT_TIME_MS);
      }
    }

    setIsRevealing(true);
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false);
    }, Settings.REVEAL_TIME_MS * Settings.MAX_WORD_LENGTH);

    const winningWord = isWinningWord(WordPlaying, currentGuess);

    if (
      currentGuess.length === Settings.MAX_WORD_LENGTH &&
      guesses.length < Settings.MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess('');

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length));
        return setIsGameWon(true);
      }

      if (guesses.length === Settings.MAX_CHALLENGES - 1) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1));
        setIsGameLost(true);
        showErrorAlert(Text.CORRECT_WORD_MESSAGE(WordPlaying.text), {
          persist: false,
          delayMs: Settings.REVEAL_TIME_MS * Settings.MAX_WORD_LENGTH + 1,
        })
      }
    }
  };

  return (

    <div className="pt-1 pb-4 max-w-7xl mx-auto sm:px-6 lg:px-8 pr-1">
      <div className="flex mx-auto max-w-2xl w-auto items-center mb-4 mt-1 space-x-1.5  ">
        <h1 className="text-xl ml-2.5 grow font-bold dark:text-white">
          {Text.GAME_TITLE}
        </h1>
        <IonItem className='rounded-md' lines="none">
            <IonSelect className='' value={lang ? lang : "en"} interface="popover" onIonChange={e => handleLangMode(e.detail.value)}>
            <IonSelectOption value="es">Español</IonSelectOption>
            <IonSelectOption value="cat">Catalá</IonSelectOption>
            <IonSelectOption value="eusk">Euskara</IonSelectOption>
            <IonSelectOption value="en">English</IonSelectOption>
            <IonSelectOption value="it">Italiano</IonSelectOption>
            <IonSelectOption value="fr">Français</IonSelectOption>
            <IonSelectOption value="de">Deutsch</IonSelectOption>
          </IonSelect>
          </IonItem>
        <RefreshIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={playOtherWord}
        />

        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
        <CogIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsSettingsModalOpen(true)}
        />
      </div>
      <Grid
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
      />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => showSuccessAlert(Text.GAME_COPIED_MESSAGE)}
        isHardMode={isHardMode}
        playing={WordPlaying.text}
      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
        isHardMode={isHardMode}
        handleHardMode={handleHardMode}
        isDarkMode={isDarkMode}
        handleDarkMode={handleDarkMode}
        isHighContrastMode={false} //{isHighContrastMode}
        handleHighContrastMode={() => { }} // {handleHighContrastMode}
      />

      <AboutModal />
      <AlertContainer />
    </div>
  )
  };

export default Home

