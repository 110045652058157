import {
  ShareIcon,
  RefreshIcon,
  InformationCircleIcon,
  ClipboardCopyIcon
} from '@heroicons/react/outline';
import { StatBar } from '../stats/StatBar';
import { Histogram } from '../stats/Histogram';
import { GameStats } from '../../lib/localStorage';
import { shareStatus } from '../../lib/share';
import { BaseModal } from './BaseModal';
import { Text } from '../../i18n/LangHandler';
import { IonButton } from '@ionic/react';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];
  gameStats: GameStats;
  isGameLost: boolean;
  isGameWon: boolean;
  handleShare: () => void;
  isHardMode: boolean;
  playing: string;
};

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
  isHardMode,
  playing,
}: Props) => {
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={Text.STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
      </BaseModal>
    );
  }

  const playOtherWord = () => {
    window.location.assign('/');
  };

  const openRae = () => {
    window.open(Text.URL_DEFINITION(playing), '_blank');
  };

  const playingColor = isGameWon ? "bg-blue-500" : "bg-red-500";
  const playingBox = `flex mx-auto max-w-2xl w-auto items-center justify-center space-x-4 p-2 rounded-md text-center font-bold ${playingColor} dark:text-white`;

  return (
    <BaseModal
      title={Text.STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      {(isGameLost || isGameWon) && (
        <div className={playingBox}>
          <p>Jordle:  { playing}</p>
          <InformationCircleIcon className='h-6 w-6 mr-2 cursor-pointer dark:stroke-white' onClick={openRae}/>
        </div>
      )}

      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {Text.GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram gameStats={gameStats} />
      { (isGameLost || isGameWon) && (
        <IonButton color="medium" className='rounded-md text-white dark:text-white' onClick={() => {
          shareStatus(guesses, isGameLost, isHardMode);
          handleShare();
        }}>
          <ShareIcon className="h-5 w-5 mr-1 cursor-pointer dark:stroke-white" />
          <b>{Text.SHARE_TEXT}</b>
        </IonButton>
      )}

      <IonButton color="primary" className='rounded-md text-white dark:text-white' onClick={playOtherWord}>
        <RefreshIcon className="h-5 w-5 mr-1 cursor-pointer dark:stroke-white" />
        <b>{Text.PLAY_MORE}</b>
      </IonButton>

      <div className="flex justify-center mb-3 mt-4 ">
        <a href="https://www.buymeacoffee.com/jovidstudio" className="focus:outline-none" >
          <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=😀&slug=jovidstudio&button_colour=5F7FFF&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00" />
        </a>
      </div>
    </BaseModal>
  );
};
