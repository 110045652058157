export const VALID_GUESSES_ES = [
  "ababa",
  "abaca",
  "abaco",
  "abada",
  "abadi",
  "abaje",
  "abajo",
  "abano",
  "abara",
  "abasi",
  "abate",
  "abati",
  "abece",
  "abeja",
  "abete",
  "abeto",
  "abiar",
  "abiso",
  "abita",
  "abono",
  "abozo",
  "abril",
  "abrir",
  "absit",
  "abubo",
  "abuje",
  "abuso",
  "acaba",
  "acabe",
  "acabo",
  "acalo",
  "acana",
  "acaro",
  "acaso",
  "acato",
  "acaya",
  "acayo",
  "acebo",
  "aceda",
  "acedo",
  "aceña",
  "acera",
  "acere",
  "acero",
  "aceta",
  "aceto",
  "acezo",
  "achin",
  "achis",
  "acial",
  "aciar",
  "acida",
  "acido",
  "acije",
  "acilo",
  "acimo",
  "acion",
  "aclla",
  "acodo",
  "acoro",
  "acose",
  "acoso",
  "acroe",
  "acroy",
  "actea",
  "actor",
  "acuea",
  "acueo",
  "acula",
  "acure",
  "acuse",
  "acuso",
  "acuta",
  "acuti",
  "acuto",
  "adala",
  "adara",
  "adaza",
  "adega",
  "adeli",
  "adema",
  "ademe",
  "adiar",
  "adios",
  "adiva",
  "adive",
  "adoba",
  "adobe",
  "adobo",
  "adoro",
  "adral",
  "adrar",
  "adtor",
  "aduar",
  "adufa",
  "adufe",
  "aduja",
  "adula",
  "aduro",
  "aerea",
  "aereo",
  "afaca",
  "afate",
  "afear",
  "afiar",
  "afice",
  "afija",
  "afijo",
  "afila",
  "afilo",
  "afine",
  "afino",
  "afona",
  "afono",
  "aforo",
  "afufa",
  "agace",
  "agami",
  "agape",
  "agata",
  "agave",
  "agnus",
  "agora",
  "agosa",
  "agoso",
  "agote",
  "agras",
  "agraz",
  "agria",
  "agrio",
  "agror",
  "aguai",
  "aguar",
  "aguay",
  "aguda",
  "agudo",
  "aguin",
  "aguio",
  "aguja",
  "aguti",
  "ahilo",
  "ahina",
  "ahita",
  "ahito",
  "ahogo",
  "ahora",
  "aillo",
  "ainas",
  "ainde",
  "airar",
  "aireo",
  "airon",
  "ajada",
  "ajado",
  "ajaja",
  "ajear",
  "ajebe",
  "ajena",
  "ajeno",
  "ajera",
  "ajero",
  "ajete",
  "ajipa",
  "ajobo",
  "ajote",
  "ajuar",
  "ajuna",
  "ajuno",
  "alabe",
  "alaco",
  "alada",
  "alado",
  "alafa",
  "alaga",
  "alaju",
  "alala",
  "alalo",
  "alama",
  "alamo",
  "alana",
  "alano",
  "alara",
  "alaui",
  "alazo",
  "albar",
  "albin",
  "albor",
  "album",
  "albur",
  "alcea",
  "alcor",
  "aldea",
  "alear",
  "alece",
  "aleda",
  "aleja",
  "aleli",
  "alelo",
  "alema",
  "alera",
  "alero",
  "aleta",
  "aleto",
  "aleve",
  "alevo",
  "aleya",
  "alezo",
  "alfar",
  "alfil",
  "alfiz",
  "alfoz",
  "algar",
  "algia",
  "algol",
  "algun",
  "alhoz",
  "aliar",
  "alias",
  "alica",
  "alier",
  "alifa",
  "alijo",
  "alima",
  "alimo",
  "aliño",
  "alioj",
  "aliso",
  "aljez",
  "aljor",
  "allen",
  "almea",
  "almez",
  "almud",
  "aloes",
  "aloja",
  "alora",
  "alosa",
  "aloya",
  "alpes",
  "alpez",
  "altar",
  "altea",
  "altor",
  "aluda",
  "aludo",
  "aluen",
  "alula",
  "alveo",
  "alzar",
  "amada",
  "amado",
  "amago",
  "amala",
  "amalo",
  "amaño",
  "amara",
  "amaro",
  "amate",
  "ambar",
  "ambas",
  "ambon",
  "ambos",
  "ameba",
  "amelo",
  "amena",
  "ameno",
  "ameos",
  "amiba",
  "amibo",
  "amida",
  "amiga",
  "amigo",
  "amina",
  "amino",
  "amiri",
  "amito",
  "amole",
  "amomo",
  "ampla",
  "amplo",
  "ampon",
  "amuje",
  "amura",
  "amuso",
  "anabi",
  "anaco",
  "anade",
  "anafe",
  "anamu",
  "anana",
  "anata",
  "ancha",
  "ancho",
  "ancla",
  "ancon",
  "ancua",
  "andar",
  "andas",
  "andel",
  "anden",
  "andon",
  "anear",
  "aneja",
  "anejo",
  "aneto",
  "anexa",
  "anexo",
  "angel",
  "angla",
  "anglo",
  "angor",
  "angra",
  "anima",
  "anime",
  "animo",
  "anion",
  "anito",
  "anjeo",
  "anodo",
  "anona",
  "ansar",
  "ansia",
  "antes",
  "antia",
  "anton",
  "antor",
  "antro",
  "anual",
  "anula",
  "anulo",
  "anura",
  "anuro",
  "añada",
  "añejo",
  "añero",
  "añino",
  "añoja",
  "añojo",
  "añoso",
  "aocar",
  "aojar",
  "aonia",
  "aonio",
  "aorar",
  "aorta",
  "aovar",
  "apaño",
  "apear",
  "apego",
  "apena",
  "apero",
  "apezu",
  "apice",
  "apiri",
  "apito",
  "apnea",
  "apoca",
  "apoda",
  "apodo",
  "apoyo",
  "apres",
  "aptar",
  "apure",
  "apuro",
  "aquea",
  "aquel",
  "aquen",
  "aqueo",
  "arabe",
  "arabi",
  "arabo",
  "arada",
  "arado",
  "arana",
  "araña",
  "araño",
  "arasa",
  "araza",
  "arbol",
  "arbor",
  "arcar",
  "arcaz",
  "arcea",
  "arcen",
  "archa",
  "archi",
  "arcon",
  "ardea",
  "arder",
  "ardid",
  "ardil",
  "ardor",
  "ardua",
  "arduo",
  "areca",
  "arena",
  "arepa",
  "arete",
  "arfar",
  "arfil",
  "argan",
  "argel",
  "argen",
  "argon",
  "argos",
  "argot",
  "argue",
  "arida",
  "arido",
  "aries",
  "arija",
  "arije",
  "arijo",
  "arilo",
  "ariol",
  "arlar",
  "armar",
  "armon",
  "armos",
  "arnes",
  "aroca",
  "aroma",
  "aromo",
  "arpar",
  "arpeo",
  "arpia",
  "arpon",
  "arras",
  "arraz",
  "arreo",
  "arria",
  "arroz",
  "arrui",
  "artal",
  "artar",
  "artos",
  "arula",
  "aruño",
  "arzon",
  "asada",
  "asado",
  "asaro",
  "ascar",
  "ascia",
  "ascio",
  "ascua",
  "asear",
  "asilo",
  "asina",
  "asmar",
  "asnal",
  "asomo",
  "aspar",
  "aspic",
  "aspid",
  "aspro",
  "aster",
  "astil",
  "astre",
  "astri",
  "astro",
  "astur",
  "asuso",
  "atabe",
  "atada",
  "atado",
  "atajo",
  "ataud",
  "atear",
  "ateca",
  "ateje",
  "atica",
  "atico",
  "atino",
  "atlas",
  "atoar",
  "atoba",
  "atole",
  "atomo",
  "atona",
  "atono",
  "atora",
  "atoro",
  "atras",
  "atril",
  "atrio",
  "atroz",
  "atufo",
  "audaz",
  "audio",
  "augur",
  "aullo",
  "aunar",
  "aupar",
  "aurea",
  "aureo",
  "ausol",
  "autan",
  "autor",
  "avara",
  "avaro",
  "avati",
  "avena",
  "aveza",
  "aviar",
  "avica",
  "avida",
  "avido",
  "avion",
  "aviso",
  "avugo",
  "axial",
  "axila",
  "ayaco",
  "ayate",
  "ayear",
  "ayora",
  "ayote",
  "ayuda",
  "ayuga",
  "ayuna",
  "ayuno",
  "ayuso",
  "azada",
  "azala",
  "azaro",
  "azaya",
  "azcon",
  "azeri",
  "azimo",
  "azoar",
  "azoro",
  "azote",
  "aztor",
  "azuda",
  "babel",
  "babeo",
  "babia",
  "bable",
  "babor",
  "bacan",
  "bache",
  "bacia",
  "bacin",
  "bacon",
  "badal",
  "badan",
  "badea",
  "baden",
  "badil",
  "bafle",
  "bagar",
  "bagre",
  "bahai",
  "bahia",
  "baida",
  "baifa",
  "baifo",
  "baila",
  "baile",
  "bajar",
  "bajel",
  "bajez",
  "bajia",
  "bajio",
  "bajon",
  "balaj",
  "balar",
  "balay",
  "balda",
  "balde",
  "baldo",
  "balea",
  "baleo",
  "balin",
  "balon",
  "balsa",
  "balso",
  "balta",
  "balto",
  "bamba",
  "bambu",
  "banal",
  "banas",
  "banca",
  "bance",
  "banco",
  "banda",
  "bando",
  "banir",
  "banjo",
  "bantu",
  "banyo",
  "banzo",
  "bañar",
  "bañil",
  "bañon",
  "baque",
  "barba",
  "barbo",
  "barca",
  "barco",
  "barda",
  "bardo",
  "baria",
  "baril",
  "bario",
  "baron",
  "barra",
  "barre",
  "barro",
  "barza",
  "basal",
  "basar",
  "basca",
  "basco",
  "bascu",
  "basis",
  "basna",
  "basta",
  "baste",
  "basto",
  "batan",
  "batea",
  "batel",
  "bateo",
  "batey",
  "batik",
  "batin",
  "batir",
  "bausa",
  "bauza",
  "bayal",
  "bayon",
  "bayua",
  "bazar",
  "beata",
  "beato",
  "bebda",
  "bebdo",
  "beber",
  "becar",
  "bedel",
  "befar",
  "befre",
  "begum",
  "beige",
  "bejin",
  "belen",
  "belez",
  "belfa",
  "belfo",
  "belga",
  "belio",
  "bella",
  "bello",
  "belua",
  "bemba",
  "bembo",
  "bemol",
  "beoda",
  "beodo",
  "beori",
  "beque",
  "berbi",
  "berma",
  "beron",
  "berra",
  "berro",
  "berta",
  "berza",
  "besar",
  "betel",
  "betun",
  "beuda",
  "beudo",
  "beuna",
  "bevra",
  "bezar",
  "bezon",
  "biaza",
  "bibir",
  "bical",
  "bicha",
  "biche",
  "bicho",
  "bicos",
  "bidma",
  "bidon",
  "biela",
  "bijao",
  "bijol",
  "bilao",
  "bilis",
  "billa",
  "bilma",
  "bimba",
  "binar",
  "bingo",
  "binza",
  "biota",
  "birla",
  "birli",
  "birlo",
  "bisar",
  "bisel",
  "biste",
  "bitar",
  "biter",
  "bitor",
  "bizca",
  "bizco",
  "bizma",
  "bizna",
  "blago",
  "blava",
  "blavo",
  "bleda",
  "bledo",
  "blezo",
  "bloca",
  "blues",
  "blusa",
  "boato",
  "bocal",
  "bocel",
  "bocha",
  "boche",
  "bocho",
  "bocin",
  "bocio",
  "bocon",
  "bocoy",
  "bodon",
  "bofia",
  "bogar",
  "bohio",
  "boina",
  "boira",
  "boîte",
  "bojar",
  "bojeo",
  "bolan",
  "bolar",
  "boldo",
  "boleo",
  "bolin",
  "bolla",
  "bollo",
  "bolon",
  "bolsa",
  "bolso",
  "bomba",
  "bombe",
  "bombo",
  "bonga",
  "bongo",
  "bonzo",
  "boque",
  "boqui",
  "borax",
  "borda",
  "borde",
  "bordo",
  "boreo",
  "borgo",
  "boria",
  "borla",
  "borne",
  "borni",
  "borra",
  "borro",
  "borto",
  "bosar",
  "bosta",
  "botar",
  "botin",
  "boton",
  "botor",
  "boxeo",
  "boxer",
  "boyal",
  "boyar",
  "bozal",
  "bozon",
  "braca",
  "braco",
  "braga",
  "brama",
  "bramo",
  "braña",
  "brasa",
  "brava",
  "bravo",
  "braza",
  "brazo",
  "brear",
  "breca",
  "brega",
  "breña",
  "breta",
  "brete",
  "breva",
  "breve",
  "brezo",
  "brial",
  "briba",
  "brida",
  "briol",
  "brios",
  "brisa",
  "briza",
  "brizo",
  "broca",
  "broma",
  "bromo",
  "brota",
  "brote",
  "broto",
  "broza",
  "brugo",
  "bruja",
  "brujo",
  "bruma",
  "brumo",
  "bruna",
  "bruno",
  "bruño",
  "bruta",
  "bruto",
  "bruza",
  "buaro",
  "bubon",
  "bucal",
  "buceo",
  "buces",
  "bucha",
  "buche",
  "bucio",
  "bucle",
  "budin",
  "buega",
  "bueis",
  "buena",
  "bueno",
  "buera",
  "bufar",
  "bufeo",
  "bufia",
  "bufon",
  "bufos",
  "bugle",
  "buhar",
  "buhio",
  "buida",
  "buido",
  "bujeo",
  "bujia",
  "bular",
  "bulbo",
  "bulda",
  "bulin",
  "bulla",
  "bulon",
  "bulto",
  "bunio",
  "buque",
  "burda",
  "burdo",
  "burel",
  "bureo",
  "burga",
  "burgo",
  "buril",
  "burio",
  "burla",
  "burra",
  "burre",
  "burro",
  "busca",
  "busco",
  "busto",
  "buten",
  "butia",
  "buyes",
  "buzar",
  "buzon",
  "buzos",
  "cabal",
  "cabas",
  "caber",
  "cabio",
  "cable",
  "cabra",
  "cabro",
  "cabru",
  "cacan",
  "cacao",
  "cacea",
  "caceo",
  "cacha",
  "cache",
  "cachi",
  "cacho",
  "cachu",
  "cacle",
  "cacto",
  "cacuy",
  "cader",
  "cadoz",
  "cafiz",
  "cafre",
  "cagar",
  "cagon",
  "cague",
  "cahiz",
  "caico",
  "caida",
  "caido",
  "caima",
  "caire",
  "caite",
  "cajel",
  "cajin",
  "cajon",
  "calan",
  "calao",
  "calar",
  "calca",
  "calce",
  "calco",
  "calda",
  "caldo",
  "caler",
  "cales",
  "caliz",
  "calla",
  "calle",
  "callo",
  "calma",
  "calmo",
  "calon",
  "calor",
  "calta",
  "calva",
  "calvo",
  "calza",
  "calzo",
  "camal",
  "camao",
  "camba",
  "cambo",
  "camia",
  "camio",
  "camon",
  "campa",
  "campi",
  "campo",
  "canal",
  "canco",
  "cande",
  "candi",
  "caney",
  "canez",
  "canga",
  "cangu",
  "cania",
  "canil",
  "canje",
  "canoa",
  "canon",
  "cansa",
  "cansi",
  "canso",
  "canta",
  "cante",
  "canti",
  "canto",
  "cantu",
  "cañal",
  "cañar",
  "cañeo",
  "cañon",
  "caoba",
  "caobo",
  "capar",
  "capaz",
  "capea",
  "capel",
  "capeo",
  "capia",
  "capin",
  "capio",
  "capon",
  "cappa",
  "capuz",
  "caqui",
  "carao",
  "carau",
  "caray",
  "carba",
  "carca",
  "carda",
  "carde",
  "cardo",
  "carea",
  "carel",
  "careo",
  "carey",
  "carga",
  "cargo",
  "caria",
  "cario",
  "cariz",
  "carla",
  "carlo",
  "carma",
  "carme",
  "carne",
  "caron",
  "carpa",
  "carpe",
  "carpo",
  "carra",
  "carre",
  "carri",
  "carro",
  "carta",
  "carvi",
  "casal",
  "casar",
  "casca",
  "casco",
  "casea",
  "caseo",
  "casia",
  "casio",
  "casis",
  "cason",
  "caspa",
  "casta",
  "casto",
  "catan",
  "catar",
  "cateo",
  "catey",
  "catin",
  "caton",
  "catos",
  "catre",
  "catzo",
  "cauba",
  "cauca",
  "cauce",
  "cauda",
  "cauje",
  "caula",
  "cauno",
  "cauri",
  "cauro",
  "causa",
  "cauta",
  "cauto",
  "cauza",
  "cavan",
  "cavar",
  "cavea",
  "cavia",
  "cavio",
  "cavon",
  "cayan",
  "cazar",
  "cazon",
  "cazuz",
  "ceaja",
  "ceajo",
  "cebar",
  "cebil",
  "cebon",
  "cebra",
  "cebro",
  "cebti",
  "cecal",
  "ceceo",
  "ceder",
  "cedra",
  "cedro",
  "cefea",
  "cefeo",
  "cegar",
  "cegri",
  "cegua",
  "ceiba",
  "ceibo",
  "ceina",
  "cejar",
  "celan",
  "celar",
  "celda",
  "celfo",
  "celia",
  "cella",
  "cello",
  "celta",
  "cembo",
  "cenal",
  "cenar",
  "cenca",
  "cence",
  "cenco",
  "cenia",
  "cenit",
  "censo",
  "centi",
  "ceñar",
  "ceñir",
  "ceoan",
  "cepon",
  "cepti",
  "cequi",
  "cerca",
  "cerco",
  "cerda",
  "cerdo",
  "cerea",
  "cereo",
  "ceres",
  "cerio",
  "cerme",
  "cerna",
  "cerne",
  "cerno",
  "ceron",
  "cerpa",
  "cerra",
  "cerro",
  "certa",
  "cesar",
  "cesio",
  "cesta",
  "cesto",
  "cetil",
  "cetis",
  "cetme",
  "cetra",
  "cetre",
  "cetro",
  "ceuti",
  "chaca",
  "chaco",
  "chagu",
  "chaja",
  "chala",
  "chale",
  "chama",
  "chana",
  "chane",
  "chano",
  "chapa",
  "chape",
  "chapo",
  "charo",
  "chata",
  "chato",
  "chaul",
  "chauz",
  "chavo",
  "chaya",
  "chayo",
  "chaza",
  "chazo",
  "checa",
  "checo",
  "cheje",
  "chele",
  "cheli",
  "chelo",
  "chepa",
  "chepe",
  "chepo",
  "chero",
  "chesa",
  "cheso",
  "chiar",
  "chica",
  "chico",
  "chifa",
  "chigu",
  "chile",
  "chimo",
  "china",
  "chine",
  "chino",
  "chipa",
  "chipe",
  "chira",
  "chiri",
  "chisa",
  "chist",
  "chita",
  "chite",
  "chito",
  "chiva",
  "chivo",
  "chiza",
  "choba",
  "choca",
  "choco",
  "chofe",
  "chola",
  "cholo",
  "chopa",
  "chope",
  "chopo",
  "chori",
  "choro",
  "chota",
  "chote",
  "choto",
  "chova",
  "choya",
  "choza",
  "chozo",
  "chuca",
  "chuco",
  "chufa",
  "chula",
  "chule",
  "chulo",
  "chuna",
  "chuña",
  "chuño",
  "chupa",
  "chupe",
  "chupo",
  "churo",
  "chute",
  "chuto",
  "chuva",
  "chuyo",
  "chuza",
  "chuzo",
  "ciani",
  "ciato",
  "cibal",
  "cibui",
  "cicca",
  "ciclo",
  "cidra",
  "cidro",
  "cieca",
  "ciega",
  "ciego",
  "cielo",
  "ciemo",
  "cieno",
  "cifac",
  "cifra",
  "cigua",
  "cilio",
  "cilla",
  "cimar",
  "cimba",
  "cimia",
  "cimpa",
  "cinca",
  "cinco",
  "cinia",
  "cinta",
  "cinto",
  "circa",
  "circe",
  "circo",
  "cirio",
  "cirro",
  "cisca",
  "cisco",
  "cisma",
  "cisne",
  "cista",
  "citar",
  "citra",
  "civil",
  "claco",
  "clamo",
  "clapa",
  "clara",
  "claro",
  "clase",
  "clava",
  "clave",
  "clavo",
  "cleda",
  "clero",
  "clica",
  "clima",
  "clise",
  "cloro",
  "clota",
  "clown",
  "clube",
  "coana",
  "coata",
  "coate",
  "coati",
  "cobea",
  "cobez",
  "cobil",
  "cobla",
  "cobos",
  "cobra",
  "cobre",
  "cobro",
  "cocal",
  "cocan",
  "cocar",
  "cocer",
  "cocha",
  "coche",
  "cochi",
  "cocho",
  "cocol",
  "cocui",
  "cocuy",
  "codal",
  "codeo",
  "codin",
  "codon",
  "coeva",
  "coevo",
  "cofia",
  "cofin",
  "cofre",
  "cofta",
  "cofto",
  "coger",
  "cogon",
  "cohen",
  "cohol",
  "coido",
  "coima",
  "coime",
  "coine",
  "coipo",
  "coito",
  "cojal",
  "cojez",
  "cojin",
  "cojon",
  "colar",
  "coleo",
  "colin",
  "colla",
  "colme",
  "colmo",
  "colon",
  "color",
  "colpa",
  "colpe",
  "colza",
  "comal",
  "comba",
  "combo",
  "comer",
  "comic",
  "comis",
  "compa",
  "comta",
  "comto",
  "comun",
  "conca",
  "concu",
  "conde",
  "conga",
  "congo",
  "conta",
  "coñac",
  "coñon",
  "coona",
  "copal",
  "copar",
  "copec",
  "copeo",
  "copey",
  "copia",
  "copin",
  "copla",
  "copon",
  "copra",
  "copta",
  "copto",
  "coque",
  "coqui",
  "coral",
  "coran",
  "corar",
  "coras",
  "corbe",
  "corca",
  "corco",
  "corda",
  "corea",
  "coreo",
  "coria",
  "corla",
  "corma",
  "corno",
  "corpa",
  "corpi",
  "corps",
  "corra",
  "corro",
  "corsa",
  "corse",
  "corso",
  "corta",
  "corte",
  "corto",
  "corua",
  "corva",
  "corve",
  "corvo",
  "corza",
  "corzo",
  "coser",
  "cospe",
  "costa",
  "coste",
  "costo",
  "cotar",
  "cotin",
  "coton",
  "cotua",
  "coxal",
  "coxis",
  "coyan",
  "coyol",
  "crack",
  "crasa",
  "craso",
  "craza",
  "crear",
  "credo",
  "creer",
  "crema",
  "crepe",
  "cresa",
  "creso",
  "creta",
  "criar",
  "criba",
  "cribo",
  "crica",
  "crida",
  "croar",
  "croco",
  "cromo",
  "crono",
  "cross",
  "croza",
  "cruce",
  "cruda",
  "crudo",
  "cruel",
  "cruor",
  "cruza",
  "cuaba",
  "cuaco",
  "cuada",
  "cuado",
  "cuajo",
  "cuama",
  "cuasi",
  "cuata",
  "cuate",
  "cuati",
  "cubil",
  "cucar",
  "cucha",
  "cuche",
  "cuchi",
  "cucho",
  "cucuy",
  "cueca",
  "cueco",
  "cuemo",
  "cuera",
  "cuero",
  "cuesa",
  "cuete",
  "cueto",
  "cueva",
  "cueza",
  "cuezo",
  "cuica",
  "cuico",
  "cuida",
  "cuido",
  "cuija",
  "cuina",
  "cuino",
  "cuita",
  "cujon",
  "cular",
  "culas",
  "culen",
  "culle",
  "culon",
  "culpa",
  "culta",
  "culto",
  "cumba",
  "cumbe",
  "cumbo",
  "cumel",
  "cumpa",
  "cunar",
  "cuneo",
  "cuñal",
  "cuñar",
  "cuoma",
  "cuota",
  "cuple",
  "cupon",
  "cural",
  "curar",
  "curco",
  "curda",
  "curdo",
  "curia",
  "curie",
  "curio",
  "curra",
  "curre",
  "curro",
  "curry",
  "cursi",
  "curso",
  "curta",
  "curto",
  "curue",
  "curul",
  "curva",
  "curvo",
  "cusca",
  "cusco",
  "cusir",
  "cusma",
  "cuspa",
  "cuter",
  "cutio",
  "cutir",
  "cutis",
  "cutre",
  "cuzco",
  "cuzma",
  "dable",
  "dacha",
  "dacia",
  "dacio",
  "dador",
  "dagon",
  "dahir",
  "daifa",
  "dajao",
  "dalgo",
  "dalia",
  "dalla",
  "dalle",
  "damil",
  "dance",
  "dandi",
  "danes",
  "dango",
  "danta",
  "dante",
  "danto",
  "danza",
  "dañar",
  "daqui",
  "dardo",
  "dares",
  "darga",
  "datar",
  "datil",
  "dauco",
  "dauda",
  "david",
  "debda",
  "debdo",
  "deber",
  "debil",
  "debla",
  "deble",
  "debut",
  "decir",
  "decor",
  "dedal",
  "dedeo",
  "dedil",
  "deesa",
  "dejar",
  "delco",
  "delga",
  "delia",
  "delio",
  "della",
  "dello",
  "delta",
  "demas",
  "dende",
  "deneb",
  "densa",
  "denso",
  "deñar",
  "depos",
  "deque",
  "derbi",
  "desba",
  "desca",
  "desce",
  "desde",
  "deseo",
  "desga",
  "desli",
  "desma",
  "desmo",
  "despe",
  "desri",
  "desro",
  "desta",
  "deste",
  "desti",
  "desto",
  "desus",
  "deuda",
  "deudo",
  "deuto",
  "devan",
  "dezme",
  "diada",
  "diado",
  "diana",
  "diaño",
  "dicaz",
  "dicha",
  "dicho",
  "diego",
  "diesi",
  "dieta",
  "digna",
  "digno",
  "dihue",
  "dijes",
  "dimes",
  "dimir",
  "dinar",
  "diñar",
  "diodo",
  "diosa",
  "dioso",
  "dique",
  "disco",
  "dison",
  "diuca",
  "divan",
  "dobla",
  "doble",
  "doblo",
  "docea",
  "docen",
  "docil",
  "docta",
  "docto",
  "dogal",
  "dogma",
  "dogre",
  "dolar",
  "doler",
  "dolor",
  "domar",
  "dombo",
  "donar",
  "donde",
  "doneo",
  "dopar",
  "doral",
  "dorar",
  "doria",
  "dorio",
  "dorna",
  "dorso",
  "dosel",
  "dosis",
  "dotal",
  "dotar",
  "dotor",
  "draba",
  "draga",
  "drago",
  "drama",
  "drino",
  "driza",
  "droga",
  "drope",
  "drupa",
  "drusa",
  "druso",
  "dseda",
  "dubda",
  "dubio",
  "ducal",
  "ducas",
  "ducha",
  "ducho",
  "ducil",
  "ducto",
  "dudar",
  "duela",
  "duelo",
  "duena",
  "dueña",
  "dueño",
  "dueto",
  "dular",
  "dulce",
  "dulia",
  "dunda",
  "dundo",
  "dupla",
  "duplo",
  "duque",
  "durar",
  "durez",
  "ebano",
  "ebria",
  "ebrio",
  "echar",
  "edema",
  "edila",
  "edipo",
  "edrar",
  "efebo",
  "efera",
  "efero",
  "efeta",
  "efeto",
  "eforo",
  "efusa",
  "efuso",
  "egena",
  "egeno",
  "egida",
  "eguar",
  "ejido",
  "ejion",
  "ejote",
  "elami",
  "elata",
  "elato",
  "elche",
  "elega",
  "elego",
  "elemi",
  "elepe",
  "eleta",
  "eleto",
  "elite",
  "ellas",
  "ellos",
  "elote",
  "embre",
  "empos",
  "empre",
  "emula",
  "emulo",
  "enagu",
  "enana",
  "enano",
  "encia",
  "eneal",
  "enema",
  "enero",
  "engre",
  "enojo",
  "ensay",
  "ensue",
  "enteo",
  "entra",
  "entre",
  "entro",
  "enula",
  "enves",
  "envio",
  "eolia",
  "eolio",
  "epica",
  "epico",
  "epoca",
  "epoda",
  "epodo",
  "epota",
  "epoto",
  "epoxi",
  "equis",
  "eraje",
  "erala",
  "erbio",
  "ercer",
  "erebo",
  "ergio",
  "erial",
  "erina",
  "erizo",
  "ermar",
  "erraj",
  "errar",
  "error",
  "eruga",
  "eruto",
  "escas",
  "escoa",
  "escri",
  "esnob",
  "espay",
  "espia",
  "espin",
  "esqui",
  "estar",
  "estas",
  "estay",
  "ester",
  "estil",
  "estio",
  "estol",
  "estor",
  "estos",
  "estre",
  "estro",
  "esula",
  "etano",
  "etapa",
  "etica",
  "etico",
  "etilo",
  "etimo",
  "etnea",
  "etneo",
  "etnia",
  "etola",
  "etolo",
  "etusa",
  "eubea",
  "eubeo",
  "evohe",
  "exida",
  "exigu",
  "exima",
  "exito",
  "exodo",
  "extra",
  "fabla",
  "fabro",
  "facer",
  "facha",
  "facia",
  "facil",
  "facon",
  "facto",
  "fadar",
  "faena",
  "fagot",
  "faino",
  "fajar",
  "fajin",
  "fajol",
  "fajon",
  "falaz",
  "falca",
  "falce",
  "falda",
  "falgu",
  "falir",
  "falla",
  "fallo",
  "falsa",
  "falso",
  "falta",
  "falte",
  "falto",
  "falua",
  "fanal",
  "fango",
  "fañar",
  "faqui",
  "farad",
  "farda",
  "fardo",
  "fares",
  "faria",
  "farol",
  "faron",
  "farpa",
  "farra",
  "farro",
  "farsa",
  "farta",
  "farte",
  "farto",
  "fasol",
  "fasta",
  "fasto",
  "fatal",
  "fator",
  "fatua",
  "fatuo",
  "fauna",
  "fauno",
  "favor",
  "febea",
  "febeo",
  "feble",
  "febra",
  "fecal",
  "fecha",
  "fecho",
  "feder",
  "fedor",
  "feeza",
  "feila",
  "feliz",
  "felon",
  "felpa",
  "felpo",
  "felus",
  "femar",
  "femur",
  "fenal",
  "fenda",
  "fendi",
  "fenix",
  "fenol",
  "feota",
  "feote",
  "feral",
  "feraz",
  "feria",
  "ferir",
  "fermi",
  "feroz",
  "ferra",
  "ferre",
  "ferro",
  "ferry",
  "festa",
  "fetal",
  "feten",
  "fetor",
  "fetua",
  "feuca",
  "feuco",
  "feudo",
  "fiada",
  "fiado",
  "fibra",
  "ficar",
  "ficha",
  "ficta",
  "ficto",
  "fideo",
  "fiemo",
  "fiera",
  "fiero",
  "figle",
  "figon",
  "fijar",
  "filar",
  "filfa",
  "filia",
  "filis",
  "fillo",
  "filme",
  "filon",
  "final",
  "finar",
  "finca",
  "fines",
  "finir",
  "finta",
  "fique",
  "firma",
  "firme",
  "fisan",
  "fisco",
  "fisga",
  "fisto",
  "fizar",
  "fizon",
  "flaca",
  "flaco",
  "flama",
  "flaon",
  "flash",
  "flato",
  "flava",
  "flavo",
  "fleco",
  "fleja",
  "fleje",
  "flema",
  "fleme",
  "fleta",
  "flete",
  "flexo",
  "floja",
  "flojo",
  "flora",
  "flota",
  "flote",
  "fluir",
  "flujo",
  "fluor",
  "fobia",
  "focal",
  "focha",
  "fogar",
  "fogon",
  "foisa",
  "foiso",
  "folga",
  "folgo",
  "folia",
  "folio",
  "folla",
  "foluz",
  "fomes",
  "fonda",
  "fondo",
  "fonil",
  "fonio",
  "fonje",
  "fonte",
  "foque",
  "foral",
  "foras",
  "forca",
  "forja",
  "forma",
  "forno",
  "forra",
  "forro",
  "forte",
  "forza",
  "fosal",
  "fosar",
  "fosca",
  "fosco",
  "fosil",
  "fosor",
  "foton",
  "fovea",
  "frada",
  "frade",
  "fraga",
  "fragu",
  "frase",
  "fredo",
  "freir",
  "freno",
  "freon",
  "frere",
  "fresa",
  "freso",
  "frete",
  "freza",
  "frida",
  "frido",
  "frige",
  "frion",
  "frior",
  "frisa",
  "friso",
  "frita",
  "frito",
  "froga",
  "frote",
  "fruir",
  "fruta",
  "fruto",
  "fucar",
  "fucia",
  "fudre",
  "fuego",
  "fuera",
  "fuero",
  "fuesa",
  "fufar",
  "fugar",
  "fugaz",
  "fugir",
  "fuida",
  "fuina",
  "fulan",
  "fular",
  "fulla",
  "fumar",
  "funda",
  "fundo",
  "fuñar",
  "furia",
  "furor",
  "furto",
  "fusca",
  "fusco",
  "fusil",
  "fusor",
  "fusta",
  "fuste",
  "fusto",
  "futil",
  "futre",
  "gaban",
  "gacel",
  "gacha",
  "gache",
  "gachi",
  "gacho",
  "gafar",
  "gafez",
  "gaita",
  "galan",
  "galce",
  "galea",
  "galeo",
  "gales",
  "galga",
  "galgo",
  "galio",
  "galla",
  "gallo",
  "galon",
  "galop",
  "galua",
  "gamba",
  "gamma",
  "gamon",
  "ganar",
  "ganga",
  "gansa",
  "ganso",
  "ganta",
  "gante",
  "gañan",
  "gañil",
  "gañin",
  "gañir",
  "gañon",
  "garay",
  "garba",
  "garbo",
  "garda",
  "gardo",
  "gardu",
  "garfa",
  "garfi",
  "gargu",
  "gario",
  "garla",
  "garlo",
  "garma",
  "garpa",
  "garra",
  "garro",
  "garua",
  "garza",
  "garzo",
  "gason",
  "gasto",
  "gatas",
  "gauss",
  "gavia",
  "gayar",
  "gayon",
  "gazmo",
  "gelfe",
  "gemir",
  "genio",
  "genol",
  "genro",
  "genta",
  "gente",
  "gento",
  "geoda",
  "gerbo",
  "gerno",
  "gesta",
  "gesto",
  "gibao",
  "gibar",
  "gibon",
  "gilva",
  "gilvo",
  "ginea",
  "girar",
  "giste",
  "glase",
  "glayo",
  "gleba",
  "glera",
  "glifo",
  "globo",
  "glosa",
  "glose",
  "gluma",
  "gneis",
  "gnomo",
  "goben",
  "gobio",
  "gocha",
  "gocho",
  "godeo",
  "godoy",
  "gofio",
  "golde",
  "goles",
  "golfa",
  "golfo",
  "golpe",
  "gomar",
  "gomel",
  "gomer",
  "gomia",
  "gonce",
  "gongo",
  "gorda",
  "gordo",
  "gorga",
  "gorja",
  "gorra",
  "gorro",
  "goteo",
  "goton",
  "gozar",
  "gozne",
  "gozon",
  "grada",
  "grado",
  "grafo",
  "graja",
  "grajo",
  "grama",
  "gramo",
  "grana",
  "grand",
  "grano",
  "grant",
  "grapa",
  "grasa",
  "graso",
  "grata",
  "grato",
  "grava",
  "grave",
  "greba",
  "greca",
  "greco",
  "greda",
  "green",
  "grege",
  "gregu",
  "grelo",
  "greno",
  "greña",
  "grial",
  "grida",
  "grido",
  "grifa",
  "grifo",
  "grija",
  "grill",
  "grima",
  "gripa",
  "gripe",
  "gripo",
  "grisa",
  "grisu",
  "grita",
  "grito",
  "groar",
  "grofa",
  "grojo",
  "gromo",
  "grosa",
  "groso",
  "gruir",
  "gruja",
  "grumo",
  "grupa",
  "grupo",
  "gruta",
  "guaba",
  "guabo",
  "guaca",
  "guaco",
  "guada",
  "guado",
  "guaja",
  "guaje",
  "guala",
  "guama",
  "guamo",
  "guano",
  "guapa",
  "guape",
  "guapo",
  "guara",
  "guaro",
  "guasa",
  "guaso",
  "guata",
  "guate",
  "guaya",
  "guayo",
  "guban",
  "gubia",
  "gueña",
  "guero",
  "gueto",
  "guiar",
  "guido",
  "guifa",
  "guija",
  "guije",
  "guijo",
  "guila",
  "guile",
  "guiño",
  "guion",
  "guira",
  "guiri",
  "guiro",
  "guisa",
  "guiso",
  "guita",
  "guite",
  "guito",
  "gular",
  "gules",
  "gumia",
  "gurda",
  "gurdo",
  "guron",
  "gusto",
  "guzgo",
  "guzla",
  "habar",
  "haber",
  "habil",
  "habiz",
  "habla",
  "habon",
  "habus",
  "hacan",
  "hacer",
  "hacha",
  "hache",
  "hacho",
  "hacia",
  "hadar",
  "haedo",
  "hafiz",
  "haiga",
  "halar",
  "halda",
  "halon",
  "hamez",
  "hampa",
  "hampo",
  "hansa",
  "hanzo",
  "hapax",
  "harca",
  "harda",
  "harem",
  "haren",
  "harma",
  "haron",
  "harpa",
  "harre",
  "harta",
  "harto",
  "hasta",
  "haute",
  "havar",
  "hayal",
  "heben",
  "hebra",
  "hecha",
  "hecho",
  "hecto",
  "heder",
  "hedor",
  "helar",
  "helio",
  "helor",
  "henal",
  "henar",
  "henil",
  "henry",
  "heñir",
  "heren",
  "heria",
  "heril",
  "herir",
  "herma",
  "heroe",
  "herpe",
  "herre",
  "hertz",
  "hetea",
  "heteo",
  "hevea",
  "hiato",
  "hidra",
  "hielo",
  "hiena",
  "hiera",
  "hiero",
  "higui",
  "hilar",
  "hilio",
  "himen",
  "himno",
  "hinco",
  "hindi",
  "hindu",
  "hiñir",
  "hipar",
  "hiper",
  "hippy",
  "hirco",
  "hirma",
  "hisca",
  "hitar",
  "hiton",
  "hobby",
  "hogar",
  "holan",
  "holco",
  "honda",
  "hondo",
  "hongo",
  "honor",
  "honra",
  "hopeo",
  "hoque",
  "horca",
  "horco",
  "horda",
  "horma",
  "horno",
  "horra",
  "horre",
  "horro",
  "hosca",
  "hosco",
  "hospa",
  "hoste",
  "hotel",
  "hoyar",
  "hozar",
  "huaca",
  "huaco",
  "hucha",
  "hucho",
  "hucia",
  "hueca",
  "hueco",
  "huecu",
  "huego",
  "huera",
  "huero",
  "huesa",
  "hueso",
  "huete",
  "hueva",
  "huevo",
  "huich",
  "huida",
  "huido",
  "huifa",
  "huira",
  "huiro",
  "hulla",
  "hulte",
  "humar",
  "humil",
  "humor",
  "humus",
  "huron",
  "hurra",
  "hurto",
  "husar",
  "husma",
  "husmo",
  "hutia",
  "ibera",
  "ibero",
  "ibice",
  "icaco",
  "ichal",
  "icono",
  "ictus",
  "ideal",
  "idear",
  "idolo",
  "ignea",
  "igneo",
  "igual",
  "iguar",
  "ijada",
  "ijuju",
  "ileon",
  "ilesa",
  "ileso",
  "ilion",
  "ilota",
  "ilusa",
  "iluso",
  "imada",
  "imela",
  "impar",
  "impia",
  "impio",
  "impla",
  "inane",
  "index",
  "india",
  "indio",
  "ingle",
  "ingre",
  "inope",
  "input",
  "inter",
  "invar",
  "irada",
  "irado",
  "irani",
  "iride",
  "irupe",
  "islam",
  "islan",
  "isleo",
  "isoca",
  "istme",
  "istmo",
  "itala",
  "italo",
  "itria",
  "itrio",
  "izada",
  "izado",
  "izote",
  "jable",
  "jabon",
  "jacal",
  "jacer",
  "jadeo",
  "jagua",
  "jaiba",
  "jaima",
  "jajay",
  "jalar",
  "jalda",
  "jalde",
  "jaldo",
  "jalea",
  "jaleo",
  "jalma",
  "jalon",
  "jamar",
  "jamas",
  "jamba",
  "jambo",
  "jamon",
  "japon",
  "jaque",
  "jaral",
  "jarca",
  "jarda",
  "jarra",
  "jarro",
  "jasar",
  "jaspe",
  "jatea",
  "jateo",
  "jatib",
  "jauda",
  "jaudo",
  "jauja",
  "jaula",
  "jauta",
  "jauto",
  "jayan",
  "jedar",
  "jeito",
  "jejen",
  "jeliz",
  "jemal",
  "jeque",
  "jerbo",
  "jerez",
  "jerga",
  "jerpa",
  "jesus",
  "jetar",
  "jeton",
  "jibia",
  "jifia",
  "jigue",
  "jijas",
  "jijeo",
  "jimia",
  "jimio",
  "jinda",
  "jinja",
  "jinjo",
  "jiñar",
  "jiote",
  "jipio",
  "jirel",
  "jiron",
  "jisca",
  "jisma",
  "jitar",
  "jobar",
  "joder",
  "jofor",
  "jolin",
  "jondo",
  "jonia",
  "jonio",
  "jopeo",
  "jorco",
  "jorfe",
  "jorga",
  "jorge",
  "jorro",
  "josue",
  "joule",
  "jovar",
  "joven",
  "joyel",
  "joyon",
  "juana",
  "jubon",
  "judas",
  "judea",
  "judia",
  "judio",
  "judit",
  "juego",
  "juera",
  "jueza",
  "jugar",
  "jujeo",
  "julia",
  "julio",
  "jumil",
  "junco",
  "junio",
  "junta",
  "junto",
  "junza",
  "juñir",
  "jurar",
  "jurco",
  "jurel",
  "jurio",
  "justa",
  "justo",
  "jutia",
  "juvia",
  "kappa",
  "karma",
  "karst",
  "kayak",
  "kefir",
  "kirie",
  "koala",
  "koine",
  "kopek",
  "krill",
  "kurda",
  "kurdo",
  "labeo",
  "labia",
  "labil",
  "labio",
  "labor",
  "labra",
  "labro",
  "lacha",
  "lacho",
  "lacia",
  "lacio",
  "lacon",
  "lacra",
  "lacre",
  "ladeo",
  "ladon",
  "ladra",
  "lagar",
  "lagua",
  "laica",
  "laico",
  "laida",
  "laido",
  "lamer",
  "lamia",
  "lamin",
  "lampa",
  "lampi",
  "lampo",
  "lanar",
  "lance",
  "landa",
  "lande",
  "lando",
  "langa",
  "lania",
  "lanio",
  "lanza",
  "lañar",
  "lapiz",
  "lapon",
  "lapsa",
  "lapso",
  "laque",
  "larda",
  "lardo",
  "larga",
  "largo",
  "larra",
  "larva",
  "lasca",
  "laser",
  "lasto",
  "lasun",
  "latae",
  "lataz",
  "latex",
  "latin",
  "latir",
  "laton",
  "lauda",
  "laude",
  "laudo",
  "launa",
  "lauro",
  "lauta",
  "lauto",
  "lavar",
  "laxar",
  "layar",
  "lazar",
  "lecha",
  "leche",
  "lecho",
  "ledon",
  "ledra",
  "ledro",
  "legal",
  "legar",
  "legon",
  "legra",
  "legua",
  "legui",
  "leida",
  "leido",
  "leila",
  "leima",
  "lejar",
  "lejas",
  "lejia",
  "lejio",
  "lejos",
  "leman",
  "lembo",
  "lempo",
  "lemur",
  "lengu",
  "lenir",
  "lenon",
  "lenta",
  "lente",
  "lento",
  "leñar",
  "leona",
  "lepar",
  "lepra",
  "lerda",
  "lerdo",
  "lesna",
  "leste",
  "letal",
  "letea",
  "leteo",
  "leton",
  "letor",
  "letra",
  "leuda",
  "leude",
  "leudo",
  "levar",
  "lezda",
  "lezna",
  "lezne",
  "liana",
  "liara",
  "liaza",
  "liban",
  "libar",
  "liber",
  "libia",
  "libio",
  "libon",
  "libra",
  "libre",
  "libro",
  "liceo",
  "licia",
  "licio",
  "licor",
  "lider",
  "lidia",
  "lidio",
  "liega",
  "liego",
  "lieva",
  "lieve",
  "ligar",
  "light",
  "ligio",
  "ligon",
  "ligua",
  "ligue",
  "ligur",
  "lijar",
  "lilac",
  "lilao",
  "lilio",
  "limar",
  "limbo",
  "limen",
  "limon",
  "linao",
  "linar",
  "lince",
  "linda",
  "linde",
  "lindo",
  "linea",
  "lineo",
  "linfa",
  "lingu",
  "linio",
  "linon",
  "liosa",
  "lioso",
  "lipes",
  "lipis",
  "lipon",
  "liria",
  "lirio",
  "liron",
  "lisar",
  "lisis",
  "lisol",
  "lisor",
  "lista",
  "listo",
  "litar",
  "litio",
  "litis",
  "litre",
  "litro",
  "lituo",
  "liuda",
  "liudo",
  "livor",
  "lizar",
  "llaca",
  "llaga",
  "llama",
  "llana",
  "llano",
  "llapa",
  "llave",
  "lleca",
  "lleco",
  "llega",
  "llena",
  "lleno",
  "llera",
  "lleta",
  "lleva",
  "lloro",
  "llosa",
  "lobby",
  "local",
  "locha",
  "loche",
  "locro",
  "lodon",
  "lodra",
  "logar",
  "logia",
  "logis",
  "logos",
  "logro",
  "loica",
  "loina",
  "lolio",
  "lomar",
  "lomba",
  "lombo",
  "lonco",
  "longa",
  "longo",
  "lonja",
  "lopez",
  "lorza",
  "losar",
  "loteo",
  "lucas",
  "lucha",
  "luche",
  "lucia",
  "lucio",
  "lucir",
  "lucro",
  "ludia",
  "ludio",
  "ludir",
  "luego",
  "lueñe",
  "lugar",
  "lugre",
  "luisa",
  "lujar",
  "lumbo",
  "lumen",
  "lumia",
  "lunar",
  "lunch",
  "lunel",
  "lunes",
  "lunfa",
  "lunga",
  "lungo",
  "lupia",
  "lupus",
  "lurte",
  "lusca",
  "lusco",
  "lutea",
  "luteo",
  "luvia",
  "luxar",
  "mable",
  "macal",
  "macar",
  "maceo",
  "macha",
  "machi",
  "macho",
  "macia",
  "macio",
  "macis",
  "macla",
  "macon",
  "macro",
  "macua",
  "mador",
  "madre",
  "madro",
  "maesa",
  "maese",
  "maeso",
  "mafia",
  "magar",
  "magia",
  "magin",
  "magma",
  "magna",
  "magno",
  "magra",
  "magro",
  "mague",
  "mahon",
  "maido",
  "majal",
  "majar",
  "malar",
  "malla",
  "mallo",
  "malon",
  "malta",
  "malva",
  "mamar",
  "mambi",
  "mambo",
  "mamey",
  "mamia",
  "mamon",
  "mamua",
  "mamut",
  "manal",
  "manar",
  "manca",
  "manco",
  "manda",
  "mandi",
  "mando",
  "manea",
  "manes",
  "manga",
  "mango",
  "mania",
  "manir",
  "mansa",
  "manso",
  "manta",
  "manto",
  "manus",
  "maori",
  "mapeo",
  "maque",
  "maqui",
  "marca",
  "marce",
  "marco",
  "marea",
  "mareo",
  "marga",
  "maria",
  "marlo",
  "maron",
  "marra",
  "marro",
  "marsa",
  "marso",
  "marta",
  "marte",
  "marzo",
  "masar",
  "masia",
  "maslo",
  "mason",
  "maste",
  "masto",
  "matar",
  "mateo",
  "matiz",
  "maton",
  "matul",
  "maula",
  "maura",
  "maure",
  "mauro",
  "mayal",
  "mayar",
  "mayor",
  "mazar",
  "mbaya",
  "meada",
  "meado",
  "meaja",
  "meano",
  "meato",
  "mecer",
  "mecha",
  "media",
  "medio",
  "medir",
  "medra",
  "medri",
  "medro",
  "meiga",
  "meigo",
  "mejer",
  "mejor",
  "melar",
  "melca",
  "melga",
  "melgo",
  "melis",
  "mella",
  "melon",
  "melsa",
  "melva",
  "memez",
  "menar",
  "menda",
  "meneo",
  "menes",
  "menga",
  "menge",
  "menor",
  "menos",
  "menso",
  "mensu",
  "menta",
  "mente",
  "meona",
  "merar",
  "merca",
  "merey",
  "mergo",
  "merla",
  "merlo",
  "merma",
  "mesar",
  "mesma",
  "mesmo",
  "meson",
  "mesta",
  "meste",
  "mesto",
  "metad",
  "metal",
  "meter",
  "metra",
  "metro",
  "meyor",
  "miaja",
  "micer",
  "micha",
  "miche",
  "micho",
  "micra",
  "micro",
  "miedo",
  "miera",
  "migar",
  "migra",
  "milan",
  "milla",
  "millo",
  "milpa",
  "mimar",
  "minal",
  "minar",
  "minaz",
  "minga",
  "mingo",
  "minio",
  "minue",
  "miñon",
  "mioma",
  "miope",
  "mirar",
  "miria",
  "mirla",
  "mirlo",
  "miron",
  "mirra",
  "mirto",
  "mirza",
  "misal",
  "misar",
  "misia",
  "misil",
  "misio",
  "misma",
  "mismo",
  "mista",
  "misto",
  "mitad",
  "mitan",
  "mitin",
  "miton",
  "mitra",
  "miura",
  "mixta",
  "mixto",
  "mizar",
  "moare",
  "moble",
  "mocar",
  "mocha",
  "moche",
  "mocho",
  "mocil",
  "modal",
  "modem",
  "modio",
  "mofar",
  "mogol",
  "mogon",
  "mohin",
  "mohur",
  "mojar",
  "mojel",
  "mojil",
  "mojon",
  "molar",
  "molde",
  "moler",
  "molla",
  "molle",
  "molon",
  "molsa",
  "molso",
  "momia",
  "momio",
  "monda",
  "mondo",
  "monfi",
  "monga",
  "mongo",
  "monin",
  "monis",
  "monja",
  "monje",
  "monse",
  "monta",
  "monte",
  "monti",
  "monto",
  "moñon",
  "moral",
  "morar",
  "morbi",
  "morbo",
  "morca",
  "morce",
  "morea",
  "morel",
  "moreo",
  "morfa",
  "morga",
  "morir",
  "moron",
  "morra",
  "morri",
  "morro",
  "morsa",
  "morse",
  "mosca",
  "mosco",
  "mosen",
  "moste",
  "mosto",
  "motar",
  "motel",
  "motil",
  "motin",
  "moton",
  "motor",
  "mover",
  "movil",
  "moxte",
  "muare",
  "mucha",
  "mucho",
  "mudar",
  "mudez",
  "mueca",
  "muela",
  "muelo",
  "muera",
  "muesa",
  "mueso",
  "mufla",
  "mufti",
  "mugar",
  "mugil",
  "mugir",
  "mugor",
  "mugre",
  "muita",
  "muito",
  "mujer",
  "mujol",
  "mular",
  "muleo",
  "mulla",
  "mullo",
  "mulsa",
  "mulso",
  "multa",
  "multi",
  "mundo",
  "muñir",
  "muñon",
  "mural",
  "murar",
  "murga",
  "muria",
  "murta",
  "murto",
  "musar",
  "musca",
  "musco",
  "museo",
  "musga",
  "musgo",
  "muslo",
  "muste",
  "mutar",
  "mutis",
  "mutro",
  "mutua",
  "mutuo",
  "nabab",
  "nabal",
  "nabar",
  "nabla",
  "nacar",
  "nacer",
  "nacha",
  "nacho",
  "nacre",
  "nadal",
  "nadar",
  "nadga",
  "nadie",
  "nadir",
  "nafra",
  "nafta",
  "nagua",
  "nahoa",
  "nahua",
  "naife",
  "naipe",
  "naire",
  "nalca",
  "nalga",
  "nanay",
  "nance",
  "nanga",
  "nango",
  "nansa",
  "nansu",
  "napea",
  "napeo",
  "naque",
  "narco",
  "nardo",
  "nares",
  "nariz",
  "narra",
  "nasal",
  "nason",
  "natal",
  "natia",
  "natio",
  "natri",
  "nauta",
  "naval",
  "navio",
  "nebel",
  "nebli",
  "nebro",
  "necia",
  "necio",
  "nefas",
  "negar",
  "negra",
  "negro",
  "negus",
  "neldo",
  "nemea",
  "nemeo",
  "nemon",
  "nengu",
  "nenia",
  "neron",
  "nesga",
  "neuma",
  "nevar",
  "niara",
  "niazo",
  "nicho",
  "nicle",
  "nicol",
  "nidal",
  "nidia",
  "nidio",
  "niego",
  "nieta",
  "nieto",
  "nieve",
  "nigua",
  "nilad",
  "nilon",
  "nimbo",
  "nimia",
  "nimio",
  "ninfa",
  "ninfo",
  "ningu",
  "ninot",
  "niñez",
  "nioto",
  "nipis",
  "nipon",
  "nipos",
  "niqui",
  "niste",
  "nitor",
  "nitro",
  "nivea",
  "nivel",
  "niveo",
  "nixte",
  "noble",
  "noche",
  "nocir",
  "nocla",
  "nodal",
  "noema",
  "nogal",
  "nolit",
  "nomas",
  "nomon",
  "nones",
  "nonio",
  "nopal",
  "noque",
  "noray",
  "noria",
  "norma",
  "norte",
  "notar",
  "notro",
  "noval",
  "novar",
  "novel",
  "noven",
  "novia",
  "novio",
  "nubil",
  "nubio",
  "nubla",
  "nublo",
  "nuche",
  "nucir",
  "nuera",
  "nuesa",
  "nueso",
  "nueva",
  "nueve",
  "nuevo",
  "nueza",
  "numen",
  "nunca",
  "nutra",
  "ñandu",
  "ñanga",
  "ñango",
  "ñaque",
  "ñecla",
  "ñengo",
  "ñeque",
  "ñinga",
  "ñique",
  "ñisca",
  "ñocha",
  "ñoclo",
  "ñongo",
  "ñoñez",
  "ñoqui",
  "ñorbo",
  "ñublo",
  "oasis",
  "obelo",
  "obesa",
  "obeso",
  "obice",
  "obito",
  "oblea",
  "obolo",
  "obrar",
  "obvia",
  "obvio",
  "ocapi",
  "ocaso",
  "ocelo",
  "ocena",
  "ociar",
  "ocote",
  "ocuje",
  "ocume",
  "ocumo",
  "odeon",
  "odiar",
  "oeste",
  "ofita",
  "ogaño",
  "ohmio",
  "oible",
  "oidio",
  "oidor",
  "oislo",
  "ojala",
  "ojear",
  "ojera",
  "ojete",
  "ojito",
  "ojiva",
  "ojosa",
  "ojoso",
  "ojota",
  "ojudo",
  "okapi",
  "olaje",
  "olear",
  "oliva",
  "olivo",
  "ollao",
  "ollar",
  "olote",
  "olura",
  "omaso",
  "omega",
  "omero",
  "omeya",
  "omisa",
  "omiso",
  "ondeo",
  "ondra",
  "onice",
  "onoto",
  "opaca",
  "opaco",
  "opada",
  "opado",
  "opalo",
  "opera",
  "opima",
  "opimo",
  "optar",
  "oraje",
  "orate",
  "orden",
  "orear",
  "oreja",
  "orfre",
  "orgia",
  "oribe",
  "orina",
  "oriol",
  "orion",
  "orive",
  "orlar",
  "ornar",
  "oroya",
  "oruga",
  "orujo",
  "orzar",
  "osada",
  "osado",
  "osear",
  "osera",
  "osero",
  "oseta",
  "osmio",
  "ososa",
  "ososo",
  "ostia",
  "ostra",
  "ostro",
  "osuda",
  "osudo",
  "osuna",
  "osuno",
  "otate",
  "otear",
  "otero",
  "otoba",
  "otoño",
  "ovada",
  "ovado",
  "ovalo",
  "oveja",
  "overa",
  "overo",
  "ovido",
  "ovina",
  "ovino",
  "ovolo",
  "ovosa",
  "ovoso",
  "ovulo",
  "oxear",
  "oxido",
  "ozona",
  "ozono",
  "pablo",
  "pacay",
  "pacer",
  "pacha",
  "pacho",
  "pacon",
  "pacto",
  "pacul",
  "padre",
  "pafia",
  "pafio",
  "pagar",
  "pagel",
  "pagro",
  "pagua",
  "pahua",
  "paico",
  "paila",
  "paina",
  "pairo",
  "pajar",
  "pajea",
  "pajel",
  "pajil",
  "pajon",
  "pajuz",
  "palas",
  "palay",
  "palca",
  "palco",
  "palia",
  "palio",
  "palla",
  "palma",
  "palmo",
  "palon",
  "palor",
  "palpi",
  "palpo",
  "palta",
  "palto",
  "pamba",
  "pampa",
  "pamue",
  "panal",
  "panca",
  "panco",
  "panda",
  "pando",
  "panel",
  "panga",
  "pange",
  "panji",
  "panol",
  "pansa",
  "panty",
  "panul",
  "panza",
  "pañal",
  "pañil",
  "pañol",
  "papal",
  "papar",
  "papaz",
  "papel",
  "papin",
  "papon",
  "papua",
  "paral",
  "parao",
  "parar",
  "parca",
  "parce",
  "parco",
  "parda",
  "pardo",
  "pared",
  "parel",
  "pareo",
  "pargo",
  "paria",
  "pario",
  "parir",
  "paris",
  "parla",
  "parne",
  "parra",
  "parro",
  "parsi",
  "parta",
  "parte",
  "parto",
  "parva",
  "parvo",
  "pasar",
  "pasco",
  "paseo",
  "pasil",
  "pasma",
  "pasmo",
  "pasta",
  "paste",
  "pasto",
  "patan",
  "patao",
  "patas",
  "patax",
  "patay",
  "pateo",
  "pater",
  "patin",
  "patio",
  "paton",
  "patra",
  "pauji",
  "pausa",
  "pauta",
  "paves",
  "pavia",
  "pavon",
  "pavor",
  "payar",
  "payes",
  "peaje",
  "peana",
  "peaña",
  "pebre",
  "pecar",
  "pecha",
  "peche",
  "pecho",
  "pecio",
  "pedal",
  "pedio",
  "pedir",
  "pedre",
  "pedro",
  "pegar",
  "pegon",
  "peina",
  "peine",
  "pejin",
  "pelar",
  "pelda",
  "pelde",
  "pelea",
  "pelel",
  "pella",
  "pello",
  "pelma",
  "pelon",
  "pelta",
  "pelvi",
  "penal",
  "penar",
  "penca",
  "penco",
  "penol",
  "peñol",
  "peñon",
  "pepla",
  "peplo",
  "pepon",
  "peque",
  "peral",
  "perca",
  "perge",
  "peris",
  "perla",
  "perle",
  "perna",
  "perno",
  "perol",
  "perra",
  "perro",
  "persa",
  "perta",
  "pesar",
  "pesca",
  "pesce",
  "pescu",
  "pesga",
  "pesgo",
  "pesia",
  "pesol",
  "pesor",
  "pesta",
  "peste",
  "pesti",
  "petar",
  "petra",
  "peuco",
  "peumo",
  "pezon",
  "piada",
  "piano",
  "piara",
  "pibil",
  "pical",
  "picar",
  "picea",
  "piceo",
  "picha",
  "piche",
  "pichi",
  "picho",
  "picio",
  "picoa",
  "picol",
  "picon",
  "picor",
  "picta",
  "piden",
  "pidon",
  "piejo",
  "pieza",
  "pifar",
  "pifia",
  "pigra",
  "pigre",
  "pigro",
  "pihua",
  "pilar",
  "pilca",
  "pileo",
  "pilla",
  "pillo",
  "pilme",
  "pilon",
  "pinar",
  "pinga",
  "pingo",
  "pingu",
  "pinna",
  "pinol",
  "pinta",
  "pinte",
  "pinto",
  "pinza",
  "piñal",
  "piñen",
  "piñon",
  "piojo",
  "piola",
  "piona",
  "pipar",
  "pipil",
  "pipon",
  "pique",
  "piral",
  "pirar",
  "pirca",
  "pirco",
  "pirgu",
  "pirla",
  "piron",
  "pisar",
  "pisca",
  "pisco",
  "pison",
  "pispa",
  "pista",
  "piste",
  "pisto",
  "pitao",
  "pitar",
  "pitia",
  "pitio",
  "piton",
  "piune",
  "piure",
  "pivot",
  "pixel",
  "pizca",
  "pizco",
  "pizza",
  "placa",
  "plaga",
  "plana",
  "plano",
  "plata",
  "plato",
  "playa",
  "playo",
  "plaza",
  "plazo",
  "plebe",
  "pleca",
  "plena",
  "pleno",
  "pleon",
  "plepa",
  "plexo",
  "plica",
  "plomo",
  "pluma",
  "pobra",
  "pobre",
  "pocha",
  "pocho",
  "podar",
  "poder",
  "podio",
  "podon",
  "podre",
  "poema",
  "poeta",
  "poino",
  "poisa",
  "poise",
  "polar",
  "polca",
  "polea",
  "polen",
  "poleo",
  "polex",
  "polin",
  "polio",
  "polir",
  "polla",
  "pollo",
  "polux",
  "polvo",
  "pomar",
  "pomez",
  "pomol",
  "pompa",
  "pompo",
  "ponce",
  "ponci",
  "pondo",
  "poner",
  "poney",
  "pongo",
  "ponto",
  "ponzo",
  "popal",
  "popar",
  "popel",
  "popes",
  "porco",
  "porno",
  "porra",
  "porro",
  "porta",
  "porte",
  "posar",
  "posca",
  "posma",
  "poson",
  "posta",
  "poste",
  "potar",
  "potra",
  "potro",
  "poyal",
  "poyar",
  "pozal",
  "pozol",
  "prade",
  "prado",
  "prava",
  "pravo",
  "praza",
  "prear",
  "preda",
  "presa",
  "preso",
  "prest",
  "prima",
  "primo",
  "prion",
  "prior",
  "prisa",
  "priso",
  "proal",
  "proba",
  "probo",
  "proco",
  "proel",
  "profe",
  "prois",
  "proiz",
  "prole",
  "prona",
  "prono",
  "prora",
  "prosa",
  "proto",
  "pruna",
  "pruno",
  "puado",
  "puber",
  "pubes",
  "pubis",
  "pucha",
  "pucho",
  "pucia",
  "pudin",
  "pudio",
  "pudir",
  "pudor",
  "pugil",
  "pugna",
  "pujar",
  "pujes",
  "pulga",
  "pulir",
  "pulla",
  "pulpa",
  "pulpo",
  "pulso",
  "pumba",
  "punar",
  "punga",
  "punir",
  "punta",
  "punto",
  "punzo",
  "puñal",
  "puñar",
  "puñir",
  "pupar",
  "purga",
  "purin",
  "puspo",
  "puyar",
  "puyon",
  "puzle",
  "puzol",
  "quark",
  "queco",
  "queda",
  "quedo",
  "queja",
  "quejo",
  "quema",
  "quemi",
  "quena",
  "quera",
  "queso",
  "quico",
  "quien",
  "quier",
  "quijo",
  "quila",
  "quili",
  "quilo",
  "quima",
  "quimo",
  "quina",
  "quino",
  "quiño",
  "quipa",
  "quipo",
  "quipu",
  "quisa",
  "quita",
  "quite",
  "quito",
  "quivi",
  "quiza",
  "rabal",
  "rabel",
  "rabeo",
  "rabia",
  "rabil",
  "rabon",
  "racel",
  "racha",
  "racor",
  "radal",
  "radar",
  "radia",
  "radio",
  "radon",
  "rafal",
  "rafez",
  "rafia",
  "ragua",
  "rahez",
  "raida",
  "raido",
  "raijo",
  "rajar",
  "rajon",
  "ralea",
  "rallo",
  "rally",
  "ramal",
  "ramea",
  "rameo",
  "ramio",
  "ramon",
  "rampa",
  "rampi",
  "ranal",
  "randa",
  "rango",
  "rapar",
  "rapaz",
  "rapta",
  "rapto",
  "raque",
  "rasar",
  "rasca",
  "rasco",
  "rascu",
  "rasel",
  "rasgo",
  "rasgu",
  "raspa",
  "rateo",
  "ratio",
  "raton",
  "rauca",
  "rauco",
  "rauda",
  "raudo",
  "rauli",
  "rauta",
  "rayar",
  "rayon",
  "razar",
  "razia",
  "razon",
  "reacu",
  "reala",
  "reame",
  "reata",
  "reato",
  "reble",
  "rebol",
  "rebus",
  "recel",
  "recia",
  "recio",
  "recle",
  "recre",
  "recta",
  "recto",
  "recua",
  "redar",
  "redel",
  "redil",
  "redol",
  "redor",
  "redro",
  "refez",
  "regar",
  "regia",
  "regio",
  "regir",
  "regla",
  "regru",
  "rehen",
  "rehus",
  "reina",
  "reino",
  "rejal",
  "rejin",
  "rejon",
  "rejus",
  "relax",
  "relej",
  "reloj",
  "relsa",
  "relso",
  "relva",
  "remar",
  "renal",
  "renca",
  "renco",
  "renda",
  "renga",
  "rengo",
  "renil",
  "renio",
  "renta",
  "rento",
  "reñir",
  "reoca",
  "reojo",
  "resca",
  "resma",
  "resol",
  "respe",
  "resta",
  "resto",
  "retal",
  "retar",
  "retel",
  "reten",
  "retin",
  "retir",
  "retor",
  "retro",
  "reuma",
  "rever",
  "reves",
  "reyar",
  "rezar",
  "rezno",
  "rezon",
  "riada",
  "ricia",
  "ricio",
  "riego",
  "riera",
  "rieto",
  "rifar",
  "rifle",
  "rigel",
  "rigor",
  "rigue",
  "rilar",
  "rimar",
  "rimel",
  "rinde",
  "riñon",
  "rioja",
  "ripia",
  "ripio",
  "risca",
  "risco",
  "rispa",
  "rispo",
  "riste",
  "risue",
  "ritmo",
  "riton",
  "rival",
  "rizal",
  "rizar",
  "rizon",
  "roana",
  "roano",
  "robar",
  "robda",
  "robin",
  "robla",
  "roble",
  "robot",
  "robra",
  "robre",
  "rocha",
  "rocho",
  "rocin",
  "rocio",
  "rodal",
  "rodar",
  "rodas",
  "rodea",
  "rodeo",
  "rodia",
  "rodil",
  "rodio",
  "roela",
  "roete",
  "rogar",
  "roida",
  "roido",
  "rojal",
  "rojez",
  "rolar",
  "rolde",
  "roleo",
  "rolla",
  "rollo",
  "roman",
  "rombo",
  "romea",
  "romeo",
  "romin",
  "ronca",
  "ronce",
  "ronco",
  "ronda",
  "ronde",
  "rondo",
  "ronza",
  "roñal",
  "roñar",
  "roñia",
  "ropon",
  "roque",
  "rorar",
  "rorro",
  "rosal",
  "rosar",
  "rosca",
  "rosco",
  "rosea",
  "roseo",
  "rosjo",
  "roson",
  "rotal",
  "rotar",
  "roten",
  "rotor",
  "rouge",
  "royal",
  "rozar",
  "rozno",
  "rozon",
  "ruana",
  "ruano",
  "rubea",
  "rubeo",
  "rubia",
  "rubin",
  "rubio",
  "rublo",
  "rubor",
  "rubra",
  "rubro",
  "rucar",
  "ruche",
  "rucho",
  "rucia",
  "rucio",
  "rudez",
  "rueca",
  "rueda",
  "ruedo",
  "ruego",
  "ruejo",
  "rueño",
  "rufon",
  "rugar",
  "rugby",
  "rugir",
  "ruido",
  "ruina",
  "ruise",
  "rular",
  "rumba",
  "rumbo",
  "rumia",
  "rumor",
  "rungo",
  "ruñar",
  "rupia",
  "rural",
  "rurru",
  "rusco",
  "rusel",
  "rusia",
  "rutar",
  "rutel",
  "sabea",
  "sabeo",
  "saber",
  "sabia",
  "sabio",
  "sable",
  "sabor",
  "sabre",
  "sacar",
  "sacho",
  "sacia",
  "sacio",
  "sacon",
  "sacra",
  "sacre",
  "sacro",
  "saeta",
  "saeti",
  "safio",
  "safir",
  "sagaz",
  "saino",
  "sajar",
  "sajia",
  "sajon",
  "salab",
  "salar",
  "salaz",
  "salce",
  "salci",
  "saldo",
  "salea",
  "salep",
  "salga",
  "salia",
  "salin",
  "salio",
  "salir",
  "salma",
  "salmo",
  "salol",
  "salon",
  "salpa",
  "salsa",
  "salso",
  "salte",
  "salto",
  "salud",
  "salva",
  "salve",
  "salvo",
  "saman",
  "samba",
  "sambo",
  "samia",
  "samio",
  "sampa",
  "sanar",
  "sanco",
  "sanes",
  "sango",
  "sangu",
  "sanie",
  "sansa",
  "sanso",
  "santa",
  "santo",
  "sapan",
  "saque",
  "saran",
  "sarao",
  "sarco",
  "sarda",
  "sarde",
  "sardo",
  "sarga",
  "sargo",
  "saria",
  "sarna",
  "sarro",
  "sarta",
  "sarza",
  "sarzo",
  "satan",
  "saten",
  "satin",
  "satis",
  "sauce",
  "sauco",
  "saudi",
  "sauna",
  "savia",
  "saxea",
  "saxeo",
  "sayal",
  "sayon",
  "sazon",
  "secar",
  "secen",
  "secon",
  "secor",
  "secta",
  "secua",
  "sedal",
  "sedan",
  "sedar",
  "segar",
  "segri",
  "segun",
  "segur",
  "seibo",
  "seico",
  "seise",
  "sello",
  "seltz",
  "selva",
  "semen",
  "semis",
  "senda",
  "senil",
  "señal",
  "señor",
  "seora",
  "sepia",
  "septo",
  "serba",
  "serbo",
  "seria",
  "serie",
  "serio",
  "serna",
  "seron",
  "serpa",
  "serta",
  "servo",
  "sesen",
  "seseo",
  "sesga",
  "sesgo",
  "sesil",
  "sesma",
  "sesmo",
  "sespe",
  "setal",
  "seudo",
  "sexma",
  "sexmo",
  "sexta",
  "sexto",
  "short",
  "sibil",
  "siclo",
  "sidra",
  "siega",
  "siena",
  "sieso",
  "siete",
  "sifon",
  "sifue",
  "sigla",
  "siglo",
  "sigma",
  "signo",
  "sigua",
  "silba",
  "silbo",
  "silex",
  "silfo",
  "silga",
  "silla",
  "silva",
  "simia",
  "simil",
  "simio",
  "simon",
  "simpa",
  "simun",
  "singa",
  "sipia",
  "sirga",
  "sirgo",
  "siria",
  "sirio",
  "sirle",
  "sirte",
  "sisal",
  "sisar",
  "sisca",
  "siseo",
  "sismo",
  "sison",
  "sitio",
  "sobar",
  "sobeo",
  "sobon",
  "sobra",
  "sobre",
  "socaz",
  "soche",
  "socia",
  "socio",
  "sodio",
  "soeza",
  "sogun",
  "solar",
  "solaz",
  "solea",
  "solen",
  "soleo",
  "soler",
  "solfa",
  "solio",
  "solla",
  "sollo",
  "sonar",
  "sonda",
  "sonio",
  "sonsa",
  "sonso",
  "sonta",
  "sonto",
  "soñar",
  "sopar",
  "sopie",
  "soplo",
  "sopon",
  "sopor",
  "sorba",
  "sorbo",
  "sorce",
  "sorda",
  "sordo",
  "sorgo",
  "sorna",
  "soror",
  "sorra",
  "sosal",
  "sosar",
  "sosia",
  "sotar",
  "sotil",
  "sotol",
  "sovoz",
  "sport",
  "spray",
  "stand",
  "statu",
  "stock",
  "suabo",
  "suato",
  "suave",
  "subeo",
  "subir",
  "subte",
  "suche",
  "sucia",
  "sucio",
  "sucre",
  "sudar",
  "sudor",
  "sueca",
  "sueco",
  "suela",
  "suelo",
  "sueno",
  "sueño",
  "suero",
  "sueva",
  "suevo",
  "sufra",
  "suido",
  "suita",
  "suite",
  "suiza",
  "suizo",
  "sulco",
  "sulla",
  "sumar",
  "sumir",
  "sunco",
  "super",
  "supra",
  "sural",
  "surca",
  "surco",
  "surta",
  "surto",
  "susto",
  "sutas",
  "sutil",
  "suyas",
  "suyos",
  "suzon",
  "tabal",
  "tabea",
  "tabes",
  "tabla",
  "tabon",
  "tabor",
  "tacar",
  "tacet",
  "tacha",
  "tacho",
  "tacon",
  "tacto",
  "tafia",
  "tafon",
  "tafur",
  "tagua",
  "tahur",
  "taifa",
  "taiga",
  "taima",
  "taina",
  "taino",
  "taira",
  "taire",
  "taita",
  "tajar",
  "tajea",
  "tajon",
  "talan",
  "talar",
  "talco",
  "talea",
  "taled",
  "talgo",
  "talin",
  "talio",
  "talla",
  "talle",
  "tallo",
  "talma",
  "talon",
  "talpa",
  "talud",
  "tamal",
  "tamba",
  "tambo",
  "tamil",
  "tamiz",
  "tamul",
  "tanda",
  "tanga",
  "tango",
  "tanka",
  "tanor",
  "tanta",
  "tanto",
  "tanza",
  "tañar",
  "tañer",
  "tapar",
  "tapeo",
  "tapia",
  "tapin",
  "tapir",
  "tapis",
  "tapiz",
  "tapon",
  "taque",
  "tarar",
  "taray",
  "tarca",
  "tarco",
  "tarda",
  "tarde",
  "tardo",
  "tarea",
  "tarin",
  "tarja",
  "tarma",
  "tarot",
  "tarra",
  "tarre",
  "tarro",
  "tarso",
  "tarta",
  "tasar",
  "tasca",
  "tasco",
  "tasia",
  "tasio",
  "tasto",
  "tatas",
  "tauca",
  "taula",
  "tauro",
  "taxon",
  "tazar",
  "tazon",
  "teame",
  "tebea",
  "tebeo",
  "techo",
  "tecla",
  "tecle",
  "tecol",
  "tedio",
  "tegea",
  "tegeo",
  "tegua",
  "tegue",
  "teina",
  "tejar",
  "tejer",
  "tejon",
  "telar",
  "telex",
  "telon",
  "temer",
  "temor",
  "tempo",
  "tenaz",
  "tenca",
  "tener",
  "tenia",
  "tenis",
  "teniu",
  "tenor",
  "tensa",
  "tenso",
  "tenua",
  "tenue",
  "tenuo",
  "teñir",
  "teosa",
  "teoso",
  "terca",
  "terco",
  "terma",
  "termo",
  "terna",
  "terne",
  "terno",
  "terre",
  "terru",
  "tersa",
  "terso",
  "tesar",
  "tesis",
  "tesla",
  "teson",
  "testa",
  "teste",
  "tetar",
  "teton",
  "tetra",
  "tetro",
  "texto",
  "theta",
  "tiaca",
  "tiara",
  "tibar",
  "tibia",
  "tibio",
  "tibor",
  "tiesa",
  "tieso",
  "tifon",
  "tifus",
  "tigra",
  "tigre",
  "tilde",
  "tilia",
  "tilin",
  "tilla",
  "tillo",
  "tilma",
  "timar",
  "timba",
  "timbo",
  "timol",
  "timon",
  "timpa",
  "tinca",
  "tinco",
  "tinea",
  "tinge",
  "tinta",
  "tinte",
  "tinto",
  "tiple",
  "tipoi",
  "tipoy",
  "tique",
  "tiqui",
  "tirar",
  "tiria",
  "tirio",
  "tiron",
  "tirso",
  "tirte",
  "tisis",
  "tiste",
  "titan",
  "titar",
  "titeo",
  "tizna",
  "tizne",
  "tizon",
  "tlaco",
  "tobar",
  "tocar",
  "tocha",
  "toche",
  "tocho",
  "tocia",
  "tocio",
  "tocon",
  "tocte",
  "todia",
  "toesa",
  "tojal",
  "tolda",
  "toldo",
  "tolla",
  "tollo",
  "tolmo",
  "tolon",
  "tolva",
  "tomar",
  "tomin",
  "tomon",
  "tonal",
  "tonar",
  "tonca",
  "tondo",
  "tonel",
  "tonga",
  "tongo",
  "tonta",
  "tonto",
  "toñil",
  "topar",
  "topia",
  "topil",
  "topon",
  "toque",
  "toqui",
  "toral",
  "torax",
  "torca",
  "torce",
  "torco",
  "torda",
  "tordo",
  "toreo",
  "tores",
  "torga",
  "torgo",
  "toril",
  "torio",
  "tormo",
  "torna",
  "torno",
  "toron",
  "torpe",
  "torre",
  "torso",
  "torta",
  "torva",
  "torvo",
  "tosca",
  "tosco",
  "toser",
  "total",
  "totem",
  "totum",
  "tozal",
  "tozar",
  "traba",
  "trabe",
  "traca",
  "trace",
  "traer",
  "trago",
  "traje",
  "trama",
  "tramo",
  "trans",
  "trapa",
  "trape",
  "trapo",
  "traro",
  "trasa",
  "trase",
  "traso",
  "trata",
  "trato",
  "travo",
  "traza",
  "trazo",
  "trebo",
  "trece",
  "trefe",
  "treja",
  "tremo",
  "trena",
  "treno",
  "trepa",
  "trepe",
  "tresa",
  "treta",
  "treza",
  "trial",
  "triar",
  "tribu",
  "triga",
  "trigo",
  "trile",
  "trina",
  "trino",
  "tripa",
  "tripe",
  "trisa",
  "triza",
  "troco",
  "troja",
  "troje",
  "trola",
  "trole",
  "trona",
  "trono",
  "tropa",
  "tropo",
  "trote",
  "trova",
  "trovo",
  "troya",
  "troza",
  "trozo",
  "truca",
  "truco",
  "trufa",
  "truja",
  "trusa",
  "trust",
  "tucan",
  "tucia",
  "tudel",
  "tueca",
  "tueco",
  "tuera",
  "tuero",
  "tuina",
  "tulio",
  "tulpa",
  "tumba",
  "tumbo",
  "tumor",
  "tunal",
  "tunar",
  "tunca",
  "tunco",
  "tunda",
  "tunel",
  "tunez",
  "tunjo",
  "tunta",
  "tupin",
  "tupir",
  "turar",
  "turba",
  "turca",
  "turco",
  "turma",
  "turno",
  "turon",
  "turpe",
  "turra",
  "turro",
  "tusar",
  "tusca",
  "tusco",
  "tuson",
  "tuteo",
  "tutia",
  "tutor",
  "tuyas",
  "tuyos",
  "ubada",
  "ucase",
  "uebos",
  "ueste",
  "ufana",
  "ufano",
  "ujier",
  "ulaga",
  "ulala",
  "ulano",
  "ulema",
  "ulmen",
  "ultra",
  "ulula",
  "umbra",
  "umbro",
  "umero",
  "uncia",
  "uncir",
  "ungir",
  "unica",
  "unico",
  "union",
  "untar",
  "uñado",
  "uñate",
  "uñero",
  "uñeta",
  "uñoso",
  "upupa",
  "urano",
  "urape",
  "urato",
  "urdir",
  "urgir",
  "urias",
  "urica",
  "urico",
  "urubu",
  "urucu",
  "uruga",
  "usada",
  "usado",
  "usaje",
  "usier",
  "usina",
  "usted",
  "usual",
  "usura",
  "usuta",
  "utero",
  "uvada",
  "uvate",
  "uvera",
  "uvero",
  "uviar",
  "uvula",
  "vacar",
  "vacio",
  "vacua",
  "vacuo",
  "vagar",
  "vagon",
  "vahar",
  "vaida",
  "vaina",
  "valar",
  "valer",
  "valgo",
  "valia",
  "valla",
  "valle",
  "valon",
  "valor",
  "valse",
  "valua",
  "valva",
  "vamos",
  "vapor",
  "varal",
  "varar",
  "varea",
  "vareo",
  "varga",
  "varia",
  "vario",
  "variz",
  "varon",
  "vasar",
  "vasca",
  "vasco",
  "vasta",
  "vasto",
  "vater",
  "vatio",
  "vedar",
  "vejar",
  "vejez",
  "vejon",
  "velar",
  "velay",
  "velis",
  "veliz",
  "vello",
  "velon",
  "veloz",
  "venal",
  "venda",
  "vendi",
  "vendo",
  "venia",
  "venir",
  "venta",
  "venus",
  "veras",
  "veraz",
  "verba",
  "verbi",
  "verbo",
  "verde",
  "verdi",
  "verga",
  "verge",
  "vergo",
  "vergu",
  "veril",
  "verja",
  "verme",
  "vermu",
  "verso",
  "vesta",
  "veste",
  "vetar",
  "veton",
  "vezar",
  "viada",
  "viaje",
  "vichy",
  "vicia",
  "vicio",
  "victo",
  "vidal",
  "video",
  "vidro",
  "vidue",
  "vieja",
  "viejo",
  "viesa",
  "vigia",
  "vigor",
  "villa",
  "vilos",
  "vinal",
  "vinar",
  "vinca",
  "vinco",
  "vinta",
  "viola",
  "viral",
  "virar",
  "vireo",
  "virgo",
  "viril",
  "virio",
  "virol",
  "viron",
  "virus",
  "visal",
  "visar",
  "visco",
  "visir",
  "vison",
  "visor",
  "vista",
  "visto",
  "vital",
  "vitar",
  "vitor",
  "vitre",
  "viuda",
  "viudo",
  "vivac",
  "vivar",
  "vivaz",
  "vivez",
  "vivir",
  "voace",
  "vocal",
  "voceo",
  "vodca",
  "vodka",
  "voila",
  "volar",
  "volea",
  "voleo",
  "volvo",
  "vomer",
  "voraz",
  "vosco",
  "voseo",
  "votar",
  "votri",
  "vuelo",
  "vuesa",
  "vuese",
  "vueso",
  "vulgo",
  "vulto",
  "vulva",
  "vusco",
  "weber",
  "xenon",
  "yacal",
  "yacer",
  "yacio",
  "yagua",
  "yaiti",
  "yamao",
  "yambo",
  "yangu",
  "yanta",
  "yapar",
  "yaque",
  "yarda",
  "yarey",
  "yatai",
  "yatay",
  "yedgo",
  "yedra",
  "yegua",
  "yelgo",
  "yelmo",
  "yente",
  "yeral",
  "yerba",
  "yerma",
  "yermo",
  "yerna",
  "yerno",
  "yerra",
  "yerro",
  "yersi",
  "yerta",
  "yerto",
  "yervo",
  "yesal",
  "yesar",
  "yesca",
  "yeson",
  "yezgo",
  "yogar",
  "yogui",
  "yogur",
  "yoqui",
  "yucal",
  "yumba",
  "yumbo",
  "yunga",
  "yunta",
  "yunto",
  "yuras",
  "yuyal",
  "zabra",
  "zabro",
  "zaceo",
  "zafar",
  "zafia",
  "zafio",
  "zafir",
  "zafon",
  "zafra",
  "zafre",
  "zagal",
  "zagua",
  "zahen",
  "zahon",
  "zaida",
  "zaina",
  "zaino",
  "zajon",
  "zalbo",
  "zalea",
  "zaleo",
  "zamba",
  "zambo",
  "zampa",
  "zampo",
  "zanca",
  "zanco",
  "zanga",
  "zanja",
  "zapar",
  "zaque",
  "zarbo",
  "zarca",
  "zarce",
  "zarco",
  "zarja",
  "zarpa",
  "zarza",
  "zarzo",
  "zebra",
  "zegri",
  "zejel",
  "zenda",
  "zendo",
  "zenit",
  "zeuma",
  "zoclo",
  "zofra",
  "zoilo",
  "zoizo",
  "zolle",
  "zombi",
  "zompa",
  "zompo",
  "zonal",
  "zonda",
  "zonto",
  "zonza",
  "zonzo",
  "zopas",
  "zoque",
  "zorra",
  "zorro",
  "zotal",
  "zuavo",
  "zubia",
  "zueca",
  "zueco",
  "zuela",
  "zuiza",
  "zulla",
  "zumba",
  "zumbo",
  "zumel",
  "zuñir",
  "zupia",
  "zurba",
  "zurda",
  "zurdo",
  "zureo",
  "zurra",
  "zuzar",
  "zuzon",
];
