

export const TEXTS_DE = {
  GAME_TITLE: 'Jordle 🇩🇪',
  WIN_MESSAGES: ['Tolle Arbeit!', 'Super', 'Gut gemacht!', "Großartig!", "Erstaunlich!", "Spektakulär!", "Unglaublich!", "Erstaunlich!", "Außergewöhnlich!"],
  GAME_COPIED_MESSAGE: 'Spiel in die Zwischenablage kopiert',
  ABOUT_GAME_MESSAGE: 'Über dieses Spiel',
  NOT_ENOUGH_LETTERS_MESSAGE: 'Nicht genug Buchstaben',
  WORD_NOT_FOUND_MESSAGE: 'Wort nicht gefunden',
  HARD_MODE_DESC: "Die erratenen Buchstaben müssen im nächsten Wort vorkommen",
  HARD_MODE_ALERT_MESSAGE: "Hard-Modus aktiviert!",
  CORRECT_WORD_MESSAGE: (solution: string) => `Das Wort war ${solution}`,
  WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Verwenden müssen ${guess} in position ${position}`,
  NOT_CONTAINED_MESSAGE: (letter: string) => `Vermutung muss enthalten ${letter}`,
  URL_DEFINITION: (word: string) => `https://www.dwds.de/wb/${word}`,
  ENTER_TEXT: 'Enter',
  DELETE_TEXT: 'Löschen',
  STATISTICS_TITLE: 'Statistiken',
  GUESS_DISTRIBUTION_TEXT: 'Raten Sie die Verteilung',
  NEW_WORD_TEXT: 'Neues Wort rein ',
  SHARE_TEXT: 'Teilen',
  TOTAL_TRIES_TEXT: 'Totale Versuche',
  SUCCESS_RATE_TEXT: 'Erfolgsrate',
  CURRENT_STREAK_TEXT: 'Aktueller Streifzug',
  BEST_STREAK_TEXT: 'Bester Streifen',
  INFO_TITLE: "Spielanleitung",
  INFO_INTRO: "Erraten Sie das Wort in 6 Versuchen. Nach jeder Vermutung ändert sich die Farbe der Kacheln, um anzuzeigen, wie nah Ihre Vermutung am Wort war.",
  INFO_CORRECT: "Der Buchstabe 'W' ist im Wort und an der richtigen Stelle.",
  INFO_PRESENT: "Der Buchstabe 'L' ist im Wort, aber an der falschen Stelle.",
  INFO_ABSENT: "Der Buchstabe 'U' kommt an keiner Stelle im Wort vor.",
  PLAY_MORE: "Nochmal abspielen!!"
};


