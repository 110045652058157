import { getGuessStatuses } from './statuses';
import { WordPlaying } from './words';
import { Text } from '../i18n/LangHandler';
import { Settings } from '../constants/settings';

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean
) => {
  // https://play.google.com/store/apps/details?id=com.jovid.jordle
  const url = process.env.REACT_APP_SHARE_URL || "https://jordle.jovid.win";
  const lang = localStorage.getItem("lang") || "en";
  navigator.clipboard.writeText(
    `${Text.GAME_TITLE} Unlimited (${lang.toUpperCase()}): ${WordPlaying.text} ${lost ? 'X' : guesses.length
    }/${Settings.MAX_CHALLENGES} (${isHardMode ? 'hard' : 'normal'} mode)\n\n` +
    generateEmojiGrid(guesses) +
    `\n\n Wordle Unlimited games (english, spanish, french, italian, german, catalá, euskara) \n\n ${url}  \n`
  );
};

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess);
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩';
            case 'present':
              return '🟨';
            default:
              //if (localStorage.getItem('theme') === 'dark') {
              return '⬛';
            //}
            //return '⬜';
          }
        })
        .join('');
    })
    .join('\n');
};
