import { VALID_GUESSES_DE } from './validGuesses_de';
import { WORDS_DE } from './wordlist_de';
import { VALID_GUESSES_FR } from './validGuesses_fr';
import { VALID_GUESSES_IT } from './validGuesses_it';
import { WORDS_EN } from './wordlist_en';
import { VALID_GUESSES_EN } from './validGuesses_en';
import { WORDS_ES } from './wordlist_es';
import { VALID_GUESSES_ES } from './validGuesses_es';
import { WORDS_IT } from './wordlist_it';
import { WORDS_FR } from './wordlist_fr';
import { VALID_GUESSES_CAT } from './validGuesses_cat';
import { WORDS_CAT } from './wordlist_cat';
import { VALID_GUESSES_EUSK } from './validGuesses_eusk';
import { WORDS_EUSK } from './wordlist_eusk';


/**
 *
 * Command to prepare simple word list:
 *   awk '{ print "\""$0"\","}' file > new_file
 *
 * @export
 * @class DictHandler
 */
export class DictHandler {

  public words!: string[];
  public guesses!: string[];

  constructor(public lang: string = "en") {
    this.load();
  }
  private load() {
    switch (this.lang) {
      case "es":
        this.words = WORDS_ES;
        this.guesses = VALID_GUESSES_ES;
        break;
      case "cat":
        this.words = WORDS_CAT;
        this.guesses = VALID_GUESSES_CAT;
        break;
      case "eusk":
        this.words = WORDS_EUSK;
        this.guesses = VALID_GUESSES_EUSK;
        break;
      case "it":
        this.words = WORDS_IT;
        this.guesses = VALID_GUESSES_IT;
        break;
      case "fr":
        this.words = WORDS_FR;
        this.guesses = VALID_GUESSES_FR;
        break;
      case "de":
        this.words = WORDS_DE;
        this.guesses = VALID_GUESSES_DE;
        break;
      default:
        this.words = WORDS_EN;
        this.guesses = VALID_GUESSES_EN;
    }

  }
}

export const dictHandler: DictHandler = new DictHandler(localStorage.getItem("lang") || undefined);
