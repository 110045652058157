

export const TEXTS_EN = {
  GAME_TITLE: 'Jordle 🇺🇸',
  WIN_MESSAGES: ['Great Job!', 'Awesome', 'Well done!', "Great!", "Amazing!", "Spectacular!", "Incredible!", "Amazing!", "Extraordinary!"],
  GAME_COPIED_MESSAGE: 'Game copied to clipboard',
  ABOUT_GAME_MESSAGE: 'About this game',
  NOT_ENOUGH_LETTERS_MESSAGE: 'Not enough letters',
  WORD_NOT_FOUND_MESSAGE: 'Word not found',
  HARD_MODE_DESC: "The guessed letters must appear in the next word",
  HARD_MODE_ALERT_MESSAGE: "Hard mode enabled!",
  CORRECT_WORD_MESSAGE: (solution: string) => `The word was ${solution}`,
  WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Must use ${guess} in position ${position}`,
  NOT_CONTAINED_MESSAGE: (letter: string) => `Guess must contain ${letter}`,
  URL_DEFINITION: (word: string) => `https://dictionary.cambridge.org/dictionary/english/${word}`,
  ENTER_TEXT: 'Enter',
  DELETE_TEXT: 'Delete',
  STATISTICS_TITLE: 'Statistics',
  GUESS_DISTRIBUTION_TEXT: 'Guess Distribution',
  NEW_WORD_TEXT: 'New word in ',
  SHARE_TEXT: 'Share',
  TOTAL_TRIES_TEXT: 'Total tries',
  SUCCESS_RATE_TEXT: 'Success rate',
  CURRENT_STREAK_TEXT: 'Current streak',
  BEST_STREAK_TEXT: 'Best streak',
  INFO_TITLE: "How to play",
  INFO_INTRO: "Guess the word in 6 tries. After each guess, the color of the tiles will change to show how close your guess was to the word.",
  INFO_CORRECT: "The letter 'W' is in the word and in the correct spot.",
  INFO_PRESENT: "The letter 'L' is in the word but in the wrong spot.",
  INFO_ABSENT: "The letter 'U' is not in the word in any spot.",
  PLAY_MORE: "Play again!"
};


