

export const TEXTS_IT = {
  GAME_TITLE: 'Jordle 🇮🇹',
  WIN_MESSAGES: ['Ottimo lavoro!', 'Fantastico', 'Ben fatto!', "Fantastico!", "Incredibile!", "Spettacolare!", "Straordinario!"],
  GAME_COPIED_MESSAGE: 'Gioco copiato negli appunti',
  ABOUT_GAME_MESSAGE: 'A proposito di questo gioco',
  NOT_ENOUGH_LETTERS_MESSAGE: 'Non abbastanza lettere',
  WORD_NOT_FOUND_MESSAGE: 'Parola non trovata',
  HARD_MODE_DESC: "Le lettere indovinate devono apparire nella parola successiva",
  HARD_MODE_ALERT_MESSAGE: "Modalità difficile abilitata!",
  CORRECT_WORD_MESSAGE: (solution: string) => `La parola era ${solution}`,
  WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Deve usare ${guess} in posizione ${position}`,
  NOT_CONTAINED_MESSAGE: (letter: string) => `Indovina deve contenere ${letter}`,
  URL_DEFINITION: (word: string) => `https://www.dizionario-italiano.it/dizionario-italiano.php?parola=${word}`,
  ENTER_TEXT: 'Enter',
  DELETE_TEXT: 'Eliminare',
  STATISTICS_TITLE: 'Statistiche',
  GUESS_DISTRIBUTION_TEXT: 'Indovina la distribuzione',
  NEW_WORD_TEXT: 'Nuova parola in ',
  SHARE_TEXT: 'Condividere',
  TOTAL_TRIES_TEXT: 'Totale tentativi',
  SUCCESS_RATE_TEXT: 'Tasso di successo',
  CURRENT_STREAK_TEXT: 'Serie attuale',
  BEST_STREAK_TEXT: 'Miglior serie',
  INFO_TITLE: "Come giocare",
  INFO_INTRO: "Indovina la parola in 6 tentativi. Dopo ogni ipotesi, il colore delle tessere cambierà per mostrare quanto la tua ipotesi fosse vicina alla parola.",
  INFO_CORRECT: "La lettera 'W' è nella parola e nel punto corretto.",
  INFO_PRESENT: "La lettera 'L' è nella parola ma nel posto sbagliato.",
  INFO_ABSENT: "La lettera 'U' non è nella parola in nessun punto.",
  PLAY_MORE: "Gioca di nuovo!"
};


