import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { Text } from "../../i18n/LangHandler";

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title={Text.INFO_TITLE} isOpen={isOpen} handleClose={handleClose}>
      <div className="flex justify-center mb-3 mt-4 ">
        <a href="https://www.buymeacoffee.com/jovidstudio" className="focus:outline-none" >
          <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=😀&slug=jovidstudio&button_colour=5F7FFF&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00" />
        </a>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {Text.INFO_INTRO}
      </p>



      <div className="flex justify-center mb-1 mt-4">
        <Cell value="W" isRevealing={true} isCompleted={true} status="correct" />
        <Cell value="E" />
        <Cell value="A" />
        <Cell value="R" />
        <Cell value="Y" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {Text.INFO_CORRECT}
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" isRevealing={true} isCompleted={true} status="present" />
        <Cell value="L" />
        <Cell value="S" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {Text.INFO_PRESENT}
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="V" />
        <Cell value="A" />
        <Cell value="G" />
        <Cell value="U" isRevealing={true} isCompleted={true} status="absent" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {Text.INFO_ABSENT}
      </p>

      <br />
      <a href="https://bit.ly/attributiongithub" className="underline font-light text-sm text-gray-700 dark:text-gray-300" target="_blank" rel="noreferrer">Attribution</a>
      <p className="text-sm text-gray-700 dark:text-gray-300">
        Thank you to the original <a href="https://www.powerlanguage.co.uk/wordle/" className="underline font-light text-sm text-gray-700 dark:text-gray-300" target="_blank" rel="noreferrer">Wordle Game</a>
      </p>


    </BaseModal>
  )
}
