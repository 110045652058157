

export const TEXTS_EUSK = {
  GAME_TITLE: 'Jordle',
  WIN_MESSAGES: ['Ongi egina!', 'Ikaragarria!', 'Lan bikaina!', "Ikaragarria!", "Harrigarria!", "Ikusgarria!", "Izugarria!", "Harrigarria!", "Apartekoa!"],
  GAME_COPIED_MESSAGE: 'Jokoa arbelean kopiatu da',
  ABOUT_GAME_MESSAGE: 'Joko honi buruz',
  NOT_ENOUGH_LETTERS_MESSAGE: 'Letra nahikorik ez',
  WORD_NOT_FOUND_MESSAGE: 'Hitza ez da aurkitu',
  HARD_MODE_DESC: "Asmatutako letrak hurrengo hitzean agertu behar dira",
  HARD_MODE_ALERT_MESSAGE: "Modu gogorra gaituta!",
  CORRECT_WORD_MESSAGE: (solution: string) => `Hitza zen ${solution}`,
  WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Erabili behar da ${guess} posizioan ${position}`,
  NOT_CONTAINED_MESSAGE: (letter: string) => `Asmatzeak eduki behar du ${letter}`,
  URL_DEFINITION: (word: string) => `https://hiztegia.labayru.eus/bilatu/LH/all/${word}`,
  ENTER_TEXT: 'Bidali',
  DELETE_TEXT: 'Ezabatu',
  STATISTICS_TITLE: 'Estatistika',
  GUESS_DISTRIBUTION_TEXT: 'Asmatu Banaketa',
  NEW_WORD_TEXT: 'Nueva palabra en ',
  SHARE_TEXT: 'Partekatu',
  TOTAL_TRIES_TEXT: 'Total intentos',
  SUCCESS_RATE_TEXT: 'Aciertos',
  CURRENT_STREAK_TEXT: 'Racha actual',
  BEST_STREAK_TEXT: 'Mejor racha',
  INFO_TITLE: "Nola jokatu",
  INFO_INTRO: "Adivina la palabra en 6 intentos. Después de cada suposición, el color de las fichas cambiará para mostrar qué tan cerca estuvo su suposición de la palabra.",
  INFO_CORRECT: "La letra 'W' está en la palabra y en el lugar correcto.",
  INFO_PRESENT: "La letra 'L' está en la palabra pero en el lugar equivocado.",
  INFO_ABSENT: "La letra 'U' no está en la palabra en ningún lugar.",
  PLAY_MORE: "Berriz jokatu!"
};


