export const WORDS_EUSK = [
  "abade",
  "abail",
  "abako",
  "abala",
  "abant",
  "abaña",
  "abari",
  "abaro",
  "abata",
  "abats",
  "abatu",
  "abatz",
  "abaxu",
  "abegi",
  "abela",
  "abere",
  "aberi",
  "abioi",
  "abiso",
  "abito",
  "abitu",
  "aboen",
  "abonu",
  "abori",
  "abots",
  "aburu",
  "adaje",
  "adaki",
  "adala",
  "adañi",
  "adats",
  "addar",
  "adeal",
  "adegi",
  "adesa",
  "adiba",
  "adina",
  "aditu",
  "aditz",
  "adobo",
  "adore",
  "adret",
  "afari",
  "afera",
  "agata",
  "ageri",
  "agian",
  "agira",
  "agiri",
  "agitu",
  "agitz",
  "agoñü",
  "agots",
  "agotz",
  "agudo",
  "agure",
  "ahabi",
  "ahago",
  "ahaka",
  "ahari",
  "ahate",
  "ahatx",
  "aheri",
  "ahetz",
  "ahitu",
  "ahobi",
  "ahoko",
  "ahots",
  "ahotz",
  "ahuku",
  "ahuñe",
  "ahuts",
  "ahutz",
  "aiada",
  "aidur",
  "aieka",
  "aieru",
  "aihar",
  "aihen",
  "aiher",
  "aikor",
  "ailis",
  "ailko",
  "ainar",
  "aiñar",
  "aiots",
  "airis",
  "airos",
  "aisia",
  "aisit",
  "aiton",
  "aitor",
  "aitzi",
  "aiüla",
  "aiuma",
  "aiuri",
  "aiuta",
  "aizto",
  "aizun",
  "ajitu",
  "ajutu",
  "ajütü",
  "akabo",
  "akara",
  "akats",
  "aketz",
  "akita",
  "akitu",
  "akort",
  "akula",
  "akuri",
  "alaba",
  "alabe",
  "alaga",
  "alaka",
  "alano",
  "alasa",
  "alatu",
  "alaua",
  "albar",
  "albol",
  "aldai",
  "aldar",
  "aldia",
  "aldiz",
  "aldra",
  "aletu",
  "alfer",
  "alkar",
  "alper",
  "althe",
  "altzo",
  "amain",
  "amama",
  "amanu",
  "amatu",
  "amelo",
  "amelu",
  "amets",
  "ametz",
  "amilu",
  "amomo",
  "amona",
  "amore",
  "amula",
  "amulu",
  "amutu",
  "anabi",
  "anaia",
  "anbar",
  "andar",
  "andel",
  "andre",
  "andur",
  "anedo",
  "anega",
  "aneka",
  "anere",
  "angel",
  "angio",
  "anhoa",
  "animo",
  "anitz",
  "ankei",
  "anker",
  "anple",
  "anpor",
  "antxa",
  "antxu",
  "antxü",
  "antze",
  "antzo",
  "antzu",
  "añegu",
  "apain",
  "apaiz",
  "apart",
  "apatx",
  "apatz",
  "apoki",
  "apotz",
  "aragi",
  "aratz",
  "arazi",
  "arazo",
  "arbel",
  "arbin",
  "ardit",
  "areto",
  "aretx",
  "argal",
  "arima",
  "aritz",
  "arkai",
  "arkin",
  "arlot",
  "armin",
  "arnas",
  "arnes",
  "arora",
  "arotx",
  "arotz",
  "arpan",
  "arpel",
  "arpeo",
  "arpin",
  "arpoi",
  "arrai",
  "arran",
  "arras",
  "arren",
  "arreo",
  "arres",
  "arroz",
  "artez",
  "artos",
  "artxo",
  "asaba",
  "asago",
  "asaia",
  "asele",
  "askar",
  "askun",
  "asnas",
  "asper",
  "aspes",
  "aspik",
  "aspil",
  "aspre",
  "astin",
  "astru",
  "astun",
  "atail",
  "ataka",
  "atake",
  "atari",
  "atatu",
  "ataza",
  "ateka",
  "atela",
  "atera",
  "ateri",
  "athei",
  "atija",
  "atzal",
  "auhen",
  "auher",
  "aulki",
  "aurba",
  "aurio",
  "aurka",
  "aurki",
  "aurre",
  "ausin",
  "axola",
  "axuri",
  "azaga",
  "azaia",
  "azari",
  "azaro",
  "azeri",
  "azkar",
  "azken",
  "azkon",
  "aznai",
  "azoka",
  "azola",
  "azote",
  "azots",
  "azpin",
  "aztal",
  "aztar",
  "azter",
  "aztru",
  "babau",
  "babes",
  "babil",
  "babux",
  "badia",
  "badur",
  "bagil",
  "bahüt",
  "baiez",
  "baiki",
  "baila",
  "baile",
  "baina",
  "baino",
  "bainu",
  "baira",
  "baita",
  "bakal",
  "bakan",
  "bakar",
  "bakun",
  "balbe",
  "balde",
  "balea",
  "balio",
  "balma",
  "baloi",
  "balur",
  "balus",
  "banai",
  "banan",
  "banda",
  "bando",
  "banzo",
  "bapho",
  "barau",
  "barda",
  "barga",
  "bargo",
  "barik",
  "barka",
  "barna",
  "barne",
  "baroi",
  "barra",
  "barre",
  "barri",
  "barru",
  "barta",
  "bartz",
  "barza",
  "basko",
  "basta",
  "basül",
  "batan",
  "bateo",
  "batez",
  "batto",
  "batzu",
  "bazka",
  "bazko",
  "beatu",
  "beaza",
  "bedar",
  "bedel",
  "bedoi",
  "begar",
  "behar",
  "behin",
  "behor",
  "beila",
  "beilo",
  "beire",
  "beita",
  "bekho",
  "bekut",
  "belai",
  "belar",
  "beldu",
  "belhi",
  "bella",
  "beltz",
  "belun",
  "belus",
  "benda",
  "bengo",
  "benta",
  "beoya",
  "berar",
  "beraz",
  "berba",
  "berde",
  "beren",
  "berex",
  "berez",
  "berga",
  "berlo",
  "berme",
  "berna",
  "berri",
  "berro",
  "bertz",
  "berun",
  "beska",
  "besta",
  "beste",
  "betor",
  "betun",
  "bezon",
  "beztu",
  "biago",
  "bibil",
  "bidai",
  "bidar",
  "bider",
  "bidur",
  "bigar",
  "bigel",
  "bigun",
  "bihar",
  "bihun",
  "bihur",
  "bikor",
  "bikun",
  "bilbe",
  "bildo",
  "bildu",
  "bilen",
  "bilgo",
  "bilun",
  "bilur",
  "biluz",
  "biñer",
  "biper",
  "bipil",
  "birau",
  "birla",
  "biska",
  "bista",
  "bitan",
  "bitxi",
  "bizar",
  "bizio",
  "bizki",
  "biztu",
  "boboi",
  "bodin",
  "bohor",
  "boila",
  "boina",
  "bokoi",
  "bolda",
  "bolin",
  "bolor",
  "bonet",
  "bontz",
  "borda",
  "bormu",
  "bornu",
  "borru",
  "borta",
  "bortu",
  "bortz",
  "bosta",
  "botea",
  "braga",
  "brasa",
  "budar",
  "buexa",
  "bühür",
  "bukal",
  "bular",
  "bulda",
  "bulko",
  "burar",
  "burdi",
  "burdu",
  "burgi",
  "burgu",
  "burho",
  "buriñ",
  "burki",
  "burla",
  "burna",
  "buruz",
  "busti",
  "bustu",
  "butzu",
  "buxek",
  "dagün",
  "daila",
  "daino",
  "dainu",
  "danba",
  "danda",
  "danga",
  "danik",
  "dardo",
  "darga",
  "dario",
  "dasta",
  "dataz",
  "dauka",
  "dauko",
  "debot",
  "debru",
  "defot",
  "deitu",
  "dekor",
  "denda",
  "denge",
  "depen",
  "depot",
  "detse",
  "diago",
  "dindi",
  "dipon",
  "disti",
  "dithi",
  "ditxo",
  "doinu",
  "domek",
  "dornu",
  "dorpe",
  "dorre",
  "dosel",
  "dosta",
  "dotor",
  "draga",
  "driza",
  "duaxa",
  "dudaz",
  "duela",
  "dünda",
  "dündü",
  "duntu",
  "dutxa",
  "duzti",
  "dzipo",
  "ebaki",
  "ebanu",
  "edabe",
  "edade",
  "edale",
  "edari",
  "edasi",
  "edder",
  "edeki",
  "edosi",
  "edota",
  "eduki",
  "egara",
  "egari",
  "egatz",
  "egetu",
  "egian",
  "egiaz",
  "egidi",
  "egiko",
  "egile",
  "egite",
  "egoki",
  "egosi",
  "eguen",
  "eguno",
  "ehaki",
  "ehein",
  "ehetz",
  "ehitu",
  "ehoki",
  "ehule",
  "eihar",
  "eitzi",
  "ekain",
  "ekera",
  "ekhei",
  "ekuru",
  "elaia",
  "elaka",
  "elazu",
  "elder",
  "eleja",
  "elgar",
  "elger",
  "eliza",
  "elkar",
  "elkor",
  "eltur",
  "eltxo",
  "eltze",
  "eltzü",
  "elume",
  "eluts",
  "emaro",
  "emeki",
  "enara",
  "enbei",
  "enbor",
  "enbra",
  "enene",
  "epail",
  "epher",
  "eraen",
  "erago",
  "eragu",
  "erail",
  "erain",
  "eraio",
  "erari",
  "eratu",
  "erazi",
  "erazo",
  "erazu",
  "erdal",
  "erdio",
  "eredu",
  "eregi",
  "erein",
  "eremu",
  "eresi",
  "ereti",
  "eretz",
  "ereza",
  "ergai",
  "ergel",
  "ergun",
  "erida",
  "erigi",
  "erigo",
  "erika",
  "eriko",
  "eritu",
  "erkin",
  "erlai",
  "erlax",
  "ernai",
  "eroan",
  "eroki",
  "erori",
  "erosi",
  "eroso",
  "erotu",
  "erran",
  "erras",
  "erraz",
  "errea",
  "erren",
  "errex",
  "errez",
  "erroi",
  "erron",
  "errun",
  "ertar",
  "ertze",
  "ertzo",
  "erube",
  "esago",
  "esaka",
  "esale",
  "esaul",
  "eseri",
  "eskai",
  "eskas",
  "esker",
  "eslai",
  "esnar",
  "espar",
  "espil",
  "espos",
  "estai",
  "esugi",
  "esuro",
  "etsai",
  "etxau",
  "etxon",
  "etzan",
  "etzin",
  "euntz",
  "euria",
  "eurki",
  "eusko",
  "eutsi",
  "exegi",
  "ezade",
  "ezain",
  "ezazi",
  "ezbai",
  "ezein",
  "ezeki",
  "ezetu",
  "ezetz",
  "ezkai",
  "ezkel",
  "ezker",
  "ezkur",
  "ezpal",
  "ezpel",
  "eztar",
  "eztei",
  "ezten",
  "ezter",
  "eztoi",
  "eztul",
  "eztun",
  "ezune",
  "falta",
  "farol",
  "feble",
  "feliz",
  "ferde",
  "fermu",
  "festa",
  "fidel",
  "fidos",
  "filus",
  "fitxa",
  "flako",
  "fleit",
  "floka",
  "foina",
  "fonio",
  "forja",
  "forma",
  "forru",
  "fosil",
  "froga",
  "frunt",
  "fungo",
  "funts",
  "fusil",
  "futil",
  "futre",
  "gabai",
  "gaban",
  "gabia",
  "gabon",
  "gaion",
  "gaita",
  "gaitu",
  "gaitz",
  "gaixo",
  "gaiza",
  "galai",
  "galar",
  "galda",
  "galde",
  "galdu",
  "galga",
  "galgo",
  "galki",
  "galoi",
  "gando",
  "ganga",
  "ganik",
  "gantz",
  "gapar",
  "gapel",
  "garai",
  "garau",
  "garba",
  "garbi",
  "gardo",
  "gardu",
  "garei",
  "garil",
  "garko",
  "garle",
  "garmu",
  "garnu",
  "garro",
  "garun",
  "gasna",
  "gastu",
  "gatha",
  "gatzu",
  "gaude",
  "gauza",
  "gazta",
  "gazte",
  "gazun",
  "gazur",
  "gedar",
  "gehio",
  "geinu",
  "gelba",
  "geldi",
  "gemen",
  "gente",
  "geren",
  "gerla",
  "gerli",
  "gernu",
  "gerok",
  "geroz",
  "gerpe",
  "gerra",
  "gerri",
  "gertu",
  "gesal",
  "geure",
  "gezal",
  "gezur",
  "giadu",
  "gibel",
  "gidak",
  "gider",
  "gietz",
  "gihar",
  "gilda",
  "giltz",
  "ginga",
  "girli",
  "gitoi",
  "gizen",
  "gizon",
  "gizur",
  "gogor",
  "gogoz",
  "goien",
  "goiko",
  "goiti",
  "gokho",
  "golde",
  "golüt",
  "gomar",
  "gomon",
  "gontz",
  "gopor",
  "gorde",
  "goren",
  "gorga",
  "gorni",
  "gorpu",
  "gorri",
  "gorta",
  "gorte",
  "gosez",
  "gostu",
  "gotor",
  "grasa",
  "greko",
  "grifü",
  "grina",
  "gubia",
  "gubio",
  "guhor",
  "gurdi",
  "gurtu",
  "gustu",
  "gutar",
  "gutti",
  "gutun",
  "gutxi",
  "guzti",
  "guzur",
  "hagin",
  "hagun",
  "haien",
  "haika",
  "haiko",
  "haina",
  "haitz",
  "haize",
  "haizu",
  "haltz",
  "hamar",
  "handi",
  "hanka",
  "hanpa",
  "hantu",
  "haran",
  "harbi",
  "hardi",
  "harea",
  "haril",
  "harjo",
  "harpa",
  "harpe",
  "harri",
  "harro",
  "hartu",
  "hartz",
  "hatse",
  "hatxe",
  "hauei",
  "haurk",
  "hauta",
  "hauts",
  "hauxe",
  "hauze",
  "hauzu",
  "hazil",
  "hazle",
  "hazta",
  "hazte",
  "hazti",
  "heden",
  "hedoi",
  "hegal",
  "hegan",
  "hegaz",
  "hegoi",
  "heina",
  "helde",
  "heldu",
  "hemen",
  "heren",
  "herio",
  "heroi",
  "herra",
  "herri",
  "herru",
  "hersi",
  "hersu",
  "hertu",
  "hesol",
  "heure",
  "hezur",
  "hiena",
  "hildo",
  "hilka",
  "hinka",
  "hiotü",
  "hiran",
  "hiroi",
  "hirur",
  "hista",
  "histu",
  "hitza",
  "hizka",
  "hoben",
  "hodei",
  "hogei",
  "hoila",
  "holaz",
  "holli",
  "hondo",
  "honen",
  "hontz",
  "horda",
  "hordi",
  "horka",
  "horko",
  "horma",
  "horra",
  "hortu",
  "hortz",
  "hosin",
  "hospe",
  "hosto",
  "hozka",
  "huita",
  "humil",
  "hunla",
  "huntü",
  "huntz",
  "hustu",
  "hüxtü",
  "ibaso",
  "ibeni",
  "ibili",
  "idaur",
  "ideia",
  "ideki",
  "iduri",
  "ifini",
  "igain",
  "igali",
  "igara",
  "igaro",
  "igatu",
  "igeri",
  "ihesi",
  "ihime",
  "ihitz",
  "ihiza",
  "ihizi",
  "ijito",
  "ikara",
  "ikasi",
  "ikatz",
  "ikusi",
  "ikutu",
  "ikuzi",
  "ilaga",
  "ilaun",
  "ilhar",
  "iliki",
  "illor",
  "iloba",
  "ilotz",
  "iltki",
  "iltze",
  "ilzar",
  "indar",
  "infra",
  "iñera",
  "iñutu",
  "iphüi",
  "ipini",
  "ipiñi",
  "ipuin",
  "ipuro",
  "irail",
  "irain",
  "iratu",
  "iraun",
  "ireki",
  "irozo",
  "irris",
  "irten",
  "irudi",
  "irule",
  "irura",
  "isari",
  "isats",
  "iseka",
  "isiki",
  "istil",
  "isuri",
  "itain",
  "itaun",
  "ithon",
  "itsas",
  "itutu",
  "itxia",
  "itzal",
  "itzul",
  "izapo",
  "izari",
  "izatz",
  "izeba",
  "izeki",
  "izitu",
  "izotz",
  "iztai",
  "iztar",
  "izter",
  "iztil",
  "jabal",
  "jabon",
  "jagon",
  "jaiki",
  "jakin",
  "jalgi",
  "jarki",
  "jarri",
  "jasan",
  "jaski",
  "jasta",
  "jator",
  "jatsi",
  "jatun",
  "jaube",
  "jaugi",
  "jauki",
  "jaupa",
  "jauts",
  "jauzi",
  "jazar",
  "jeiki",
  "jelos",
  "jende",
  "jente",
  "jesan",
  "jetxi",
  "jetzi",
  "jipoi",
  "joare",
  "jodda",
  "joile",
  "jokai",
  "jolas",
  "jorra",
  "josle",
  "juale",
  "juatz",
  "jupoi",
  "justu",
  "juzgu",
  "kaban",
  "kabia",
  "kadan",
  "kaden",
  "kadre",
  "kafia",
  "kaiar",
  "kaiku",
  "kailu",
  "kalte",
  "kanpo",
  "kanta",
  "kantu",
  "kapar",
  "kapuz",
  "karan",
  "karba",
  "karbe",
  "karga",
  "karia",
  "kario",
  "karra",
  "karri",
  "karta",
  "kasko",
  "kasku",
  "kasto",
  "katea",
  "kauke",
  "kauku",
  "kausa",
  "kaxal",
  "kedar",
  "keinu",
  "kemen",
  "kendu",
  "kerra",
  "kerru",
  "ketxo",
  "kezka",
  "kinka",
  "kinoi",
  "kinto",
  "kirol",
  "kirru",
  "klero",
  "klike",
  "kobla",
  "kobre",
  "kofau",
  "koita",
  "kolpe",
  "konde",
  "kondu",
  "kontu",
  "kopla",
  "koral",
  "korda",
  "koroa",
  "koroi",
  "korta",
  "korte",
  "kosta",
  "kotor",
  "kotxe",
  "koxka",
  "krial",
  "kuita",
  "kunbo",
  "kutsa",
  "kutsu",
  "kutun",
  "kutxa",
  "labur",
  "lagin",
  "lagun",
  "lahar",
  "laido",
  "lakar",
  "laket",
  "landa",
  "landu",
  "lanoa",
  "lantu",
  "lapar",
  "lapur",
  "larde",
  "larre",
  "larru",
  "lasto",
  "lauki",
  "lauso",
  "legar",
  "legen",
  "legez",
  "legor",
  "lehen",
  "leher",
  "lehia",
  "lehor",
  "lehua",
  "leial",
  "leiar",
  "leiho",
  "leinu",
  "leire",
  "leitu",
  "leize",
  "lekoa",
  "lekuz",
  "lerde",
  "lerdo",
  "lerin",
  "lerma",
  "lerro",
  "letra",
  "leuta",
  "lezoi",
  "lezor",
  "libre",
  "libro",
  "likar",
  "likin",
  "limoe",
  "lipar",
  "lirin",
  "lirio",
  "lirta",
  "listu",
  "lizar",
  "lizun",
  "llaga",
  "llaru",
  "loben",
  "lobil",
  "lobio",
  "lohia",
  "loilo",
  "lokan",
  "longo",
  "loria",
  "lorio",
  "lortu",
  "lotan",
  "lotin",
  "lotki",
  "lotsa",
  "lotxa",
  "lozan",
  "lozer",
  "lubai",
  "ludoi",
  "lugin",
  "luizi",
  "lukur",
  "lunka",
  "lurpe",
  "lurta",
  "lutho",
  "lutxo",
  "luxar",
  "lüzko",
  "mabil",
  "magal",
  "mahai",
  "mahel",
  "maier",
  "maila",
  "mailu",
  "maira",
  "mairu",
  "maisu",
  "maite",
  "maixu",
  "makal",
  "makar",
  "maker",
  "makol",
  "makur",
  "malba",
  "malbu",
  "malda",
  "males",
  "malko",
  "malta",
  "mando",
  "manex",
  "marda",
  "mardo",
  "margo",
  "marka",
  "marla",
  "marmo",
  "marot",
  "marra",
  "marro",
  "marru",
  "marti",
  "masto",
  "mauka",
  "maula",
  "mazte",
  "meatz",
  "medar",
  "media",
  "mehar",
  "melar",
  "menda",
  "mende",
  "mendi",
  "mendu",
  "menia",
  "menpe",
  "menta",
  "mente",
  "merio",
  "merke",
  "merla",
  "metxa",
  "miesa",
  "miilu",
  "mikor",
  "milin",
  "minat",
  "minda",
  "mindu",
  "mirri",
  "miska",
  "mithi",
  "mitra",
  "mitxa",
  "miura",
  "mizki",
  "mizto",
  "modor",
  "moeta",
  "moker",
  "mokil",
  "mokol",
  "mokor",
  "molde",
  "monje",
  "morde",
  "mordo",
  "mortu",
  "motel",
  "motxa",
  "moztu",
  "muber",
  "mueka",
  "mueta",
  "muger",
  "mugon",
  "mukai",
  "mukil",
  "mukit",
  "mukul",
  "mukur",
  "mulko",
  "mundu",
  "munto",
  "murde",
  "murgi",
  "murko",
  "murla",
  "murlo",
  "murru",
  "murtu",
  "musde",
  "musin",
  "musko",
  "mutil",
  "mutin",
  "mutur",
  "muzin",
  "muzka",
  "naatz",
  "nabar",
  "nabas",
  "nafar",
  "nahar",
  "nahas",
  "nahiz",
  "naiko",
  "nakar",
  "napar",
  "napur",
  "narda",
  "narra",
  "narru",
  "nasai",
  "naska",
  "naski",
  "natre",
  "natza",
  "nauna",
  "nausa",
  "nazka",
  "negal",
  "negar",
  "negil",
  "nehon",
  "nehor",
  "nehun",
  "neitu",
  "nekez",
  "nekor",
  "nekos",
  "neska",
  "neure",
  "nexar",
  "nigar",
  "nobel",
  "nobia",
  "nobio",
  "nokhu",
  "nongo",
  "norat",
  "norde",
  "noski",
  "notha",
  "nütre",
  "ñarre",
  "ñauka",
  "ñotto",
  "oarka",
  "obeil",
  "obeta",
  "odots",
  "ogale",
  "ogara",
  "ogika",
  "ogite",
  "ogitz",
  "oguzi",
  "ohaka",
  "ohara",
  "ohata",
  "ohatz",
  "ohitu",
  "ohoin",
  "ohore",
  "ohüts",
  "oihal",
  "oihan",
  "oihar",
  "oiher",
  "oihes",
  "oihuz",
  "oilar",
  "oillo",
  "oixal",
  "okatu",
  "okelu",
  "oketa",
  "okhil",
  "okitu",
  "okotz",
  "oküpü",
  "olana",
  "olata",
  "olatu",
  "oldar",
  "oldei",
  "olibo",
  "olitz",
  "oloak",
  "oltza",
  "omaia",
  "omane",
  "onbor",
  "ondar",
  "ondun",
  "onest",
  "onstu",
  "ontsa",
  "ontxi",
  "ontzi",
  "onura",
  "opalo",
  "opari",
  "opeil",
  "opots",
  "opotz",
  "opütz",
  "orain",
  "oratu",
  "oratz",
  "orban",
  "orbel",
  "ordea",
  "ordez",
  "ordun",
  "orein",
  "oreka",
  "orgil",
  "orile",
  "oritu",
  "oritz",
  "orjat",
  "oroit",
  "orots",
  "orril",
  "orrin",
  "orsto",
  "ortoz",
  "ortzi",
  "orzme",
  "orzti",
  "osaba",
  "osatu",
  "oskar",
  "oskin",
  "oskol",
  "osoki",
  "osotu",
  "ospel",
  "ostel",
  "osten",
  "ostil",
  "otatu",
  "oteun",
  "otika",
  "otitu",
  "otore",
  "otron",
  "otsar",
  "otsil",
  "otxar",
  "otxin",
  "otxio",
  "otxor",
  "otzan",
  "ozama",
  "ozkar",
  "ozmin",
  "ozpel",
  "ozpil",
  "ozpin",
  "oztin",
  "padar",
  "padel",
  "pagot",
  "paira",
  "pakin",
  "paldo",
  "palma",
  "palüñ",
  "paneü",
  "paper",
  "papun",
  "papur",
  "paret",
  "parta",
  "parte",
  "pasmo",
  "patar",
  "patas",
  "patox",
  "pausa",
  "pausu",
  "pazko",
  "pegar",
  "perde",
  "peril",
  "perke",
  "pesta",
  "petar",
  "petxu",
  "pezta",
  "phesi",
  "pialu",
  "pimin",
  "pintz",
  "piñus",
  "piper",
  "pirta",
  "pisti",
  "pixor",
  "pizka",
  "pizma",
  "pizte",
  "piztu",
  "plaka",
  "planu",
  "plaza",
  "plazu",
  "plein",
  "poden",
  "polea",
  "polit",
  "pondu",
  "pontz",
  "porru",
  "portu",
  "posta",
  "postu",
  "potor",
  "potro",
  "potxa",
  "pozik",
  "pozor",
  "poztu",
  "praka",
  "premu",
  "preso",
  "prest",
  "punta",
  "puntu",
  "puska",
  "putin",
  "putzu",
  "puztu",
  "sabai",
  "sabel",
  "sagar",
  "saioa",
  "sakan",
  "saker",
  "sakil",
  "sakon",
  "salbu",
  "salda",
  "saldo",
  "saldu",
  "salki",
  "samin",
  "samur",
  "santa",
  "santu",
  "sapai",
  "sapar",
  "sarde",
  "sarga",
  "sargu",
  "saroi",
  "sarri",
  "sartu",
  "sasoi",
  "sator",
  "seilu",
  "seita",
  "senar",
  "sendi",
  "sendo",
  "seska",
  "siklo",
  "sillu",
  "soinu",
  "sokor",
  "solas",
  "sonto",
  "sortu",
  "sotil",
  "sotin",
  "statu",
  "sudur",
  "sufle",
  "sugai",
  "sugar",
  "suhar",
  "suhet",
  "sukal",
  "sukar",
  "sumin",
  "supio",
  "susmo",
  "sutsu",
  "suzko",
  "takoi",
  "talde",
  "talol",
  "tandu",
  "tanga",
  "tanto",
  "tarro",
  "tarta",
  "tarte",
  "taula",
  "teila",
  "teilu",
  "teitu",
  "tekla",
  "tepel",
  "terna",
  "theiü",
  "thomü",
  "tinko",
  "tinta",
  "tipil",
  "toila",
  "tonto",
  "tornu",
  "toska",
  "traka",
  "tratu",
  "trebe",
  "trein",
  "treus",
  "trufa",
  "truke",
  "ttipi",
  "tuaxa",
  "tupin",
  "tutik",
  "txano",
  "txaul",
  "txiel",
  "txiki",
  "txiko",
  "txima",
  "txiro",
  "txito",
  "txori",
  "txulo",
  "txuzo",
  "tzotz",
  "ubegi",
  "ubide",
  "udare",
  "uduku",
  "ugaio",
  "ugari",
  "ugolo",
  "uhain",
  "uhera",
  "uhure",
  "uhuri",
  "ukatu",
  "ükhen",
  "ükürü",
  "ultze",
  "umetu",
  "umore",
  "unatu",
  "unide",
  "unkhü",
  "untzi",
  "urdin",
  "urdun",
  "urent",
  "urlia",
  "urotz",
  "urril",
  "urrun",
  "urtzi",
  "ürüle",
  "usaia",
  "usain",
  "usegi",
  "usigi",
  "uskoi",
  "ustel",
  "üsügi",
  "utzio",
  "utzur",
  "uxada",
  "uxatu",
  "uxter",
  "uzker",
  "uzkin",
  "uzkol",
  "uzkur",
  "uztai",
  "uztar",
  "xabal",
  "xabur",
  "xahal",
  "xakar",
  "xakur",
  "xalke",
  "xalma",
  "xamar",
  "xarbo",
  "xatar",
  "xauzi",
  "xerra",
  "xerri",
  "xexen",
  "xikin",
  "ximal",
  "ximel",
  "ximen",
  "xinta",
  "xixta",
  "xorta",
  "xotil",
  "xuala",
  "xugun",
  "xuxen",
  "xygyn",
  "ynide",
  "zaatz",
  "zabal",
  "zabar",
  "zabau",
  "zabor",
  "zador",
  "zafla",
  "zagon",
  "zahar",
  "zaian",
  "zaitu",
  "zakar",
  "zakur",
  "zaldi",
  "zaldu",
  "zalke",
  "zalko",
  "zalui",
  "zamar",
  "zango",
  "zanko",
  "zarpa",
  "zarri",
  "zatal",
  "zatar",
  "zauri",
  "zauzi",
  "zazpi",
  "zeden",
  "zedro",
  "zehar",
  "zeinu",
  "zekai",
  "zeken",
  "zekor",
  "zelai",
  "zelan",
  "zeldu",
  "zemai",
  "zendu",
  "zerba",
  "zeren",
  "zerga",
  "zerka",
  "zerra",
  "zerri",
  "zertu",
  "zetan",
  "zetro",
  "zezen",
  "zidar",
  "zifra",
  "zigar",
  "zigor",
  "zigur",
  "zikin",
  "zilar",
  "ziluz",
  "zimel",
  "zimur",
  "zindo",
  "zinge",
  "zinka",
  "zirga",
  "zirko",
  "zisku",
  "zisne",
  "zital",
  "zitra",
  "zizpa",
  "zoegi",
  "zopin",
  "zorga",
  "zorhi",
  "zorne",
  "zorpe",
  "zorte",
  "zorun",
  "zorze",
  "zotin",
  "zozon",
  "zoztu",
  "zügün",
  "zuhar",
  "zuhur",
  "zulla",
  "zumel",
  "zuntz",
  "zurru",
  "zurtu",
  "zutik",
  "zuzen",
  "zuzun",
];
